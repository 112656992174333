import { Component, ElementRef, Input, SimpleChanges, ViewChild } from '@angular/core';
import { UserSelectionService } from 'src/services/ui/user-selection.service';
import { SelectedModel } from 'src/classes/SelectedModel';
import { MatTableDataSource } from '@angular/material/table';
import * as Plotly from 'plotly.js-dist-min';
import { Data } from 'plotly.js';
import { MapPlotService } from 'src/services/map-plot.service';
import html2canvas from 'html2canvas';
import { PageData } from 'src/classes/pdf/PageData';

interface Prediction {
  model: string,
  prediction: number,
  position: string,
}
const slotColors: string[] = ['#003653', '#00708C', '#00B2BD'];
const slots: string[] = ['A', 'B', 'C'];

@Component({
  selector: 'prediction-summary',
  templateUrl: './prediction-summary.component.html',
  styleUrls: ['./prediction-summary.component.scss']
})
export class PredictionSummaryComponent {
  @Input() selectedHexes: string[] = [];
  @ViewChild('predictionSummary') predictionSummaryElement: ElementRef<HTMLElement>;

  displayedColumns: string[] = ["model", "prediction"];
  dataSource: any = new MatTableDataSource<Prediction>([]);

  graphName: string = "barChart";
  graphRef: Plotly.PlotlyHTMLElement | null;
  title:string='Local Area Predictions';

  constructor(
    private selectionService: UserSelectionService,
    private mapPlotService: MapPlotService
  ) {}

  ngOnChanges(changes: SimpleChanges) {

    if (!changes['selectedHexes'].firstChange && changes['selectedHexes'].currentValue.length > 0) {
      this.getPadMetrics();
    }
  }
 
  getModels(): Array<SelectedModel> {
    return this.selectionService.getLastRunModels();
  }

  getPadMetrics() {
    this.saveHtmlElementData('', this.title);
    let predictions = [];  // store mean of each model's prediction here
    this.getModels().forEach((run, index) => {
      const mean_prediction: number = this.mapPlotService.getMeanPredictionByHexes(run.runId, this.selectedHexes);
      predictions.push({ model: run.runId, prediction: mean_prediction, position: run.position });
    });
    this.dataSource.data = predictions;
   
    let graphData: Data[] = [{
      name: "",
      x: slots.slice(0, predictions.length),
      y: predictions.map(m => m.prediction),
      hovertemplate: '%{y:,.3s}',
      marker: {
        color: slotColors.slice(0, predictions.length),
      },
      type: 'bar'
    }];
    let layout = {
      height: 212,
      width: 150,
      margin: {
        l: 40,
        r: 20,
        b: 20,
        t: 20,
        pad: 4
      },
    }
    let config = {
      displayModeBar: false,
    };
    Plotly.newPlot(this.graphName, graphData, layout, config).then(x=>{
      this.savePredictionSummary();
    });
    }

  savePredictionSummary() {
    const element = document.getElementById('predictionSummary');
      if (this.predictionSummaryElement.nativeElement) {
      html2canvas(this.predictionSummaryElement.nativeElement, {
        scale: window.devicePixelRatio,
        }).then((canvas) => {
        const image = canvas.toDataURL('image/png', 1.0);
        this.saveHtmlElementData(image, this.title);
      }
      );
    }
  }
  saveHtmlElementData(htmlContent: any, title: string) {
    let { imgWidth, imgHeight } ={ imgWidth: 180, imgHeight: 60 };
    const pdfData: PageData = {
      title: '', // not required for summary data
      subTitle: '',
      data: htmlContent,
      imgSize: { imgWidth, imgHeight },
      module: 'Local Area Predictions'
    };
    this.mapPlotService.saveDataToLocalStorage(pdfData);
  }
}
