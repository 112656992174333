export class ControlValue<T> {
    name: string;
    value: T;

    copy(): ControlValue<T> {
        const copy = new ControlValue<T>();
        copy.name = "FAKE NAME";
        copy.value = this.value;
        return copy;
    }
}
