<div class="HEADER_CONTAINER">
    <div class="GROUP_NAME" (click)="collapse()" [title]="groupTitle">{{ groupName }}</div>
    <div *ngIf="collapsed" class="EXPAND_GROUP" (click)="collapse()"><mat-icon>chevron_right</mat-icon></div>
    <div *ngIf="!collapsed" class="EXPAND_GROUP" (click)="collapse()"><mat-icon>expand_more</mat-icon></div>
</div>
<mat-divider *ngIf="!collapsed" class="similar-margin"></mat-divider>
<div [style.display]="collapsed ? 'none' : ''" class="panel-content" [formGroup]="userSelectionForm">
    <div class="stacked-btn-container button-container">
        <ng-container *ngIf="userSelectionForm.get('selectedModels').value.length > 0; else elseNotDone">
            <div class="selectedModelRow" *ngFor="let model of userSelectionForm.get('selectedModels').value; index as i; first as isFirst" title="Run ID: {{ model.runId}}&#013;Model Type: {{ model.modelType}}&#013;Promotion Status: {{ model.promotionStatus }}&#013;&#013;Business Unit: {{ model.businessUnit }}&#013;Basin: {{ model.basin }}&#013;Product: {{ model.product }}&#013;Bench: {{ model.bench }}">
                <div class="ModelDisplay__Position--Position{{ model.position }}">
                    {{ model.position }}
                </div>
                <div class="selectedModelRunID">
                    {{ model.friendlyName }} | {{ model.modelType }}
                </div>
            </div>
            <button class="btn-medium-secondary--cancel" (click)="clearSelectedModels()">Clear Models</button>
        </ng-container>
        <ng-template #elseNotDone>
            <button class="btn-medium-secondary--cancel" matTooltip="Default models by location are used for predictions if selection is not customized." (click)="getDefaultModels()">Load Defaults</button>
        </ng-template>
        <button class="btn-medium-secondary" (click)="showModelSelect()">Search Models</button>
    </div>
</div>