import { ModelDataResponse } from "./dataTransfer/responses/ModelDataResponse";

export class SelectedModel {
    runId: string;
    modelType: string;
    friendlyName:string;
    businessUnit: string;
    basin: string;
    product: string;
    bench: string;
    bench_path:string;
    position: string;
    promotionStatus: string;

    constructor(modelData: ModelDataResponse) {
        this.basin = modelData.basin;
        this.bench = modelData.bench;
        this.businessUnit = modelData.business_unit;
        this.modelType = modelData.type;
        this.product = modelData.product;
        this.promotionStatus = modelData.promotion_status;
        this.runId = modelData.run_id;
        this.bench_path=modelData.bench_path;
        this.friendlyName=modelData.friendly_name;
    }

    public static isEqual(first: SelectedModel[], second: SelectedModel[], details: Array<string>): boolean {
        let valid = true;

        if(first === undefined || second === undefined)
        {
            valid = false;
        }
        else
        {
            if (first.length !== second.length) {
                details.push("Model selection");
                valid = false;
            }
            for (let i = 0; i < first.length; i++) {

                var firstModel = first[i];
                var secondModel = second[i];

                if(firstModel === undefined || secondModel === undefined || firstModel === null || secondModel === null)
                {
                    valid = (firstModel === secondModel)
                }
                else if (firstModel.runId !== secondModel.runId) {
                    details.push("Model  " + firstModel.runId);
                    valid = false;
                }
            }
        }
        return valid;
    }
}