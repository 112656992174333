
import { Compiler, Component, ComponentRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef } from "@angular/core";
import { InterfaceFolderDto } from "src/app/classes/InterfaceFolderDto";
import { PageModule } from "../page-component/page-module";
import { PageComponent } from "../page-component/page-component";
@Component
({
    selector: "dyn-folder", 
    templateUrl: "./folder-component.html",
    styleUrls: ["./folder-component.scss"],
}
)

export class FolderComponent implements OnInit{
    @Input("data") data: InterfaceFolderDto | undefined;
    
    @ViewChild('templateView', {read: ViewContainerRef})
    templateView!: ViewContainerRef;

    @Output() sendSubmit : EventEmitter<any> = new EventEmitter<any>(); 

    pages: ComponentRef<any>[];
    currentPage: number;
    totalPages: number;

    constructor(protected _compiler: Compiler) {
        this.pages = new Array<ComponentRef<any>>();
        this.currentPage = 0;
        this.totalPages = 0;
    }
  
    ngOnInit(): void {
      this.build();
    }

    build(): void {

        this._compiler.compileModuleAndAllComponentsAsync(PageModule)
        .then((factories) => {
    
            const folderData = this.data;
            if(folderData != undefined)
            {
                const pageData = folderData.pages;
                this.totalPages = pageData.length;

                for(let i = 0; i < pageData.length; ++i)
                {
                    const currentPage = pageData[i];
                    const f = factories.componentFactories[0];
                    const cmpRef = this.templateView.createComponent(f);
                    cmpRef.instance.name = "DynamicPage_" + i;
                    cmpRef.setInput("data", currentPage);
                    cmpRef.setInput("visibility",  (i == 0));
                    cmpRef.setInput("index", i);

                    var componentX =  cmpRef.instance as PageComponent;
                    componentX.updateValue.subscribe(x => this.receiveUpdate(x, i));
                    componentX.sendSubmit.subscribe(x => this.receiveSubmit(x, i));

                    this.pages.push(cmpRef);
                }
            }
          //  Get the Folder
          
        });
    }

    pageCallback(data: any, index: number) : void
    {
        console.log("Page " + index + " says " );
        console.log(data);
    }

    receiveUpdate(data: any, index: number) : void
    {
        console.log(data);
    }

    receiveSubmit(data: any, index: number) : void
    {
        this.sendSubmit.emit(data);
    }

    selectPage(index: number) {
        this.currentPage = index;
        for(let i = 0; i < this.pages.length; ++i)
        {
            const currentPage = this.pages[i];
            currentPage.setInput("visibility",  (i == index));
        }
    }

    nextPage(): void {
        this.selectPage(this.currentPage + 1);
    }

    prevPage(): void {
        this.selectPage(this.currentPage - 1);
    }
}