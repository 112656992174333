<div id="divLogin">
    <h1 class="h1">APOLO</h1>
    <div align="center">
      <img class="imgApolo" src="./assets/img/apolo.png">
    </div>
    <div *ngIf="!loggedIn" class="noAuthenticationNotify">
      Authenticating, please wait
    </div>
    <button *ngIf="loggedIn" class="btnLogin" [disabled]="isDisabled" (click)="login()">Login</button><br>
    <span  class="error" [innerHTML]='errorText'></span>
  </div> 
