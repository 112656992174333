import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import * as Plotly from 'plotly.js-dist-min';
import { MatSelect } from '@angular/material/select';
import { ComparisonChoices } from 'src/classes/ComparisonChoices';
import { PlotlyViewComponent } from '../plotly-view/plotly-view.component';
import { CommonFunctions } from 'src/classes/CommonFunctions';

@Component({
  selector: 'diff-view',
  templateUrl: './diff-view.component.html',
  styleUrls: ['./diff-view.component.scss']
})
export class DiffViewComponent implements OnInit {
  @ViewChild("compareA") compareA: MatSelect;
  @ViewChild("compareB") compareB: MatSelect;
  @ViewChild("diffPlot") comparisonView : PlotlyViewComponent;
  

  @Output("compare") compare = new EventEmitter<ComparisonChoices>();

  graphName: string;
  GraphData : any;

  constructor(private httpClient: HttpClient) {
    this.graphName = "comparisonView";
  }
  ngOnInit(): void {

    this.loadPlotlyComparison();
  }
  loadPlotlyComparison(): void {
    
  }

  Compare(): void {
    const choices = new ComparisonChoices();
    choices.choiceA = this.compareA.value;
    choices.choiceB = this.compareB.value;

    this.compare.emit(choices);
  }

  clearComparison(): void{
    this.comparisonView.initLoading();
  }
  applyComparisonData(data: any): void {
    this.comparisonView.applyData(data, "");
  }
}