export class Location {
    business_unit?: string;
    basin: string;
    bench: string;
    hexes: string[];

    constructor(basin: string, bench: string) {
        this.basin = basin;
        this.bench = bench;
        this.hexes = new Array<string>();
    }
}

export class Model {
    run_id: string;
    controls? : any;

    constructor(run_id: string) {
        this.run_id = run_id;
    }
}

export class Product {
    type: string;
    timeslice: number;

    constructor(type: string, timeslice: number) {
        this.type = type;
        this.timeslice = timeslice;
    }
}

export class ScoreParams{
    how? : string;
    sync? : boolean;
    confidence? : string;
}