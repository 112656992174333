import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";

@Component
({
  selector: "dyn-static-image", templateUrl: "./image-control.html"
})

export class StaticImageComponent implements OnInit{
    @Input("data") data: any;
    @Input("visibility") visibility: boolean;

    defaultValue: string;

    constructor() {
      this.visibility = false;
      this.defaultValue = "";
    }
  
    ngOnInit(): void {
      this.defaultValue = this.data.defaultValue;
      this.visibility = true;
    }
  }