import { SelectedModel } from "./SelectedModel";

export class ComparisonChoices
{
    choiceA: string;
    choiceB: string;   
}

export class ComparisonSettings
{
    type: string;
    baselineModel: SelectedModel;
    alternativeModel: SelectedModel;
    varianceModels: SelectedModel[];
    metric: string;
        
    public constructor(init?: Partial<ComparisonSettings>) {
        Object.assign(this, init);
    }
}