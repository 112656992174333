import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";

@Component
(
{selector: "dyn-num-box", templateUrl: "./number-box-control.html" }
)

export class NumberBoxControlComponent implements OnInit{
    @Input("data") data: any;
    @Output() updateValue : EventEmitter<any> = new EventEmitter<any>();



    @Input("visibility") visibility: boolean;

    defaultValue: string;
    sizeWidth: string;
    minimum: number | undefined;
    maximum: number | undefined;
    dirty: boolean;
    valid: boolean;

    constructor() {
      this.visibility = false;
      this.defaultValue = "";
      this.dirty = false;
      this.minimum = this.maximum = undefined;
      this.valid = true;
      this.sizeWidth = "";
    }
  
    ngOnInit(): void {
      this.defaultValue = this.data.defaultValue;
      this.minimum = this.data.minimum;
      this.maximum = this.data.maximum;
      this.visibility = true;
      switch(this.data.size)
      {
        case "t":
          this.sizeWidth = "20px";
          break;
        case "s":
          this.sizeWidth = "50px";
          break;
        case "l":
          this.sizeWidth = "200px";
          break;
        case "x":
          this.sizeWidth = "300px";
          break;
        default:
        case "m":
          this.sizeWidth = "100px";
          break;
      }
    }

    onChange(value: any): void {
      this.valid = true;
      if(this.minimum != undefined && value < this.minimum) {
        this.valid = false;
      }
      if(this.valid && this.maximum != undefined && value > this.maximum) {
        this.valid = false;
      }
      if(this.valid && value == '')
      {
        this.valid = false;
      }
      


      this.dirty = (value != this.defaultValue);

      this.updateValue.emit({
        name: this.data.data,
        value: value
      });
    }
  }