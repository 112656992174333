export const environmentRoot = {
  timeStamp: new Date().toString(),
  popupForLogin: false,
  cacheLocation: 'localStorage',
  instance: 'https://login.microsoftonline.com/',
  tenantId: 'fd799da1-bfc1-4234-a91c-72b3a1cb9e26',
  clientId: '45f3bdc7-24c6-415c-bd99-4d161214b45d',
  scopes: ['openid', 'profile', 'email', 'offline_access'],
  graphScopes: ['User.Read.All'],
  apiScope: ['https://alice2-test.azure.chevron.com/user_impersonation'],
  accessGroupId:'ITC-DSECOE-ALICE-TEAM',
  productOwnerEmail:'robertandrais@chevron.com',
  adminUsers:['2e4c5ff1-d3f9-40df-81e1-284e9e0e4682','6715029e-c629-4696-a987-b98e1f905447'],
  version: '24.3.3',  // align with sprint
};