<div>
    <div class="sub-header-container">
        <div class="subHeader">
            Data Export
        </div>
    </div>
    <div>
        <app-card-box class="d-flex justify-content-center">
            <ol>
                <div class="title">Report Options:</div>
                <li>
                    ML Summary Report -> Retrieve a detailed report summarizing the machine learning training and
                    testing results
                </li>
                <li>
                    Area Summary Report -> Retrieve a report of the plots displayed in this site summarizing predictions
                    and actuals from the area selected (or the entire basin if no area is selected)
                </li>
            </ol>
        </app-card-box>
    </div>
    <div class="row">
        <div class="column">
            <div class="input-group">
                <label class="subHeader2">Report Type</label>
                <mat-form-field class="custom-mat-dropdown" subscriptSizing="dynamic">
                    <mat-select placeholder="Type" [value]="reportType" (selectionChange)="typeChange($event)">
                        <mat-option value="run">ML Summary Report</mat-option>
                        <mat-option value="area">Area Summary Report</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="column">
            <div class="input-group">
                <label class="subHeader2">Format</label>
                <mat-form-field class="custom-mat-dropdown" subscriptSizing="dynamic">
                    <mat-select placeholder="Format" [value]="defaultFormat" (selectionChange)="formatChange($event)">
                        <mat-option value="pdf">PDF</mat-option>
                        <mat-option value="ppt" disabled="true">PPT</mat-option>
                        <mat-option value="csv" disabled="true">CSV</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <!-- <div class="column">
            <div class="input-group">
                <label class="subHeader2">Type Curve Areas</label>
                <mat-form-field class="custom-mat-dropdown" subscriptSizing="dynamic" style="border-radius: 20px;">
                    <mat-select placeholder="Type" [value]="defaultTypeCurve" [disabled]="defaultFormat=='pdf'">
                        <mat-option value="all" disabled>ALL</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-hint class="hint" *ngIf="defaultFormat=='pdf'">Type is not required for PDF</mat-hint>
            </div>
        </div> -->
    </div>
    <div class="row">
        <button class="btn-small" type="submit" (click)="preview()">Preview</button>
    </div>
    <br>
    <div class="sub-header-container" style="top:10vh" *ngIf="isReportAvailable==false">
        <img height="150" width="170" src="./assets/img/NoData.png"><br>
        <span class="unavailable">Report not available to preview </span>
    </div>
    <div class="sub-header-container" *ngIf="isReportAvailable==true">
        <div class="subHeader">
            {{reportTitle}}
        </div>
        <input (click)="downloadReport()" class="btn-export" title="Download report">
        <div>
            Page {{currentPage}} of {{pdf?.numPages }}
        </div>
        <div class="container">
            <div> <img class="nav-button" src="./assets/img/icons/backward_navigation_prev.png"
                    (click)="pageChange('Previous')" alt="Previous page"></div>
            <div class="pdf-container"> <pdf-viewer [src]="pdfData" class="pdf-content" [render-text]="true"
                    [show-all]="false" [(page)]="currentPage" [show-borders]="true"
                    (after-load-complete)="afterLoadComplete($event)" [original-size]="true"></pdf-viewer></div>
            <div> <img class="nav-button" src="./assets/img/icons/forward_navigation_next.png"
                    (click)="pageChange('Next')" alt="Next page"></div>
        </div>
    </div>
</div>