import { MapArea } from "./MapArea";
import { MapGeometry } from "./MapGeometry";

export class MapAreaEx extends MapArea {
    businessUnit: string;
    basin: string;
    bench: string;
    geometry: MapGeometry;
    hexValues: string[];

    
    applyData(data: any) : void{
        this.geometry = data.geometry;
        this.hexValues = data.hex_list;
    }
   }        