
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BODConfigModel } from 'src/models/bod.model';
import { WorkFlowInputModel } from 'src/models/workflow-input.model';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
public bu:string;
public region:string;
public bench:string;
public workFlowMode:string;
  constructor() { }
  setBusinessUnit(value) {
    this.bu = value;
  }
  getBusinessUnit() {
    return this.bu;
  }
  setBURegion(value) {
    this.region = value;
  }
  getBURegion() {
    return this.region;
  }
  setBUBench(value) {
    this.bench = value;
  }
  getBUBench() {
    return this.bench;
  }
  getWorkFlowMode() {
    return this.workFlowMode;
  }
  setWorkFlowMode(value) {
    this.workFlowMode = value;
  }
}
