import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-segmented-toggle',
  templateUrl: './segmented-toggle.component.html',
  styleUrls: ['./segmented-toggle.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SegmentedToggleComponent {
  @Input() options: string[] = [];
  @Input() selectedOption: string = '';
  @Output() optionSelected = new EventEmitter<string>();

  selectOption(option: string) {
    this.selectedOption = option;
    this.optionSelected.emit(this.selectedOption);
  }
}
