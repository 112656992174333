import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";

@Component
({
  selector: "dyn-submit-button", 
  templateUrl: "./submit-button-control.html", 
  styleUrls: ["./submit-button-control.scss"]
})

export class SubmitButtonControlComponent implements OnInit{

    @Input("visibility") visibility: boolean;
    @Input("data") data: any;
    @Output() updateValue : EventEmitter<any> = new EventEmitter<any>();
    @Output() submitForm : EventEmitter<any> = new EventEmitter<any>();


    direction: string;
    defaultValue: string;
    dirty: boolean;
    valid: boolean;
    groupName: string;

    constructor() {
      this.visibility = false;
      this.defaultValue = "";
      this.direction = "horizontal";
      this.dirty = false;
      this.valid = true;
      this.groupName = "";
    }
  
    ngOnInit(): void {
      this.defaultValue = this.data.defaultValue;
      this.direction = this.data.direction;
      this.visibility = true;

      this.groupName = this.data.data;
    }

    submit(): void{
      this.submitForm.emit(null);
    }
  }