import { HttpErrorResponse,HttpResponse } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, catchError, lastValueFrom, map, throwError } from 'rxjs';
import { CommonFunctions } from 'src/classes/CommonFunctions';
import { ModelDataRequest } from 'src/classes/dataTransfer/requests/ModelDataRequest';
import { ModelDataResponse } from 'src/classes/dataTransfer/responses/ModelDataResponse';
import { environment } from 'src/environments/environment';
import { RequestBuilder } from 'src/classes/functions/RequestBuilder';
import { BaseService } from '../service-base.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { InterfaceSectionDto } from 'src/app/classes/InterfaceSectionDto';
import { PredictionDataRequest } from 'src/classes/dataTransfer/requests/PredictionDataRequest';
import { LoadingScreenService } from '../ui/loading-screen-service';
import { ActionIndex } from 'src/classes/loadingActions/ActionIndex';

@Injectable({
    providedIn: 'root'
})
export class ModelService extends BaseService {

    @Output() controlsDataLoaded: EventEmitter<any> = new EventEmitter<any>();
    public constructor(snackBar: MatSnackBar, http: HttpClient,private loadingService: LoadingScreenService) {
        super(http, snackBar);
    }


    setControlsDataLoaded(data: any) {
        this.controlsDataLoaded.emit(data);
    }

    public getModelControls(request: PredictionDataRequest): Observable<HttpResponse<any>>
    {
        const qString = new RequestBuilder(environment.apoloWebApiURL, "Models/GetModelControls");

        request.runIds.forEach(runId => qString.addItem("runId", runId.id));
        
        if(request.basin != null) {
            qString.addItem("basin", request.basin);
        }
        if(request.bench != null) {
            qString.addItem("bench", request.bench);
        }
        if(request.basin != null) {
            qString.addItem("product", request.product);
        }
        if(request.timeslice != null) {
            qString.addItem("timeslice", request.timeslice.toString());
        }
        return this.http.get<any>(qString.getUrl(), {headers:CommonFunctions.headerInfo.headers,observe:'response'})
        .pipe(
            map(response=>{
                if(response.status==201)
               console.log('Hit prediction service to get model controls:' + response.status);
               return response.body;
               }),
               catchError((error: HttpErrorResponse) => {this.loadingService.actionFailed(ActionIndex.RETRIEVING_MODEL_CONTROLS,()=>{});return this.handleError(error)}));
          }
    
    public getModelData(request: ModelDataRequest): Observable<HttpResponse<ModelDataResponse[]>> {
        request.validate();
        const qString = new RequestBuilder(environment.apoloWebApiURL, "Models/GetModels");

        if (request.business_unit != null) {
            qString.addItem("BusinessUnit", request.business_unit);
        }

        if (request.basin != null) {
            qString.addItem("Basin", request.basin);
        }

        if (request.bench != null) {
            qString.addItem("Bench", request.bench);
        }

        if (request.product != null) {
            qString.addItem("Product", request.product);
        }

        if (request.modelType != null) {
            qString.addItem("ModelType", request.modelType);
        }

        if (request.promotion_status != null) {
            qString.addItem("PromotionStatus", request.promotion_status);
        }

        if (request.startDate != null) {
            qString.addItem("StartDate", request.startDate.toISOString());
        }

        if (request.endDate != null) {
            qString.addItem("EndDate", request.endDate.toISOString());
        }


        if (request.latestModel != null) {
            qString.addItem("LatestModel", request.latestModel.toString());
        }
        return this.http.get<ModelDataResponse[]>(qString.getUrl(), {headers:CommonFunctions.headerInfo.headers,observe:'response'})
            .pipe(
                map(response=>{
                    if(response.status==201)
                    console.log('Hit prediction service while getting models:' + response.status);
                    return response;
                    }),
                    catchError((error: HttpErrorResponse) => {this.loadingService.actionFailed(ActionIndex.LOADING_DEFAULT_MODELS,()=>{});return this.handleError(error)}));
          
    }
    public getModelFeatures(input: string, request: ModelDataRequest): Observable<HttpResponse<ModelDataResponse[]>> {
        var method = '';
        if (input == "Features")
            method = "Models/GetFeatureImportance";
        if (input == "FeaturesData")
            method = "Models/GetFeatureImportanceData";
        const qString = new RequestBuilder(environment.apoloWebApiURL, method);

        if (request.mlRunId != null) {
            qString.addItem("runId", request.mlRunId);
        }
        if (request.basin != null) {
            qString.addItem("basin", request.basin);
        }

        if (request.bench != null) {
            qString.addItem("bench", request.bench);
        }

        if (request.product != null) {
            qString.addItem("product", request.product);
        }

        if (request.timeSlice != null) {
            qString.addItem("timeslice", request.timeSlice?.toString());
        }
        return this.http.get<HttpResponse<Array<any>>>(qString.getUrl(), {headers:CommonFunctions.headerInfo.headers,observe:'response'})
            .pipe(
                map(response=>{
                    if(response.status==201)
                    console.log('Hit prediction service while getting model features:' + response.status);
                    return response.body;
                    }),
                    catchError((error: HttpErrorResponse) => {this.loadingService.actionFailed(ActionIndex.RETRIEVING_PLOTS,()=>{});return this.handleError(error)}));
    }

    public getFeatureProdQueryData(runId: string, product: string, plotType: string): Observable<HttpResponse<any[]>> {
        const qString = new RequestBuilder(environment.apoloWebApiURL, "Models/GetFeatureImportanceProdQuery");
        qString.addItem("runId", runId);
        qString.addItem("product", product);
        return this.http.get<HttpResponse<Array<any>>>(qString.getUrl(), {headers:CommonFunctions.headerInfo.headers,observe:'response'})
            .pipe(
                map(response=>{
                    if(response.status==201)
                    console.log('Hit prediction service while getting feature importance data:' + response.status);
                    return response.body;
                    }),
                catchError(this.handleError)
            );
    }

    public getFeatureProdQueryPlot(request: ModelDataRequest): Observable<HttpResponse<ModelDataResponse[]>> {
        request.validate();
        const qString = new RequestBuilder(environment.apoloWebApiURL,"Models/GetFeatureImportancePlotProdQuery");

        if (request.mlRunId != null) {
            qString.addItem("runId", request.mlRunId);
        }

        if (request.product != null) {
            qString.addItem("product", request.product);
        }

        if (request.plotType != null) {
            qString.addItem("plotType", request.plotType);
        }
        return this.http.get<HttpResponse<Array<any>>>(qString.getUrl(), {headers:CommonFunctions.headerInfo.headers,observe:'response'})
            .pipe(
                map(response=>{
                    if(response.status==201)
                    console.log('Hit prediction service while getting feature importance plot:' + response.status);
                    return response.body;
                    }),
                catchError(this.handleError));
    }

    public getPDPProdQueryPlot(runId: string, product: string): Observable<HttpResponse<any[]>> {
        const qString = new RequestBuilder(environment.apoloWebApiURL, "Models/GetPDPProdQueryPlot");
        qString.addItem("runId", runId);
        qString.addItem("product", product);
        return this.http.get<HttpResponse<Array<any>>>(qString.getUrl(), {headers:CommonFunctions.headerInfo.headers,observe:'response'})
            .pipe(
                map(response=>{
                    if(response.status==201)
                    console.log('Hit prediction service while getting PDP plot:' + response.status);
                    return response.body;
                    }),
                    catchError((error: HttpErrorResponse) => {this.loadingService.actionFailed(ActionIndex.RETRIEVING_SCORING,()=>{});return this.handleError(error)}));
    }
    public getPDPProdQueryData(runId: string, product: string): Observable<HttpResponse<any[]>> {
        const qString = new RequestBuilder(environment.apoloWebApiURL, "Models/GetPDPProdQueryData");
        qString.addItem("runId", runId);
        qString.addItem("product", product);
        return this.http.get<HttpResponse<Array<any>>>(qString.getUrl(), {headers:CommonFunctions.headerInfo.headers,observe:'response'})
        .pipe(
            map(response=>{
                if(response.status==201)
                console.log('Hit prediction service while getting PDP data:' + response.status);
                return response.body;
                }),
            catchError((error: HttpErrorResponse) => { return this.handleError(error,)}));
    }

}
