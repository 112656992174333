<div class="PlotlyView__Container--Slot{{ slotPosition }}">
    <div *ngIf="loadStatus === -1">
        <div class="plot-pre-load-container-bardata">
            <!-- <span class="plotly-loading-text">Ready</span> -->
        </div>
    </div>
    <div [style.display]="loadStatus == 0 ? 'block': 'none'">
        <div class="plot-loading-container">
            <span class="plotly-loading-text">Loading</span>
        </div>
    </div>
    <div [style.display]="loadStatus == 1 ? 'block': 'none'" class="CONTAINER">
        <div>
            <div class="grid">
                <div >
                    <div id="{{ graphName }}"></div>
                </div>
            </div>
        </div>
    </div>
</div>