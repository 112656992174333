import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ControlComponent } from "./control-component";
import { TextBoxControlComponent } from "../controls/text-box/text-box-control";
import { NumberBoxControlComponent } from "../controls/number-box/number-box-control";
import { DropDownControlComponent } from "../controls/drop-down/drop-down-control";
import { RadioControlComponent } from "../controls/radio-buttons/radio-control";
import { TextAreaControlComponent } from "../controls/text-area/text-area-control";
import { ParagraphComponent } from "../controls/paragraph/paragraph-control";
import { StaticImageComponent } from "../controls/static-image/image-control";
import { ImageRadioControlComponent } from "../controls/image-button/image-radio-control";
import { MatSliderModule } from '@angular/material/slider';
import { SliderControlComponent } from "../controls/advanced/slider/slider-control";
import { SliderRangeControlComponent } from "../controls/advanced/slider-range/slider-range-control";
import { SubmitButtonControlComponent } from "../controls/submit-button/submit-button-control";
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { CheckboxComponent } from "../controls/checkbox/checkbox-control";
import { MatCheckboxModule } from "@angular/material/checkbox";

@NgModule({
    declarations: [
        ControlComponent,
        TextBoxControlComponent,
        NumberBoxControlComponent,
        CheckboxComponent,
        DropDownControlComponent,
        RadioControlComponent,
        TextAreaControlComponent,
        ParagraphComponent,
        StaticImageComponent,
        ImageRadioControlComponent,
        SliderControlComponent,
        SliderRangeControlComponent,
        SubmitButtonControlComponent
    ],
    imports: [
        CommonModule,
        MatSliderModule,
        MatButtonModule,
        MatSelectModule,
        MatRadioModule,
        MatCheckboxModule
    ],
    providers: []
  })
  export class ControlModule { }