import { LocationInfo } from "./LocationInfo";
import { ConfigurationInfo } from "./ConfigurationInfo";
import { SelectedModel } from "./SelectedModel";

export class UserSelections {
    location: LocationInfo;
    configuration: ConfigurationInfo;    
    selectedModels: SelectedModel[];
    
    public constructor(init?: Partial<UserSelections>) {
        Object.assign(this, init);
    }

    public static isEqual(first: UserSelections, second: UserSelections, details: Array<string>): boolean {
        let valid = true;
        if(!LocationInfo.isEqual(first.location, second.location, details))
            valid = false;
        if(!ConfigurationInfo.isEqual(first.configuration, second.configuration, details))
            valid = false;
        if(!SelectedModel.isEqual(first.selectedModels, second.selectedModels, details))
            valid = false;

        return valid;
    }
}