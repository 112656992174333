// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`..

import { environmentRoot } from "./root/environment.root";

export const environment = {
  root: environmentRoot,
  
  apoloWebApiURL: 'https://alice2-test-api-cvx.azurewebsites.net/',
  autoSignIn: true,
  environmentTag: 'TEST',
  production: false,
  redirectUri: 'https://alice2-test-ui-cvx.azurewebsites.net/',
};
