import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { CalAngularService } from '@cvx/cal-angular';
import { catchError, filter, Observable } from 'rxjs';
import { CommonFunctions } from 'src/classes/CommonFunctions';
import { RequestBuilder } from 'src/classes/functions/RequestBuilder';
import { ActionIndex } from 'src/classes/loadingActions/ActionIndex';
import { environment } from 'src/environments/environment';
import { UserTracker } from 'src/models/user-tracker';
import { LoadingScreenService } from './loading-screen-service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BaseService } from '../service-base.service';

@Injectable({
    providedIn: 'root'
})
export class UserTrackerService extends BaseService {

    constructor(private router: Router, private httpClient: HttpClient,
        snackBar: MatSnackBar,
        private authService: CalAngularService,
        private loadingService: LoadingScreenService) {
        super(httpClient, snackBar);
        //this.trackNavigation();
        this.trackClicks();
    }

    GetUserLogs(): Observable<any> {
        const qString = new RequestBuilder(environment.apoloWebApiURL, "users/GetUsersLogs");
        return this.httpClient.get<any>(qString.getUrl())
            .pipe(catchError((error) => { this.loadingService.actionFailed(ActionIndex.LOADING_DATA, () => { }); return this.handleError(error) }));
    }

    SaveUserLoginInfo(pageUrl: string): Observable<any> {
        const qString = new RequestBuilder(environment.apoloWebApiURL, "users/SaveUserLogs");
        const userinfo = new UserTracker();
        userinfo.userId = this.authService.cvxClaimsPrincipal.objectId;
        userinfo.userName = this.authService.cvxClaimsPrincipal.name;
        userinfo.action = pageUrl;
        userinfo.timeStamp = new Date().toISOString();
        let header = Object.assign({}, CommonFunctions.headerInfo);
        header['responseType'] = 'text';
        return this.httpClient.post<string>(qString.getUrl(), userinfo, header)
            .pipe(
                catchError(this.handleError)
            );
    }
    // Method to track user navigation (page visits)
    trackNavigation(): void {
        this.router.events.pipe(
            filter(event => event instanceof NavigationStart)
        ).subscribe(event => {
            this.SaveUserLoginInfo(`Navigated to ${event['url']} page`).subscribe();
        });
    }

    trackClicks(): void {
        const tagNames = ['BUTTON', 'A']
        document.body.addEventListener('click', (event) => {
            const clickedElement = event.target as HTMLElement;
            if (tagNames.find(item => item === clickedElement.tagName)) {
                let action = clickedElement.innerText != '' ? clickedElement.innerText : clickedElement.title;
                this.SaveUserLoginInfo(`Clicked on ${action}`).subscribe();
            }
        });
    }

}
