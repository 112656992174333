<div class="containerDivWrapper">
    <div class="topDivWrapper">
        <div class="inputSearchIcon"><img src="./assets/img/search-icon.png" height="30" width="30"/></div>
        <input class="inputCss" (input)="applyFilter($event)" placeholder="Search filter..." >
        <div class="d-flex flex-row justify-content-start align-items-center">
            <div class="toggle-switch" (click)="toggleLocationTag()" matTooltip="Select on Location Level"  [matTooltipShowDelay]="500">
                <div class="toggle-knob"[ngStyle]="{ 'left': locationSelected ? '50%' : '0', 'background-color': locationSelected ? '#009DD9' : '' }"></div>
            </div>
            <span [ngStyle]="{ 'font-weight': locationSelected ? 'bolder' : ''}">Filter to Selected Location</span>
        </div>
        <div class="div-wrapper-button">
            <div class="d-flex">
                <span class="file-name">{{uploadedFileName || 'No file chosen'}}</span>
                <label for="file-upload" class="btn-medium btn-run"  style="display: flex;justify-content: center;align-items: center;gap: 5%;">
                    Upload <img width="25" height="25" src="../../assets/img/upload.png">
                </label>
                <input type="file" id="file-upload" (change)="onFileChange($event)" accept=".csv" hidden />
            </div>
            <button class="btn-medium btn-run" (click)="exportInventory()" style="display: flex;justify-content: center;align-items: center;gap: 5%;">
                Download <img width="25" height="25" src="../../assets/img/download.png">
            </button>
        </div>
    </div>
    <div class="tablePlaceholderCss" *ngIf="dataSource.data.length == 0">
        <img class="imgDivWrapper" src="./assets/img/interface/table_placeholder.png" height="350" width="1150"/>
    </div>
    <div class="tableContainer" *ngIf="dataSource.data.length != 0">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <!-- Dynamic column definitions -->
            <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
              <th mat-header-cell *matHeaderCellDef class="sticky-header"> {{ formatHeaderValue(column)}} </th>
              <td mat-cell *matCellDef="let element" style="text-align: center;cursor: pointer;" (click)="generateSelectedPlot(element['Prop ID'])" [ngStyle]="{ 'background-color': element['Prop ID'] == selectedPropId ? '#009DD9' : '' }"> {{ element[column] }} </td>
            </ng-container>
          
            <!-- Header and Row Definitions -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
    </div>
    <div class="paginationDivWrapper">
        <!-- Paginator -->
        <div><mat-paginator [pageSizeOptions]="[10, 20, 30, 40, 50]" showFirstLastButtons style="background-color: transparent;"></mat-paginator></div>
    </div>
    <div style="display: flex;justify-content: center;margin-top: 2vh;">
        <button class="btn-medium btn-run">Explain predictions</button>
    </div>
    <div class="plotViewContainer">
        <div class="plotView">
            <plotly-view #qqPlot graphType="scatter" [width]=1000 [height]=400 graphName="qqPlot" headerText="Q-Q Plot">
            </plotly-view>
        </div>
    </div>
</div>