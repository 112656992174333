import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { UserProfileService } from './user-profile.service';
import { Injectable } from '@angular/core';
import { CalAngularService } from '@cvx/cal-angular';
import { environment } from 'src/environments/environment';

import { catchError, Observable, throwError, map, from, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor {
  private token = null;
  constructor(
    private userService: UserProfileService,
    private authService: CalAngularService
  ) {
    this.token =
      this.userService.getUserDetails('userToken') ||
      this.userService.getCurrentUserSecret();
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.authService.getAADToken(environment.root.apiScope).pipe(
      switchMap(token => {
        const requestClone = request.clone({
          setHeaders: { Authorization: `Bearer ${token}` },
        });
        return next.handle(requestClone);
      })

    )
    )
  }
}
