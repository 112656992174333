import { Component, AfterViewInit, ViewChild, Input, ViewEncapsulation } from '@angular/core';
import { TabViewComponent } from '../base/tab-view-base.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { PredictionService } from 'src/services/data/prediction.service';
import { PredictionDataRequest } from 'src/classes/dataTransfer/requests/PredictionDataRequest';
import { UserSelectionService } from 'src/services/ui/user-selection.service';
import { SelectedModel } from 'src/classes/SelectedModel';
import { ExportService } from 'src/services/export.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';


export interface TableElement {
  'type_curve_2024_base_codev_phased_delaware_oil_prediction': any;
  'type_curve_2024_base_codev_phased_delaware_high_confidence': any;
  'forge_03_24_tc2024_at_4miles_delaware_oil_prediction': any;
  'forge_03_24_tc2024_at_4miles_delaware_high_confidence': any;
  '20240331T173921_oil_prediction': any;
  '20240331T173921_high_confidence': any;
  'dev_area': any;
  'Prop ID': any;
  'Pad Name': any;
}

@Component({
  selector: 'well-inventory',
  templateUrl: './well-inventory.component.html',
  styleUrls: ['./well-inventory.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class WellInventoryComponent extends TabViewComponent implements AfterViewInit {

  displayedColumns: string[] = []
  dataSource : any = new MatTableDataSource<TableElement>([]);
  dataSourceCopy = this.dataSource
  pRequest = new PredictionDataRequest();
  KeyToFilter : string[] = [
    'hex',
    'timeslice',
    'basin',
    'bench', 
    'Development Area', 
    'Sub-Development Area', 
    '2023 Type Curve', 
    'Pad Number', 
    'Calculated Completed Lateral Length',
    'POP year'
  ];
  selectedModels: Array<SelectedModel>;
  latestUpdatedModel: any;
  selectedPropId: string = '';
  locationSelected: boolean = false;
  hexList:any = [];
  uploadedFileName: string = "" ;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor( private predictionSource: PredictionService, public exportService: ExportService, private snackBar: MatSnackBar,
     override selectionService: UserSelectionService) {
    super(selectionService);
  };

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  formatNumber(value: any): string {
    return value != null && !isNaN(value) 
      ? Number(value).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) 
      : 'N/A';
  }

  calculatePercentage(valueA: number, valueB: number): string {
    if (valueB === 0) {
      return 'N/A'; // Handle division by zero
    }
    if (isNaN(valueA) || isNaN(valueB)) {
      return 'N/A'; // Handle invalid numbers
    }
    
    const percentage = ((valueA / valueB) * 100).toFixed(0);
    return `${percentage}%`;
  }

  processTableData(data: any): any[] {
    let tableData = [...data];
    let modelA = "" ;
    let modelB = "" ;
    let modelC = "" ;
    this.displayedColumns.map((key) => {
      if(key.includes(this.latestUpdatedModel[0].runId)){
        modelA = key
      }
      if(key.includes(this.latestUpdatedModel[1].runId)){
        modelB = key
      }
      if(key.includes(this.latestUpdatedModel[2].runId)){
        modelC = key
      }
    })
    tableData = tableData.map((item) => {
      // Add new columns with default values or computed values
      item['A - B Factor'] = this.calculatePercentage(item[modelB], item[modelA]);
      item['A - C Factor'] = this.calculatePercentage(item[modelC], item[modelA]);
      // Process existing columns
      this.displayedColumns.forEach((key) => {
        if (key.includes("confidence")) {
          item[key] = item[key] === true ? 'High' : item[key] === false ? 'Low' : 'N/A';
        } else if (key.includes("prediction")) {
          item[key] = this.formatNumber(item[key]);
        }
      });
      return item;
    });
    return tableData;
  }

  formatHeaderValue(column: string): any {
    this.latestUpdatedModel.map((value) => {
      if(column.includes(value.runId)){
        column.includes("prediction") ? 
        (column = column.includes("type_curve")?`${value.position} | TC Prediction`: 
        column.includes("forge")?`${value.position} | Forge Prediction`:`${value.position} | ML Prediction`):
        column.includes("confidence") ? 
        ( column = column.includes("type_curve")?`${value.position} | TC Confidence`:
        column.includes("forge")?`${value.position} | Forge Confidence`:`${value.position} | ML Confidence`):null     
      }else{
        column == "dev_area" ? column = "Dev Area" : null
      }
    })
    return column
  }

  getSelectedModels(): Array<SelectedModel> {
    return this.selectionService.getCurrentModels();
  }

  reorderColumns(columns: string[]): string[] {
    const priorityColumns = ['Sub-Development Area', 'Pad Name', 'Prop ID'];
    const factorColumns = ['A - B Factor', 'A - C Factor'];
    const otherColumns = columns.filter(col => !priorityColumns.includes(col));
    return [...priorityColumns, ...otherColumns, ...factorColumns];
  }

  generateSelectedPlot(ProdId : any){
    if(ProdId == this.selectedPropId)
      this.selectedPropId = '';
    else
      this.selectedPropId = ProdId
  }

  loadData(): void {
    if (!this.dataLoaded) {
      this.pRequest=this.selectionService.getLastRunPredictionRequest(true);
      this.pRequest.addInventory=true;
      this.predictionSource.getInventoryData(this.pRequest).subscribe(pData => {
        let tableKeys = Object.keys(pData[0])
        this.latestUpdatedModel = [ ...this.getSelectedModels()];
        this.displayedColumns = tableKeys.filter(key => !this.KeyToFilter.includes(key));
        this.dataSource.data = this.processTableData(pData);
        this.dataSourceCopy = this.dataSource.data
        this.displayedColumns = this.reorderColumns(tableKeys.filter(key => !this.KeyToFilter.includes(key)));
      });
    }
    this.dataLoaded = true;
  }

  toggleLocationTag(): void {
    const hexValues = localStorage.getItem("selectedHexList")
    this.hexList = hexValues == undefined ? undefined : JSON.parse(hexValues)
    const tempobJ = [ ...this.dataSource.data];
    const updatedObj = [];
    if(this.hexList != undefined){
      this.locationSelected = !this.locationSelected;
      if(this.locationSelected){
        this.hexList.map((hexvalue: string) => {
          tempobJ.map((value : any) => {
            if(value.hex == hexvalue)
              updatedObj.push(value)  
          })
        })
        this.dataSource.data = updatedObj
      }else{
        this.dataSource.data = this.dataSourceCopy
      }
    }else{
      const config = new MatSnackBarConfig();
      config.duration = 3000;
      config.panelClass = ['snackbar-error'];
      this.snackBar.open("Please select the loacation for filtering the Inventory", 'Close', config);
    }
  }

  exportInventory ():void{
    this.exportService.exportToCSV(this.dataSource.data, "well-inventory-table")
  }

  onFileChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    const file = input.files[0];
    this.uploadedFileName = file.name
    // Upload functionality in progress ---
  }


}
