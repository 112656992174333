import { Component, Input, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Component({
  selector: 'app-action-loader',
  templateUrl: './action-loader.component.html',
  styleUrls: ['./action-loader.component.scss'],
  providers: [MessageService]
})
export class ActionLoaderComponent implements OnInit {

  constructor(private messageService: MessageService,private snackBar: MatSnackBar) { }

  ngOnInit() {
    const config = new MatSnackBarConfig();
    config.duration = 3000;     // Duration the snackbar should be displayed (in milliseconds)
    config.panelClass = ['snackbar-info'];     // Optional: custom CSS class for styling
    this.snackBar.open("Downloading Started Successfully", 'Close', config);
  }

}