import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";

@Component
({
  selector: "dyn-image-radio", 
  templateUrl: "./image-radio-control.html", 
  styleUrls: ["./image-radio-control.scss"]
})

export class ImageRadioControlComponent implements OnInit{
    @Input("data") data: any;
    @Output() updateValue : EventEmitter<any> = new EventEmitter<any>();



    @Input("visibility") visibility: boolean;

    direction: string;
    defaultValue: string;
    dirty: boolean;
    valid: boolean;
    groupName: string;

    constructor() {
      this.visibility = false;
      this.defaultValue = "";
      this.direction = "horizontal";
      this.dirty = false;
      this.valid = true;
      this.groupName = "";
    }
  
    ngOnInit(): void {
      this.defaultValue = this.data.defaultValue;
      this.direction = this.data.direction;
      this.visibility = true;

      this.groupName = this.data.data;
    }

    onChange(value: any): void {
      this.valid = true;
      this.dirty = (value != this.defaultValue);

      this.updateValue.emit({
        name: this.data.data,
        value: value
      });
    }

    forceChange(value: any): void {
      this.defaultValue = value;
      this.onChange(value);
    }
  }