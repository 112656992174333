
import { Injectable } from '@angular/core';
import { PredictionService } from './data/prediction.service';
import { UserSelectionService } from './ui/user-selection.service';
import { Observable, delay, map, of } from 'rxjs';
import { MapPlot } from 'src/classes/plots/MapPlot';
import { createCultureLayers, createLayer, filterZScores, getMax, getMin } from 'src/classes/functions/GraphFunctions';
import { PlotLayer } from 'src/classes/plots/layer';
import { Prediction } from 'src/classes/interfaces/predictions';
import { ActionTexts } from 'src/classes/loadingActions/ActionTexts';
import { ActionIndex } from 'src/classes/loadingActions/ActionIndex';
import { LoadingScreenService } from './ui/loading-screen-service';
import * as Plotly from 'plotly.js-dist-min';
import { PageData } from 'src/classes/pdf/PageData';

@Injectable({
  providedIn: 'root'
})
export class MapPlotService {
  public maps: { [k: string]: MapPlot } = {};
  public predictions: { [k: string]: Prediction[] } = {}; // Might not be the best place to cache this... could be a different service?
  private cultures: PlotLayer[] = null;
  private hex_geojsons = {};
  public dataLoaded: boolean;

  constructor(
    private predictionSource: PredictionService,
    private selectionService: UserSelectionService,
    private loadingService: LoadingScreenService
  ) {
      this.dataLoaded = false;
     }

  public setDataLoaded(x: boolean) {
    if(!x)
      this.maps = {};

    this.dataLoaded = x;
  }

  public getMaps(): Observable<any> {
    if(this.dataLoaded) {
      return of(this.maps).pipe(delay(0));  // Delay 0 is added to ensure DOM has time to load before returning the maps
    } else {
      return this.loadMaps();
    }
  }

  public delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }
  
  public getMap(id: string): Observable<any> {
    if(this.dataLoaded) {
      return of(this.maps[id]);
    } else {
      return this.loadMaps().pipe(
        map((response) => {
          return response[id];
        }
      ))
    }
  }

  private loadMaps(): Observable<any> {
    var min, max = null;
    const pRequest = this.selectionService.getLastRunPredictionRequest(true);    
    this.loadingService.addAction(ActionIndex.RETRIEVING_PLOTS, ActionTexts.RETRIEVING_PLOTS);
    return this.predictionSource.getPredictionMap(pRequest).pipe(
      map((response) => {
        this.cultures = createCultureLayers(response['cultures']);
        this.hex_geojsons = response['geojson'];
        
        pRequest.runIds.forEach((model) => {
          let filteredValues = filterZScores(response[model.id]);
          min = getMin(min, filteredValues);
          max = getMax(max, filteredValues);
        });

        pRequest.runIds.forEach((model) => {
          let layers: PlotLayer[] = [];
          layers.push(createLayer(response, true, model.id, min, max));
          layers.push(createLayer(response, false, model.id, min, max));

          this.maps[model.id] = { 
            layers: layers.concat(this.cultures),
          }
          this.predictions[model.id] = response[model.id];

        });
        this.loadingService.actionComplete(ActionIndex.RETRIEVING_PLOTS);
        this.dataLoaded = true;
        return this.maps;
      })
    );
  }

  public getPercentDifferenceMap(baselineModelId: string, altModelId: string) {
    let baslinePred: Prediction[] = this.predictions[baselineModelId]
    let altPred: Prediction[] = this.predictions[altModelId]

    if (!baslinePred || !altPred) {
      console.warn('Predictions not found for either baseline or alternative model');
      return null;
    }
    
    const result = baslinePred.map((baselineItem: Prediction) => {
      if (baselineItem.prediction == null) {
        return null;
      }
      const altItem: Prediction = altPred.find(item => item.hex === baselineItem.hex);
      if (!altItem || altItem.prediction == null) {
        return null;
      }
      
      const percentDiff: number = ((altItem.prediction - baselineItem.prediction) / baselineItem.prediction) * 100;
      const highConfidence: boolean = altItem.high_confidence && baselineItem.high_confidence;
      return {
        hex: baselineItem.hex,
        prediction: percentDiff,
        high_confidence: highConfidence
      };
    }).filter(Boolean); // Remove any null values
    
    let layers: PlotLayer[] = [];
    const data = {
      "dynamic": result,
      "geojson": this.hex_geojsons
    };
    layers.push(createLayer(data, true, "dynamic", -50, 50, "RdBu"));
    layers.push(createLayer(data, false, "dynamic", -50, 50, "RdBu"));

    return {
      layers: layers.concat(this.cultures)
    };
  }

  public getStandardDeviationMap(modelIds: string[]) {
    const hexPredictions: { [hex: string]: { [modelId: string]: number } } = {};
  
    // First pass: Collect all predictions for each hex
    modelIds.forEach(modelId => {
      this.predictions[modelId].forEach(prediction => {
        if (!hexPredictions[prediction.hex]) {
          hexPredictions[prediction.hex] = {};
        }
        hexPredictions[prediction.hex][modelId] = prediction.prediction;
      });
    });
  
    // Calculate mean and variance for each hex
    const results: { [hex: string]: { mean: number, variance: number } } = {};
    Object.entries(hexPredictions).forEach(([hex, predictions]) => {
      const values = Object.values(predictions).filter((val): val is number => val !== null);

      if (values.length < 2) {
        // console.warn(`Not enough valid values for hex ${hex}. Skipping calculation.`);
        return;
      }

      const mean = values.reduce((sum, val) => sum + val, 0) / values.length;
      const variance = values.reduce((sum, val) => sum + Math.pow(val - mean, 2), 0) / values.length;
      results[hex] = { mean, variance };
    });
  
    // Calculate standard deviation for each hex
    const stdDevs: { [hex: string]: number } = {};
    Object.entries(results).forEach(([hex, stats]) => {
      stdDevs[hex] = Math.sqrt(stats.variance);
    });
    
    const stdDevsArray: Array<{ hex: string, prediction: number, high_confidence: boolean }> = [];
    Object.entries(stdDevs).forEach(([hex, stdDev]) => {
      stdDevsArray.push({
        hex,
        prediction: stdDev,
        high_confidence: true // Assuming we want to set high_confidence to true for all standard deviations
      });
    });

    let layers: PlotLayer[] = [];
    const data = {
      "dynamic": stdDevsArray,
      "geojson": this.hex_geojsons
    };
    let filteredValues = filterZScores(stdDevsArray);
    let min = getMin(null, filteredValues);
    let max = getMax(null, filteredValues);
    layers.push(createLayer(data, true, "dynamic", min, max, "YlGnBu"));
    layers.push(createLayer(data, false, "dynamic", min, max, "YlGnBu"));

    return {
      layers: layers.concat(this.cultures)
    };
  }

  public getMeanPredictionByHexes( modelId: string, hex: string[]): number {
    let predictions = this.predictions[modelId];
    let model_predictions_by_hex: number[] = predictions.filter((element) => hex.includes(element.hex)).map((element) => element.prediction);
    return model_predictions_by_hex.reduce((sum, value) => sum + value, 0) / model_predictions_by_hex.length
  }

  savePlot(graphName: any, title: string, subTitle: string, module: string) {
    Plotly.toImage(graphName, { format: 'png', width: 800, height: 600 })
      .then((imageData: string) => {
        let { imgWidth, imgHeight } = this.getImageSize(subTitle);
        const pdfData: PageData = {
          title: title,
          subTitle: subTitle,
          data: imageData,
          imgSize: { imgWidth, imgHeight },
          module: module
        };
        this.saveDataToLocalStorage(pdfData);
      })
  }
  
  saveDataToLocalStorage(pdfData: PageData) {
    let storedArray = JSON.parse(localStorage.getItem('savedPlots') || '[]');  // Default to empty array if null
    const existsItem = storedArray.find(item => item.subTitle === pdfData.subTitle);
    if (existsItem)
      existsItem.data = pdfData.data;  // replace if the map already saved
    else
      storedArray.push(pdfData);
    localStorage.setItem('savedPlots', JSON.stringify(storedArray));
  }
  // remove prediction maps from storage when area changed
  resetPlotStorage(){
    let storedArray = JSON.parse(localStorage.getItem('savedPlots') || '[]');  // Default to empty array if null
    const items = storedArray.filter(item => item.module === 'Local Area Predictions');
    storedArray=storedArray.filter(item => item.module != 'Local Area Predictions')
    localStorage.setItem('savedPlots', JSON.stringify(storedArray));
  }
  private getImageSize(key) {
    if (key == 'Prediction Summary Data')
      return { imgWidth: 150, imgHeight: 60 };
    if (key.includes('Local Area Predictions'))
      return { imgWidth: 130, imgHeight: 100 };
    else
      return { imgWidth: 170, imgHeight: 140 };
  }
}
