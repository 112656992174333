<div style="display: flex; flex-wrap: nowrap;">
  <div>
      <view-controls #viewControls (notifyExecute)="run()"></view-controls>
  </div>
  <div #middleBanner class="contentHolder">
    <mat-tab-group [disablePagination]="true" [disableRipple]="false" [backgroundColor]="'accent'" dynamicHeight="true" #tabGroup
      (selectedTabChange)="selectedTabChange($event)">
      <mat-tab label="Diagnostics">
        <div class="tabContainer">
          <div class="tabContent">
              <plotly-diagnostics #diagnosticsView></plotly-diagnostics>
          </div>
          <div class="peaks"></div>
        </div>
      </mat-tab>
      <mat-tab label="Predictions">
        <div class="tabContainer">
          <div class="tabContent">
            <plotly-predictions #predictionsView></plotly-predictions>
          </div>
          <div class="peaks"></div>
        </div>
      </mat-tab>
      <mat-tab label="Well Inventory">
        <div class="tabContainer">
          <div class="tabContent">
            <well-inventory #wellInventoryView></well-inventory>
          </div>
          <div class="peaks"></div>
        </div>
      </mat-tab>
      <mat-tab label="Explainability">
        <div class="tabContainer">
          <div class="tabContent">
            <plotly-explainability #explainabilityView></plotly-explainability>
          </div>
          <div class="peaks"></div>
        </div>
      </mat-tab>
      <mat-tab label="Comparison">
        <div class="tabContainer">
          <div class="tabContent">
            <comparison #comparison></comparison>
          </div>
          <div class="peaks"></div>
        </div>
      </mat-tab>
      <mat-tab label="Pad Scenario">
        <div class="tabContainer">
          <div class="tabContent">
            <pad-scenario-builder #padScenarioBuilder></pad-scenario-builder>
          </div>
          <div class="peaks"></div>
        </div>
      </mat-tab>
      <mat-tab label="Data Export">
        <div class="tabContainer">
          <div class="tabContent">
            <data-export #dataExport></data-export>
          </div>
          <div class="peaks"></div>
        </div>
      </mat-tab>
      <mat-tab label="Users Logs" #adminTab *ngIf="isAdminUser">
        <div class="tabContainer">
          <div class="tabContent">
            <users-logs #usersLogs></users-logs>
          </div>
          <div class="peaks"></div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>