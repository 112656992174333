import { Component, ViewChild } from '@angular/core';
import { PredictionService } from 'src/services/data/prediction.service';
import { PlotlyViewComponent } from '../views/plotly-view/plotly-view.component';
import { TabViewComponent } from '../base/tab-view-base.component';
import { UserSelectionService } from 'src/services/ui/user-selection.service';
import { LoadingScreenService } from 'src/services/ui/loading-screen-service';
import { SelectedModel } from 'src/classes/SelectedModel';
import { MapPlotService } from 'src/services/map-plot.service';
import { MatTableDataSource } from '@angular/material/table';
import * as Plotly from 'plotly.js-dist-min';
import { Data } from 'plotly.js';
import { FormBuilder } from '@angular/forms';
import { PredictionDataRequestBase, PredictionDataRequestBOD } from 'src/classes/dataTransfer/requests/PredictionDataRequest';
import { getHexesMiles } from 'src/classes/functions/H3Geo';

interface Prediction {
  model: string,
  prediction: number,
  position: string,
}
const slotColors: string[] = ['#003653', '#00708C', '#00B2BD'];
const slots: string[] = ['A', 'B', 'C'];

@Component({
  selector: 'pad-scenario-builder',
  templateUrl: './pad-scenario-builder.component.html',
  styleUrls: ['./pad-scenario-builder.component.scss']
})
export class PadScenarioBuilderComponent extends TabViewComponent{
  @ViewChild("plotlySelect") plotlySelect: PlotlyViewComponent;
  @ViewChild("gunBarrel") gunBarrel: PlotlyViewComponent;
  @ViewChild("BODProbit") BODProbit: PlotlyViewComponent;

  selectedModel: SelectedModel;
  selectedHexes: string[] = [];

  BODForm = this.formBuilder.group({
    radius: 4,
    property: "frac_fluid_intensity",
    window: 25,
    isPredicted: false,
  });


  constructor(private predictionSource: PredictionService,
    override selectionService: UserSelectionService,
    private mapPlotService: MapPlotService,
    private readonly formBuilder: FormBuilder,
  ) {
    super(selectionService);
  }

  loadData(): void {
    if (!this.dataLoaded) {
      this.plotlySelect.initLoading();
      const mlModel = this.getModels().filter(x => x.modelType === "machine_learning")[0];
      if(mlModel)
        this.selectedModel = mlModel;
      else
        this.selectedModel = this.getModels()[0];
      this.getGunBarrel();
      this.loadMap();
      this.dataLoaded = true;
    }
  }
  
  loadMap() {
    this.mapPlotService.getMap(this.selectedModel.runId).subscribe(
      data => {
        this.plotlySelect.createMap(data.layers, this.selectedModel.modelType);
      }
    );
  }
  
  getModels(): Array<SelectedModel> {
    return this.selectionService.getLastRunModels();
  }
  
  modelChange(selectedModel: SelectedModel) {
    this.selectedModel = selectedModel;
    this.loadMap();
  }

  selectHex(event) {
    this.selectedHexes = [event];
    this.getBOD();
  }

  removeHex(hex: string) {
    const index = this.selectedHexes.indexOf(hex);
    if(index !== -1) {
      this.selectedHexes.splice(index, 1);
    }
  }

  getGunBarrel() {
    const request = this.selectionService.getCurrentPredictionRequest(true);  // Using get current so user doesn't have to "Run" everytime a model control is changed
    this.predictionSource.getGunBarrelPlot(request).subscribe(data => {
      this.gunBarrel.applyData(data["plot"], "custom");
    });
  }

  getBOD() {
    const request: PredictionDataRequestBOD = this.selectionService.getCurrentPredictionRequest(true);
    request.hexValues = getHexesMiles(this.selectedHexes[0], this.BODForm.get("radius").value);    
    request.window = this.BODForm.get("window").value;
    request.property = this.BODForm.get("property").value;
    request.isPredicted = this.BODForm.get("isPredicted").value;
    request.bodRunId = this.selectionService.getMLRunId();
    this.predictionSource.getProbitPlot(request).subscribe(data => {
      data = data["BOD Analysis Plot"];
      data.layout.width = 400;
      data.layout.height = 300;
      this.BODProbit.applyData(data, "custom");
    });
  }

}
