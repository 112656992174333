import { Component, OnInit, ViewChild } from '@angular/core';
import { ViewControlsComponent } from '../controls/view-controls/view-controls.component';
import { PlotlyDiagnosticsComponent } from '../plotly-diagnostics/plotly-diagnostics.component';
import { ModelService } from 'src/services/data/model.service';
import { ModelDataRequest } from 'src/classes/dataTransfer/requests/ModelDataRequest';
import { PredictionDataRequest } from 'src/classes/dataTransfer/requests/PredictionDataRequest';
import { PredictionService } from 'src/services/data/prediction.service';
import { PlotlyPredictionsComponent } from '../plotly-predictions/plotly-predictions.component';
import { PlotlyExplainabilityComponent } from '../plotly-explainability/plotly-explainability.component';
import { WellInventoryComponent } from '../well-inventory/well-inventory.component';
import { ComparisonComponent } from '../comparison/comparison.component';
import { DataExportComponent } from '../data-export/data-export.component';
import { ViewEncapsulation } from '@angular/core';

import { SelectedModel } from 'src/classes/SelectedModel';
import { UserSelectionService } from 'src/services/ui/user-selection.service';
import { ExportService } from 'src/services/export.service';
import { LoadingScreenService } from 'src/services/ui/loading-screen-service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModelResolveComponent } from '../dialogs/model-resolve/model-resolve.component';
import { InterfaceSectionDto } from 'src/app/classes/InterfaceSectionDto';
import { ActionIndex } from 'src/classes/loadingActions/ActionIndex';
import { ActionTexts } from 'src/classes/loadingActions/ActionTexts';
import { MapPlotService } from 'src/services/map-plot.service';
import { PadScenarioBuilderComponent } from '../pad-scenario-builder/pad-scenario-builder.component';
import { environment } from 'src/environments/environment';
import { UserProfileService } from 'src/services/auth/user-profile.service';
import { UsersLogsComponent } from '../admin/users-logs/users-logs.component';
import { UserTrackerService } from 'src/services/ui/user-tracker.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-plotly-page',
  templateUrl: './plotly-page.component.html',
  styleUrls: ['./plotly-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class PlotlyPageComponent implements OnInit {
  @ViewChild("viewControls") viewControls: ViewControlsComponent;
  @ViewChild("diagnosticsView") diagnosticsView: PlotlyDiagnosticsComponent;
  @ViewChild("predictionsView") predictionsView: PlotlyPredictionsComponent;
  @ViewChild("wellInventoryView") wellInventoryView: WellInventoryComponent;
  @ViewChild("explainabilityView") explainabilityView: PlotlyExplainabilityComponent;
  @ViewChild("comparison") comparison: ComparisonComponent;
  @ViewChild("padScenarioBuilder") padScenarioBuilder: PadScenarioBuilderComponent;
  @ViewChild("dataExport") dataExport: DataExportComponent;
  @ViewChild("usersLogs") userLogs: UsersLogsComponent;
 
  tabIndex: number = 0;
  pRequest = new PredictionDataRequest();
  mRequest = new ModelDataRequest();
  isAdminUser: boolean=false;

  constructor(
    private modelSource: ModelService,
    private predictionSource: PredictionService,
    private selectionService: UserSelectionService,
    private loadingService: LoadingScreenService,
    public dialogRef: MatDialog,
    private mapPlotService: MapPlotService,
    private userService:UserProfileService,
    private userTracker:UserTrackerService
     ) { };

  ngOnInit(): void {
  const userId = this.userService.getUserProfile().objectId;
    if(environment.root.adminUsers.includes(userId))
      this.isAdminUser=true;
   }

  selectedTabChange(event:MatTabChangeEvent) {
    this.tabIndex =event.index
    this.userTracker.SaveUserLoginInfo(`Navigated to ${event.tab.textLabel} page`).subscribe();
    this.displayCorrectTab();
  }

  displayCorrectTab(): void {
    switch(this.tabIndex)
    {
      case 0:
        this.diagnosticsView.loadData();
        break;
      case 1:
        this.predictionsView.loadData();
        break;
      case 2:
        this.wellInventoryView.loadData();
        break;
      case 3:
        this.explainabilityView.loadData();
        break;
      case 4:
        this.comparison.loadDefaultValues();
        break;
      case 5:
        this.padScenarioBuilder.loadData();
        break;
      case 7:
        this.userLogs.loadData();
        break;
    }
  }

  run(): void {
    localStorage.setItem('savedPlots',JSON.stringify([]));
    this.setDataLoading();

    if(!this.selectionService.hasSelectedModels())
    {
      this.loadDefaultModels();      
    }
    else 
    {
      this.validateModelCompatibility()
    }
  }

  setDataLoading(): void {
    this.diagnosticsView.setDataLoaded(false);
    this.explainabilityView.setDataLoaded(false);
    this.predictionsView.setDataLoaded(false);
    this.mapPlotService.setDataLoaded(false);
    this.wellInventoryView.setDataLoaded(false);
    this.padScenarioBuilder.setDataLoaded(false);
  }
  
  loadDefaultModels(): void {
    const mRequest = this.selectionService.getCurrentModelRequest();
    mRequest.includeFinal = true;
    mRequest.latestModel = true;

    this.loadingService.addAction(ActionIndex.LOADING_DEFAULT_MODELS, ActionTexts.LOADING_DEFAULT_MODELS);
    this.modelSource.getModelData(mRequest).subscribe(resp => {
      const mData=resp.body;
      this.selectionService.setCurrentModels(mData);
      this.loadingService.actionComplete(ActionIndex.LOADING_DEFAULT_MODELS);

      if(!this.selectionService.getMLRunId()) {
        this.viewControls.recvModelControls(null);
        this.displayCorrectTab();
      } else {
        const pRequest = this.selectionService.getCurrentPredictionRequest(false);
        this.loadingService.addAction(ActionIndex.RETRIEVING_MODEL_CONTROLS, ActionTexts.RETRIEVING_MODEL_CONTROLS);
        this.modelSource.getModelControls(pRequest).subscribe( controls =>
          {
            const modelControlArray = Object.values(controls) as InterfaceSectionDto[];
            this.selectionService.addModelControlValues(modelControlArray);

            this.viewControls.recvModelControls(modelControlArray);
            this.loadingService.actionComplete(ActionIndex.RETRIEVING_MODEL_CONTROLS);
            
            this.selectionService.saveUserSelections();
            this.displayCorrectTab();
          });
      }
    });
  }

  validateModelCompatibility(): void {
    //  Get the models and then compare with selected models
    var mRequest = this.selectionService.getCurrentModelRequest();

    mRequest.promotion_status = null;
    mRequest.includeFinal = false;

    this.loadingService.addAction(ActionIndex.VERIFYING_MODEL_COMPATIBILITY, ActionTexts.VERIFYING_MODEL_COMPATIBILITY);
    this.modelSource.getModelData(mRequest).subscribe(resp => {

      const correctModels=resp.body;
      const selectedModels = this.selectionService.getCurrentModels();
      var missingModel: boolean = false;
      selectedModels.forEach(model => {
        if(correctModels.findIndex(incoming => incoming.run_id === model.runId) === -1) {
          missingModel = true;
        }
      });

      console.log("Missing Model: ", missingModel);
      if(!missingModel) {
        this.loadingService.actionComplete(ActionIndex.VERIFYING_MODEL_COMPATIBILITY);        
        this.selectionService.saveUserSelections();
        this.displayCorrectTab()
      } else {
        this.loadingService.actionFailed(ActionIndex.VERIFYING_MODEL_COMPATIBILITY, () => {
          this.loadDefaultModels();
        });
      }
    });
  }
 }