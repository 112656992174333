import { Component, EventEmitter, Output } from '@angular/core';
import { PlotlyViewComponent } from '../plotly-view/plotly-view.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as Plotly from 'plotly.js-dist-min';
import { MapGeometry } from 'src/classes/dataTransfer/areas/MapGeometry';
import { MapAreaEx } from 'src/classes/dataTransfer/areas/MapAreaEx';
import { GraphFunctions } from 'src/classes/functions/GraphFunctions';
import { MAP_CONFIG, MAP_LAYOUT } from 'src/constants/plot.constants';
import { ExportService } from 'src/services/export.service';
import { MapPlotService } from 'src/services/map-plot.service';

@Component({
  selector: 'app-plotly-view-select',
  templateUrl: '../plotly-view/plotly-view.component.html',
  styleUrls: ['../plotly-view/plotly-view.component.scss']
})
export class PlotlyViewSelectComponent extends PlotlyViewComponent {
  
  @Output() rectangleSelected: EventEmitter<MapAreaEx> = new EventEmitter<MapAreaEx>();
  @Output() lassoSelected: EventEmitter<MapAreaEx> = new EventEmitter<MapAreaEx>();
  @Output() pointSelected: EventEmitter<any> = new EventEmitter<any>();

  isDragging = false;
  selectionOverlay: MapAreaEx;
  traceTemplate: any = {
    "colorscale": [
        [
            0,
            "rgba(200, 0, 0, 0)"
        ],
        [
            1,
            "rgba(200, 0, 0, 0)"
        ]
    ],
    "geojson": {
        "type": "FeatureCollection",
        "features": [
            {
                "id": "1",
                "type": "Feature",
                "properties": {},
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [-104.54710893404996, 32.81174104880857],
                            [-104.896566783733, 32.81174104880857],
                            [-104.96645835366961, 32.65888394045846],
                            [-104.91054509772032, 32.58824615453414],
                            [-104.84065352778372, 32.57646777195852],
                        ]
                    ]
                },
                "bbox": [
                    -106.90453828736781,
                    30.622780056155484,
                    -103.61944840737726,
                    32.81804623688849
                ]
            }
        ],
        "bbox": [
            -106.90453828736781,
            30.622780056155484,
            -103.61944840737726,
            32.81804623688849
        ]
    },
    "hoverinfo": "skip",
    "locations": [
        1
    ],
    "marker": {
        "line": {
            "color": "green",
            "width": 3
        }
    },
    "showscale": false,
    "text": "SELECTION_TRACE",
    "z": [
        1
    ],
    "type": "choropleth",
    "selectedpoints": [
        0
    ]
}
  constructor(
    override exportService: ExportService,
    private mapPlotService:MapPlotService
  ) {
    super(exportService);

    this.selectionOverlay = null;
  }

  override bindEvents(): void {
    const that = this;
    this.graphRef.on("plotly_selected", function(data) {
      if(data === undefined) {
        return;
      }

      const selection = new MapAreaEx();
      selection.geometry = new MapGeometry();
      selection.geometry.type = "Polygon";

      if(data.lassoPoints != null) {
        selection.geometry.coordinates = [data.lassoPoints["geo"]];
      } else {
        // const coordinates = data.range["geo"];
        const northWest = [data.range["geo"][0][0], data.range["geo"][0][1]];
        const southWest = [data.range["geo"][0][0], data.range["geo"][1][1]];
        const southEast = [data.range["geo"][1][0], data.range["geo"][1][1]];
        const northEast = [data.range["geo"][1][0], data.range["geo"][0][1]];

        selection.geometry.coordinates = [[northWest,southWest,southEast,northEast,northWest]];
      }
      // choose only string values as hexes
      selection.hexValues = data.points.map(
        x => typeof x["location"] === 'string' ? x["location"] : undefined
      ).filter(val => val !== undefined);

      that.selectionOverlay = selection;
      that.applySelectionOverlay(selection);

      if(selection.geometry.coordinates.length === 5) {
        that.rectangleSelected.emit(selection);
      } else {
        that.lassoSelected.emit(selection); 
      }
    });
    
    this.graphRef.on("plotly_relayouting", function(data) {
      this.isDragging = true;
    });

    this.graphRef.on("plotly_click", function(data) {
      if(!this.isDragging) {
        that.pointSelected.emit(data);
      }

      this.isDragging = false;
    });
  }


  applySelectionOverlay(mapData: MapAreaEx): void {
    this.selectionOverlay = mapData;

    if(mapData !== null) {
      this.traceTemplate.geojson.features[0].geometry = this.selectionOverlay.geometry;
      this.GraphData.data.push(this.traceTemplate);
    }
    Plotly.update(this.graphName, this.GraphData.data, MAP_LAYOUT);
  }

  override applyData(data: any, type: string): void {
    this.headerType = type;
    this.loadStatus = 1;
    this.GraphData = data;
    // Convert pixel values to viewport units
    this.plotWidth = this.GraphData.layout['width'] / (window.innerWidth / 100) / 100;
    this.plotHeight = this.GraphData.layout['height'] / (window.innerHeight / 100) / 100;
    // Assign converted values to layout
    // this.GraphData.layout.width = window.innerWidth * this.plotWidth;
    // this.GraphData.layout.height = window.innerHeight * this.plotHeight;
    

    this.resetGraph();
  }
  resetGraph(): void {
    Plotly.newPlot(this.graphName, this.GraphData.data, MAP_LAYOUT, MAP_CONFIG).then(x =>
      {
        this.graphRef = x;
        this.bindEvents();
      }
    );
  }
  updateZoom(): void {
    
    const center = GraphFunctions.getGeoCenter(this.selectionOverlay.geometry);

    const layoutData = {
      geo: {
        center: {
          lat: center.latitude,
          lon: center.longitude
        },
        fitbounds: false,
        projection: {
          rotation: {
            lon: center.longitude
          },
          scale: 190.41968637944794
        }
      }
    };
    
    Plotly.update(this.graphName, {}, layoutData);
  }
}