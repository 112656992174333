import { CommonModule, DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from  '@angular/common/http';
import { Compiler, Component, NgModule, OnInit, ViewChild, ViewContainerRef, Input, Output, EventEmitter } from '@angular/core';
import { InterfaceFolderDto } from '../../app/classes/InterfaceFolderDto';
import { InterfacePageDto } from '../../app/classes/InterfacePageDto';
import { InterfaceSectionDto } from '../../app/classes/InterfaceSectionDto';
import { InterfaceItemDto } from '../../app/classes/InterfaceItemDto';
import { InterfaceItemOptionDto } from '../../app/classes/InterfaceItemOptionDto';
import { InterfaceSourceDto } from '../../app/classes/InterfaceSourceDto';

import { FolderModule } from '../dynamicControls/folder-component/folder-module';
import { FolderComponent } from '../dynamicControls/folder-component/folder-component';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CommonFunctions } from 'src/classes/CommonFunctions';


@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss']
})
export class MainPageComponent {

  structure: InterfaceFolderDto;

  @ViewChild('templateView', {read: ViewContainerRef})
  templateView!: ViewContainerRef;

  constructor(private http: HttpClient, protected _compiler: Compiler, private router: Router) {
    this.structure = new InterfaceFolderDto();
    this.buildStructure();
  }

  buildStructure(): void {
     this.http.get<InterfaceFolderDto>("assets/jsons/wizard.json", CommonFunctions.headerInfo)
      .subscribe( data => 
        {
          this.structure = data;
          this.buildInterface();
        }
      );
  }
  
  buildInterface(): void {
    this._compiler.compileModuleAndAllComponentsAsync(FolderModule)
    .then((factories) => {

      //  Get the Folder
      const f = factories.componentFactories[0];
      const cmpRef = this.templateView.createComponent(f);
      cmpRef.instance.name = 'dynamic';
      cmpRef.setInput("data", this.structure);
      var componentX =  cmpRef.instance as FolderComponent;
      componentX.sendSubmit.subscribe(x => this.receiveSubmit(x));
    })
  }

  receiveSubmit(data: any){
    this.router.navigate(["/toolbox"]);
  }
}
