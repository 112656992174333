import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { WorkFlowInputModel } from 'src/models/workflow-input.model';
//  import { SignalrService } from './signalr.service';
import { CommonService } from './common.service';
import { BaseService } from './service-base.service';
import { saveAs } from 'file-saver';
import { MatSnackBar } from '@angular/material/snack-bar';
@Injectable({
  providedIn: 'root'
})

export class WorkflowBuilderService extends BaseService {
  private workFlowBuilderUrl = environment.apoloWebApiURL + 'AliceUI/LaunchWorkFlow/';
  workFlowInputModel: WorkFlowInputModel;
  AMLPipelineId: string;
  constructor(
    http: HttpClient,
    //  public signalrService: SignalrService,
    public commonService: CommonService,
    snackBar: MatSnackBar
  ) {
    super(http, snackBar);
  }
  setAMLPipelineId(value) {
    this.AMLPipelineId = value;
  }
  getAMLPipelineId() {
    return this.AMLPipelineId;
  }
  public getRunData(source: string, author: string, date: string, version: string): Observable<any> {
    const params: HttpParams = new HttpParams()
      .set('bu', this.commonService.bu)
      .set('source', source)
      .set('author', author)
      .set('date', date)
      .set('version', version)
    return this.http.get(this.workFlowBuilderUrl + 'RunData', { 'params': params })
  }
  public postWorkflowBuilderData(inputParam: WorkFlowInputModel): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(inputParam);
    return this.http.post(this.workFlowBuilderUrl + 'InferenceMode', body, { 'headers': headers });
  }
  public getJobProgress(AMLPipelineId: string): Observable<any> {
    const params: HttpParams = new HttpParams()
      .set('AMLPipelineId', AMLPipelineId)
    return this.http.get(this.workFlowBuilderUrl + 'JobStatus', { 'params': params })
  }
  public downloadUpdatedYamlFile() {
    const fileName = this.commonService.bu + "_template.yml"
    const headers = { 'content-type': 'application/json' };
    return this.http.get(this.workFlowBuilderUrl + 'DownloadYamlFile',
      { headers, responseType: 'blob' as 'json' })
      .pipe(catchError(this.handleError))
      .subscribe((response: any) => {
        saveAs(response, fileName)
      }),
      map(() => {
        return;
      })
  }
}





