import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
 }
if(environment.environmentTag=='LOCAL' || environment.environmentTag=='DEV' ){
  //do nothing
}
 else{
  window.console.log=function(){}; //replace blank function console logs for other modes
 }
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
