import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { DynamicComponent } from "src/classes/templates/dynamic.component";

@Component
(
{selector: "dyn-dropdown", templateUrl: "./drop-down-control.html", 
styleUrls: ["./drop-down-control.scss"]  }
)

export class DropDownControlComponent extends DynamicComponent<string> implements OnInit{
    constructor() {
      super();
    }
  
    ngOnInit(): void {
      super.baseInit();

      this.defaultValue = this.data.defaultValue;

      super.emitValue(this.defaultValue);
    }

    onChange(value: any): void {
      this.valid = true;
      this.dirty = (value != this.defaultValue);

      super.emitValue(value);
    }
  }