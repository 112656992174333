import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActionItem } from 'src/classes/loadingActions/ActionItem';
import { ActionStatus } from 'src/classes/loadingActions/ActionStatus';

@Component({
  selector: 'app-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.scss']
})
export class LoadingScreenComponent implements OnInit{
  actions: Array<ActionItem>;
  ActionComplete = ActionStatus.COMPLETE;
  ActionFailed = ActionStatus.FAILED;
  ActionLoading = ActionStatus.LOADING;

  percentDone = 0;

  constructor(@Inject(MAT_DIALOG_DATA) public data: Array<ActionItem>) {
    this.actions = data;
  }

  ngOnInit(): void {
  }

  updateActionStatus(index: number, status: ActionStatus): void {
    this.actions.filter(act => act.index === index).forEach(action => action.status = status);
    this.percentDone = (this.actions.filter(action => action.status !== ActionStatus.LOADING).length / this.actions.length) * 100;
  }

  updateActionList(actionList: Array<ActionItem>): void {
    this.actions = actionList;
  }
}
