import { Compiler, Component, ComponentRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef } from "@angular/core";
import { InterfacePageDto } from "src/app/classes/InterfacePageDto";
import { SectionModule } from "../section-component/section-module";
import { SectionComponent } from "../section-component/section-component";
import { ControlModule } from "../control-component/control-module";
import { ControlComponent } from "../control-component/control-component";

@Component
({
    selector: "dyn-page", 
    templateUrl: "./page-component.html",
    styleUrls: ["./page-component.scss"]
})
export class PageComponent implements OnInit{
    @Input("data") data: InterfacePageDto | undefined;
    @Input("visibility") visibility: boolean;
    @Input("index") index: number;
    
    @ViewChild('templateView', {read: ViewContainerRef})
    templateView!: ViewContainerRef;

    sections: ComponentRef<any>[];
    title: string;


    @Output() updateValue : EventEmitter<any> = new EventEmitter<any>(); 
    @Output() sendSubmit : EventEmitter<any> = new EventEmitter<any>(); 

    constructor(protected _compiler: Compiler) {
        this.visibility = false;
        this.index = 0;
        this.sections = new Array<ComponentRef<any>>();
        this.title = "";
    }
  
    ngOnInit(): void {
      this.build();
    }

    build(): void {

        if(this.data != undefined)
        {
            this.title = this.data?.title;
        }
        this._compiler.compileModuleAndAllComponentsAsync(SectionModule)
        .then((factories) => {
            const folderData = this.data;

            if(folderData != undefined)
            {
                const sectionData = folderData.sections;

                for(let i = 0; i < sectionData.length; ++i)
                {
                    const currentSection = sectionData[i];
                    const f = factories.componentFactories[0];
                    const cmpRef = this.templateView.createComponent(f);
                    cmpRef.instance.name = "DynamicPage_" + i;
                    cmpRef.setInput("data", currentSection);
                    cmpRef.setInput("visibility",  true);


                    var componentX =  cmpRef.instance as SectionComponent;
                    componentX.updateValue.subscribe(x => this.receiveUpdate(x, i));
                    componentX.submitForm.subscribe(x => this.receiveSubmit(x, i));

                    this.sections.push(cmpRef);
                }
            }
          //  Get the Folder
          
        });
        

        if(this.data.items != null && this.data.items.length > 0)
        {
            this._compiler.compileModuleAndAllComponentsAsync(ControlModule)
            .then((factories) => {
        
                const folderData = this.data;
                if(folderData != undefined)
                {
                    const itemData = this.data.items;

                    for(let i = 0; i < itemData.length; ++i)
                    {
                        const currentControl = itemData[i];
                        const f = factories.componentFactories[0];

                        const cmpRef = this.templateView.createComponent(f);

                        cmpRef.instance.name = "DynamicItem_" + i;
                        cmpRef.setInput("data", currentControl);

                        var componentX =  cmpRef.instance as ControlComponent;
                        componentX.updateValue.subscribe(x => this.receiveUpdate(x, i));
                        componentX.submitForm.subscribe(x => this.receiveSubmit(x, i));

                        this.sections.push(cmpRef);
                    }
                }
                //  Get the Folder
            
            });
        }
    }
  
    sectionCallback(data: any, index: number) : void
    {
        console.log("Section " + index + " says " );
        console.log(data);
    }
    
    receiveUpdate(data: any, index: number){
        this.updateValue.emit(data);
    }

    receiveSubmit(data: any, index: number){
        this.sendSubmit.emit(data);
    }
}