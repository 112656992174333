<div [ngClass]="plotCssClass">
    <div *ngIf="loadStatus === 1" class="plotlyHeader">
        <div class="plotZoomInDiv"></div> <!-- Fills space on left to help center title and left align butotn  -->
        <div [ngSwitch]="headerType">
            <div *ngSwitchCase=""></div>
            <div *ngSwitchCase="'custom'">
                {{ headerText }}
                <div *ngIf="headerSubText != null" class="plotlySubHeader">
                    {{ headerSubText}}
                </div>
            </div>
            <div *ngSwitchCase="'type_curve'">
                Existing
                <div class="plotlySubHeader">
                    Type Curve Playbook
                </div>
            </div>
            <div *ngSwitchCase="'rules_based'">
                Rules Based
                <div class="plotlySubHeader">
                    Forge
                </div>
            </div>
            <div *ngSwitchCase="'machine_learning'">
                Machine Learning
                <div class="plotlySubHeader">
                    f(&minus;) &minus; GP(&mu;, k(&minus; , &minus;))
                </div>
            </div>
            <div *ngSwitchDefault>
                What is here?
                <div class="plotlySubHeader">
                    f(&minus;) &minus; GP(&mu;, k(&minus; , &minus;))
                </div>
            </div>
        </div>            
        <div class="plotZoomInDiv">
            <button *ngIf="exportType" class="btn-export" (click)="exportService.exportData(exportType)" title="Export data" ></button>
            <button *ngIf="isExpandable" class="btn-square" 
                matTooltip="{{ plotResize ? 'Normal Mode' : 'Focus Mode' }}"
                matTooltipPosition="above" 
                (click)="plotZoomEvent()">
                {{ plotResize ? '-' : '+' }}
            </button>
        </div>
    </div>
    <div class="plotly-view">
        <div *ngIf="loadStatus === -1" class="{{ getLoadingClass() }}">
            <!-- <span class="plotly-loading-text">Ready</span> -->
        </div>
        <div *ngIf="loadStatus === 0" class="plot-loading-container">
            <span class="plotly-loading-text">Loading</span>
        </div>
        <div [style.display]="loadStatus == 1 ? 'block' : 'none'">
            <div class="grid">
                <div>
                    <div id="{{ graphName }}"></div>
                </div>
            </div>
        </div>
    </div>
</div>