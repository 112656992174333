import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { DatePipe } from "@angular/common";
import { MatSlider } from "@angular/material/slider";

@Component
(
{selector: "dyn-slider-range", templateUrl: "./slider-range-control.html" }
)

export class SliderRangeControlComponent implements OnInit{
    @Input("data") data: any;
    @Output() updateValue : EventEmitter<any> = new EventEmitter<any>();

    @Input("visibility") visibility: boolean;

    @ViewChild("slider") slider: MatSlider;

    defaultValue: string;
    sizeWidth: string;
    minimum: number;
    maximum: number;
    dirty: boolean;
    valid: boolean;
    baseDate: Date;

    constructor() {
      this.visibility = false;
      this.defaultValue = "";
      this.dirty = false;
      this.minimum = this.maximum = 0;
      this.valid = true;
      this.sizeWidth = "";
    }

  
    formatDate(value: number) : string {
      
      if(this.baseDate == undefined)
      {
        this.baseDate = new Date();
        this.baseDate.setDate(1);
      }
      var newDate = new Date();
      newDate.setDate(this.baseDate.getDate() + value);

      return (newDate.getMonth() + 1) + "/" + newDate.getDate();
    }

    ngOnInit(): void {
      this.baseDate = new Date();
      this.baseDate.setDate(1);
      

      this.defaultValue = this.data.defaultValue;
      this.minimum = this.data.minimum;
      this.maximum = this.data.maximum;
      this.visibility = true;
      switch(this.data.size)
      {
        case "t":
          this.sizeWidth = "20px";
          break;
        case "s":
          this.sizeWidth = "50px";
          break;
        case "l":
          this.sizeWidth = "200px";
          break;
        case "x":
          this.sizeWidth = "300px";
          break;
        default:
        case "m":
          this.sizeWidth = "100px";
          break;
      }
    }

    onChange(value: any): void {
      

      this.dirty = (value != this.defaultValue);

      this.updateValue.emit({
        name: this.data.data,
        value: value
      });
    }
  }