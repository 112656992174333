export class LocationInfo {
    businessUnit?: string;
    basin?: string;
    product?: string;
    bench?: string;

    public constructor(init?: Partial<LocationInfo>) {
        Object.assign(this, init);
    }

    public static isEqual(first: LocationInfo, second: LocationInfo, details: Array<string>): boolean {
        let valid = true
        if(first === null || first === undefined || second === null || second === undefined) {
            return first === second;
        }

        if(first.businessUnit !== second.businessUnit) {
            details.push("Business Unit");
            valid = false;
        }
        if(first.basin !== second.basin) {
            details.push("Basin");
            valid = false;
        }
        if(first.product !== second.product) {
            details.push("Product");
            valid = false;
        }
        if(first.bench !== second.bench) {
            details.push("Bench");
            valid = false;
        }

        return valid;
    }
}