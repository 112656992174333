<div class="sub-header-container">
    <div class="subHeader">
        Pad Scenario Builder
    </div>
</div>
<div class="d-flex flex-column">
    <div class="d-flex flex-row">  
        <div class="d-flex flex-column align-items-center">  
            <div class="text-center input-label">
                Select a hex on the map to see prediction details
            </div>
            <app-plotly-view-select-hex #plotlySelect [isExpandable]="false" graphName="plotlySelect" (selectHex)="selectHex($event)"></app-plotly-view-select-hex>
            <div class="d-flex justify-content-center align-items-center">
                <span>Base Map: </span>
                <span style="margin-left: 1vw;">
                    <mat-form-field class="custom-mat-dropdown" subscriptSizing="dynamic">
                        <mat-select   (selectionChange)="modelChange($event.value)" [(ngModel)]="selectedModel">
                            <mat-option *ngFor="let model of getModels()" [value]="model">
                                <div class="d-flex align-items-center">
                                    <div class="ModelDisplay__Position--Position{{ model.position }}">
                                        {{ model.position }}
                                    </div><span style="margin-left: 0.3vw;">|</span>
                                    <div class="selectedModelRunIdHolder">
                                        {{ model.friendlyName }}
                                    </div>
                                </div>

                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </span>
            </div>
        </div>
        <div class="d-flex flex-column align-items-center detail-section">
            <button class="btn-medium" 
                [disabled]="!dataLoaded"
                matTooltip="Get Gun Barrel"
                matTooltipPosition="above" 
                (click)="getGunBarrel()">
                Get Gun Barrel
            </button>
            <div class="plotViewContainer">
                <div class="plotView">
                    <plotly-view #gunBarrel header="" graphType="{{ getScatterGraphyType() }}" [width]=800 [height]=500 graphName="gunBarrel" headerText="Gun Barrel"></plotly-view>
                </div>
            </div>           
            <prediction-summary class="mb-sm-2 w-100" [selectedHexes]="selectedHexes"></prediction-summary>
            <div>
                <!-- <app-card-box style="display: flex;justify-content: center;"> -->
                    <div class="card-container">
                    <p class="m-sm-0">Gun Barrel plots use the values from the Model Controls in the left selection panel. 
                        Adjust configuration by changing Well Per Section, Horizontal Offest, and Vertical Offset.
                        Clicking Get Gun Barrel after updating model control selections will refresh the visual (clicking Run is <b>not</b> required).</p>
                    </div>
                <!-- </app-card-box> -->
            </div>
        </div>
    </div>
    <div>
        <div class="sub-header-container">
            <div class="subHeader">
                BOD Visualizer
            </div>
        </div>
        <div class="d-flex flex-row w-100 justify-content-center">
            <div #filters class="d-flex flex-column align-items-center w-25" [formGroup]="BODForm">
                <button class="btn-medium mb-sm-2" 
                    matTooltip="Select a hex on the map to enable BOD"
                    matTooltipPosition="above" 
                    (click)="getBOD()"
                    [disabled]="selectedHexes.length === 0">
                    Get BOD
                </button>
                <div #radius class="d-flex flex-column align-items-center">
                    <div>Radius of Investigation</div>
                    <div class="d-flex flex-row justify-content-center align-items-center">
                        <mat-slider min="0" max="20" step="1" showTickMarks>
                            <input type="hidden" />
                            <input #radiusValue matSliderThumb [value]="this.BODForm.controls['radius'].value"
                            formControlName="radius">
                        </mat-slider>
                        <div>
                            {{ this.BODForm.controls['radius'].value }} miles
                        </div>
                    </div>
                </div>  
                <div #property class="d-flex flex-column align-items-center">
                    <div>Property to Analyze</div>
                    <div class="d-flex flex-row justify-content-center align-items-center">
                        <mat-form-field class="custom-mat-dropdown" subscriptSizing="dynamic">
                            <mat-select #property  formControlName="property">
                                <mat-option value="frac_fluid_intensity">Fluid Frac Intensity</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div> 
                <div #window class="d-flex flex-column align-items-center">
                    <div>BOD +/- Window</div>
                    <div class="d-flex flex-row justify-content-center align-items-center">
                        <mat-slider min="5" max="45" step="5" showTickMarks>
                            <input type="hidden" />
                            <input #radiusValue matSliderThumb [value]="this.BODForm.controls['window'].value"
                            formControlName="window">
                        </mat-slider>
                        <div>
                            {{ this.BODForm.controls['window'].value }}%
                        </div>
                    </div>
                </div> 
                <div #predicted class="d-flex flex-column align-items-center">
                    <div>Plot Predicted
                        <mat-checkbox class="example-margin" formControlName="isPredicted"></mat-checkbox>
                    </div>
                </div> 
            </div>
            <div class="d-flex flex-column align-items-center">
                <div class="plotViewContainer">
                    <div class="plotView">
                        <plotly-view #BODProbit header="" graphType="{{ getScatterGraphyType() }}" [width]=800 [height]=500 graphName="BODProbit" headerText="BOD"></plotly-view>
                </div>
            </div>
        </div>
    </div>
</div>