import { Component, Output, EventEmitter, OnInit, ViewChildren, QueryList } from '@angular/core';
import { PlotlyViewComponent } from '../views/plotly-view/plotly-view.component';
import { MapeViewComponent } from '../views/mape-view/mape-view.component';
import { UserSelectionService } from 'src/services/ui/user-selection.service';
import { PredictionDataRequest } from 'src/classes/dataTransfer/requests/PredictionDataRequest';
import { PredictionService } from 'src/services/data/prediction.service';
import { SelectedModel } from 'src/classes/SelectedModel';
import { TabViewComponent } from '../base/tab-view-base.component';
import { LoadingScreenService } from 'src/services/ui/loading-screen-service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActionIndex } from 'src/classes/loadingActions/ActionIndex';
import { PlotlyService } from 'angular-plotly.js';
import { MapPlotService } from 'src/services/map-plot.service';
import { ActionTexts } from 'src/classes/loadingActions/ActionTexts';
import { ExportService, ExportType } from 'src/services/export.service';

interface results {
  hex: string,
  prediction: number,
  high_confidence: boolean
}

@Component({
  selector: 'plotly-diagnostics',
  templateUrl: './plotly-diagnostics.component.html',
  styleUrls: ['./plotly-diagnostics.component.scss']
})
export class PlotlyDiagnosticsComponent extends TabViewComponent implements OnInit {
  @ViewChildren("slotMap") maps : QueryList<PlotlyViewComponent>;
  @ViewChildren("slotScore") scores : QueryList<MapeViewComponent>;

  pRequest: PredictionDataRequest;
  plotTitle:string;
  readyToExport: boolean;
  timeSlice:string;
  product:string;
  scoringSelection: string='mape';
  public selectedSlot: string | null = null;  // Track the selected slot (slot is shared between map and scoring plots)
  selectedModels: SelectedModel[] = [null, null, null]; // Nulls to show 3 map placeholders while waiting for run click
  slotNames: string[] = ['A','B','C'] // Might want to come from a consts location so that Model Selections form can also use?
  headerTexts:string[]=[];
  exportTypes = ExportType;

  constructor(
    private predictionSource: PredictionService,
    override selectionService: UserSelectionService,
    protected exportService: ExportService,
    private loadingService: LoadingScreenService,
    private snackBar: MatSnackBar,
    private plotlyService:PlotlyService,
    private mapPlotService: MapPlotService ) {
    super(selectionService);
  }

  ngOnInit(): void {
    this.readyToExport = false;

    this.timeSlice = this.selectionService.selections.configuration.timeslice;
    this.product = this.selectionService.selections.location.product;
    this.plotTitle= this.product && this.timeSlice ? 
          this.capitalizeTitle("Cumulative " + this.product + " at " + this.timeSlice + " months for 2 mile laterals")
          : ""
  }

  notifyAnalogMissing(): void {
    this.snackBar.open("Please choose a scoring model from selected models.", "OK", { duration: 3000 });
  }
  loadData(): void {
    if(!this.dataLoaded) {
      this.selectedModels = this.selectionService.selections.selectedModels;
      this.pRequest = this.selectionService.getLastRunPredictionRequest(true);
      if(this.pRequest.analogChoice === null || this.pRequest.analogChoice === undefined){
        this.notifyAnalogMissing();
        return;
      }
      this.pRequest.summarize = this.scoringSelection;
      this.timeSlice=this.pRequest.timeslice.toString();
      this.product=this.pRequest.product;

      //  It depends right now which tab is running  
      this.loadMaps();
      this.loadScores();
      this.setDataLoaded(true);
    }
  }

  loadMaps(): void {
    this.maps.forEach(map => {
      map.initLoading();
    })

    this.requestPredictionMaps();
  }

  loadScores(): void {
    this.scores.forEach(score => {
      score.initLoading();
    })

    this.requestPredictionScores();
  }

  requestPredictionMaps(): void {
    this.mapPlotService.getMaps().subscribe(
      data => {
        this.pRequest.runIds.forEach((model, index) => {
          this.setHeaderTextsForExport(model.type);
          this.maps.find((_, i) => i == index).createMap(data[model.id].layers, model.type);
          const map=this.maps.find((_, i) => i == index);
          this.mapPlotService.savePlot(map.graphName,this.plotTitle,this.headerTexts[index],this.headerTexts[index]);
        });
    });
  }

  requestPredictionScores(): void {
    this.loadingService.addAction(ActionIndex.RETRIEVING_SCORING, ActionTexts.RETRIEVING_SCORING);
    this.predictionSource.getScoreSummaryPlot(this.pRequest).subscribe(
      data => {
        this.pRequest.runIds.forEach((model, index) => this.applyScoreData(data[model.id], index));
        this.loadingService.actionComplete(ActionIndex.RETRIEVING_SCORING);
      }
    );
  }

  applyScoreData(data: any, index: number): void {
    this.scores.find((_, i) => i == index).applyData(data);
    this.readyToExport = true;  // This should only apply to the data that has been loaded, not all the data in the component
  }

  scoringChange(newScore: string): void {
    if(this.pRequest) { // Prevent reloading scores if Run has not already been executed
      this.pRequest.summarize=newScore;
      this.loadScores()
    }
  }

  updateLayout(layoutData: any, index: any) {
    this.maps.forEach((map, i) => {
      if(index !== i)
        map.forceMoveGraph(layoutData);
    })
  }

  onSelectedSlotChange(newValue: string): void {
    this.selectedSlot = newValue;
  }

  exportMaps() {
   this.downloadGraph();
  }

  setHeaderTextsForExport(modelType) {
    switch (modelType) {
      case "type_curve":
        this.headerTexts.push('Type Curve');
        break;
      case "rules_based":
        this.headerTexts.push('Forge');
        break;
      case "machine_learning":
        this.headerTexts.push('Machine Learning');
        break;
    }
  }

  public async downloadGraph(): Promise<void> {
    const mRequest = this.selectionService.getCurrentModelRequest();
    const plotly = await this.plotlyService.getPlotly();
    let filename = mRequest.business_unit.toLowerCase() + '-' +
      mRequest.basin + '_' +
      mRequest.bench + '_' +
      'predictions_map' + '_' +
      (+new Date).toString(36); //hashing datetime
    this.maps.forEach((map, i) => {
      plotly.downloadImage(map.graphName,
        {
          format: 'png',
          filename: filename + '_' + map.headerType
        });
        
    });

  }
}
