<div style="display: flex; flex-direction: column;">
  <div class="headline header-container">
    <!-- for any far left header items -->
    <div style="padding-left: 0px;">
      <!-- <img src="./assets/img/apolo.png" class="imgApolo"  alt="APOLO Logo"> -->
      <img src="./assets/img/chevron_logo.png" class="imgChevron" alt="Chevron Logo">
    </div> 
    <!-- for centered title-->
    <div class="title-container">
      <!-- <span id='user-name' class="user-name">{{username}}</span>
        <img src="./assets/img/logout.png" class="imgLogout" alt="" (click)="logout()"> -->
        <!-- <img src="./assets/img/user_profile.png" class="imgUser" alt=""> -->
      <!-- <img src="./assets/img/apolo.png" class="imgApolo"  alt="APOLO Logo"> -->
      <!-- <span class="pt" id='span-value'>APOLO</span> -->
      <span class="stroke-shadow">APOLO</span>
      <!-- <img src="./assets/img/chevron_logo.png" class="imgChevron" alt="Chevron Logo"> -->
    </div>
    <!-- for any far right nav items-->
    <div class="headline title-container dev-subtitle" style="padding-right: 5px;">
      <span *ngIf="showVersion">
        {{ versionText }}
      </span>
    </div>
  </div>
</div>
