
<div class="sideNavContent">
    <div class="primaryContainer">
        <form [formGroup]="userSelectionForm" style="display: flex; flex-direction: column;">
            <div class="submit-btn">
                <button class="btn-medium btn-run" style="width: 100%;"  (click)="outputGraphs()">Run</button>
                
            </div>            
            <div *ngIf="controlsAreInvalid" class="ViewControls__ValidationAlert" title="Current controls are out of sync with results.&#010;Please press run to get accurate data.&#010;&#010;The following selections have changed.&#010;{{ changeDetails }}">!</div>
            <div class="form-fields">
                <div class="input-column-section">
                    <mat-card class="side-nav-card" appearance="outlined">
                        <location-group #locationGroup groupName="Location" groupTitle="" [location]="location" (selectionChange)="selectionChange()"></location-group>
                    </mat-card>
                                        
                    <mat-card class="side-nav-card" appearance="outlined">
                        <model-navigation (runQueries)="outputGraphs()" #scoringGroup groupName="Model Selection" 
                            groupTitle="Select Models explicitly through search" [userSelectionForm]="userSelectionForm" (recvModelControls)="recvModelControls($event)" (selectionChange)="selectionChange()">
                        </model-navigation>
                    </mat-card>

                    <mat-card class="side-nav-card" appearance="outlined">
                        <configuration-group #configurationGroup 
                            groupName="Analysis Configuration" 
                            groupTitle="Choose Model to use for base scoring" 
                            [configuration]="configuration" 
                            (selectionChange)="selectionChange()" 
                            (notifyBODPlaybook)="toggleBODPlaybook($event)">
                        </configuration-group>
                    </mat-card>
                    
                    <mat-card class="side-nav-card" appearance="outlined" [style.display]="(this.showModelControlA || this.showModelControlB || this.showModelControlC) && this.showModelControls ? 'block' : 'none'">
                        <div class="HEADER_CONTAINER" matTooltip="Advanced controls for models.">
                            <div class="GROUP_NAME" (click)="collapseControls()">Model Controls</div>
                            <div *ngIf="collapsedControls" class="EXPAND_GROUP" (click)="collapseControls()"><mat-icon>chevron_right</mat-icon></div>
                            <div *ngIf="!collapsedControls" class="EXPAND_GROUP" (click)="collapseControls()"><mat-icon>expand_more</mat-icon></div>
                        </div>
                        <div [style.display]="collapsedControls ? 'none' : ''">
                            <mat-card class="side-nav-card-model-controls" appearance="outlined" [style.display]="this.showModelControlA ? 'block' : 'none'">
                                <app-model-controls #modelControlsA (selectionChange)="selectionChange()"></app-model-controls>
                            </mat-card>

                            <mat-card class="side-nav-card-model-controls" appearance="outlined" [style.display]="this.showModelControlB ? 'block' : 'none'">
                                <app-model-controls #modelControlsB (selectionChange)="selectionChange()"></app-model-controls>
                            </mat-card>
                            
                            <mat-card class="side-nav-card-model-controls" [style.display]="this.showModelControlC ? 'block' : 'none'">
                                <app-model-controls #modelControlsC (selectionChange)="selectionChange()"></app-model-controls>
                            </mat-card>
                        </div>
                    </mat-card>
                </div>
            </div> 
        </form>
        <div class="default-btns stacked-btn-container">
            <button class="btn-medium-secondary--cancel" (click)="saveDefaultScenario()" matTooltip="Save the current location selections as your default">Save My Options</button>
            <button class="btn-medium-secondary" (click)="getDefaultScenario()" matTooltip="Load your previously saved options">Load My Options</button>
        </div>
    </div>
</div>