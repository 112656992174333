import { QueryStringItem } from "./QueryStringItem";

export class RequestBuilder {
    items: Array<QueryStringItem>;
    host: string;
    function: string;
    functionEx: string = null;
    
    public constructor(hostName: string, functionName: string ) {
        this.host = hostName;
        this.function = functionName;
        this.items = new Array<QueryStringItem>();
    }


    public addItem(name: string, value: string) {
        this.items.push(new QueryStringItem(name, value));
    }

    public getUrl(): string {
        let url = this.host;
        url += this.function;

        url += this.generateQueryString();
        return url;
    }

    public getPostUrl(): string {
        let url = this.host;
        url += this.function;
        return url;
    }

    private generateQueryString() : string {
        let qString = "?";
        for(let i = 0; i < this.items.length; ++i) {
            const current = this.items[i];

            qString += current.toText();

            if(i < this.items.length - 1) {
                qString += "&";
            }
        }

        return qString;
    }
}