<div class="RADIO_STYLE" [style.display]="direction=='vertical' ? 'block' : 'flex'">
    <div *ngFor="let option of data.options; index as i;" style="margin-left: 10px;">
        <img [src]="option.imgUrl" [class]="option.value == defaultValue ? 'IMG_STYLE_SELECTED' : 'IMG_STYLE'" (click)="forceChange(option.value)"/>
        <div style="width: 100%; text-align: center;">
            {{option.value}}
        </div>
    </div>
    <br/>
</div>
<br/>
<div>Selected Value: {{defaultValue}}</div>