import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SectionComponent } from "./section-component";
@NgModule({
    declarations: [
        SectionComponent
    ],
    imports: [
        CommonModule
    ],
    providers: []
  })
  export class SectionModule { }