import { Component, ViewChild } from '@angular/core';
import { ComparisonSettings } from 'src/classes/ComparisonChoices';
import { PredictionService } from 'src/services/data/prediction.service';
import { PredictionDataRequestComparison } from 'src/classes/dataTransfer/requests/PredictionDataRequest';
import { PlotlyViewComponent } from '../views/plotly-view/plotly-view.component';
import { TabViewComponent } from '../base/tab-view-base.component';
import { UserSelectionService } from 'src/services/ui/user-selection.service';
import { LoadingScreenService } from 'src/services/ui/loading-screen-service';
import { ActionIndex } from 'src/classes/loadingActions/ActionIndex';
import { ComparisonGroupComponent } from '../controls/comparison-group/comparison-group.component';
import { MapPlotService } from 'src/services/map-plot.service';

@Component({
  selector: 'comparison',
  templateUrl: './comparison.component.html',
  styleUrls: ['./comparison.component.scss']
})
export class ComparisonComponent extends TabViewComponent{
  @ViewChild("comparisonView") comparisonView: PlotlyViewComponent;
  @ViewChild("comparisonGrp") comparisonGroup: ComparisonGroupComponent;

  constructor(
    private predictionSource: PredictionService,
    override selectionService: UserSelectionService,
    private loadingService: LoadingScreenService,
    private mapPlotService: MapPlotService
  ) {
    super(selectionService);
  }

  type: string = '';
  exportTitle:string;
  loadDefaultValues(){
    this.comparisonGroup.loadDefaultValues();
  }
  doCompare(event: ComparisonSettings) {
    const pRequest = this.selectionService.getPredictionRequestBase() as PredictionDataRequestComparison;
    this.type = event.type;
    pRequest.metric = event.metric;
    pRequest.runId = [];
    
    let result: any = null;
    if(event.type === "difference") {
      this.comparisonView.initLoading();
      this.comparisonView.headerText = "Percent Difference";
      this.comparisonView.headerSubText = "(" + event.alternativeModel.friendlyName + " - " + event.baselineModel.friendlyName + ") / " + event.baselineModel.friendlyName;
      result = this.mapPlotService.getPercentDifferenceMap(event.baselineModel.runId, event.alternativeModel.runId);
    } else if (event.type === "variance") {
      pRequest.runId = event.varianceModels.map(m => (m.runId));
      this.comparisonView.initLoading();
      this.comparisonView.headerText = "Standard Deviation";
      this.comparisonView.headerSubText = null;
      result = this.mapPlotService.getStandardDeviationMap(event.varianceModels.map(m => (m.runId)));
    }

    if (result !== null) {
      this.comparisonView.createMap(result.layers, "custom");
      this.loadingService.actionComplete(ActionIndex.RETRIEVING_PLOTS);
    }
  }
}
