import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";

@Component
(
  {
    selector: "dyn-text-area", 
    templateUrl: "./text-area-control.html" 
  }
)


export class TextAreaControlComponent implements OnInit{
    @Input("data") data: any;
    @Output() updateValue : EventEmitter<any> = new EventEmitter<any>();

    @Input("visibility") visibility: boolean;

    defaultValue: string;
    sizeWidth: string;
    sizeHeight: string;
    minLength: number;
    maxLength: number;
    dirty: boolean;
    valid: boolean;

    constructor() {
      this.visibility = false; 
      this.defaultValue = "";
      this.dirty = false;
      this.minLength = this.maxLength = 0;
      this.valid = true;
      this.sizeWidth = this.sizeHeight = "";
    }
  
    ngOnInit(): void {
      this.defaultValue = this.data.defaultValue;
      this.minLength = this.data.minimum;
      this.maxLength = this.data.maximum;
      this.visibility = true;
      switch(this.data.size)
      {
        case "t":
          this.sizeWidth = "100px";
          this.sizeHeight = "50px";
          break;
        case "s":
          this.sizeWidth = "200px";
          this.sizeHeight = "50px";
          break;
        case "l":
          this.sizeWidth = "400px";
          this.sizeHeight = "50px";
          break;
        case "x":
          this.sizeWidth = "500px";
          this.sizeHeight = "50px";
          break;
        default:
        case "m":
          this.sizeWidth = "300px";
          this.sizeHeight = "50px";
          break;
      }
    }

    onChange(value: any): void {
      this.valid = true;
      if(this.minLength != undefined && value.length < this.minLength) {
        this.valid = false;
      }
      


      this.dirty = (value != this.defaultValue);

      this.updateValue.emit({
        name: this.data.data,
        value: value
      });
    }
  }