import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ConfigurationInfo } from 'src/classes/ConfigurationInfo';
import { ControlGroup } from 'src/classes/templates/control.group';
import { UserSelectionService } from 'src/services/ui/user-selection.service';
import { SelectedModel } from 'src/classes/SelectedModel';
import { MatSliderDragEvent } from '@angular/material/slider';

@Component({
  selector: 'configuration-group',
  templateUrl: './configuration-group.component.html',
  styleUrls: ['./configuration-group.component.scss']
})
export class ConfigurationGroupComponent extends ControlGroup implements OnInit {
  @Input() configuration: FormGroup;
  @Output() notifyBODPlaybook: EventEmitter<boolean> = new EventEmitter<boolean>();

  selectedModels: Array<SelectedModel>;
  sliderValue: number = 4;
  selectEUR: boolean = false;
  useBODPlaybook: boolean = true;
  eurValue: number = 360;

  timesliceValues: number[] = [3, 6, 12, 18, 24, 36, 48, 60];
  displayValue: number = this.timesliceValues[this.sliderValue];
  EUR_LABEL: string = 'EUR View';
  TIMESLICE_LABEL: string = 'Timeslice View';
  PLAYBOOK_LABEL: string = 'Playbook';
  CUSTOM_LABEL: string = 'Custom';

  constructor(private selectionService: UserSelectionService) {
    super();
  }

  ngOnInit(): void {
    this.baseInit();
  }

  getSelectedModels(): Array<SelectedModel> {
    return this.selectionService.getCurrentModels();
  }

  setAnalogChoice(runId: string): void {
    this.configuration.get('analogSet')?.setValue(runId);
  }

  toggleSwitch(event: any) {
    this.selectEUR = event === this.EUR_LABEL;
    if (this.selectEUR) {
      this.configuration.get('timeslice')?.setValue(this.eurValue);
    } else {
      this.configuration.get('timeslice')?.setValue(this.displayValue);
    }
    this.selectionUpdate();
  }

  toggleBOD() {
    this.useBODPlaybook = !this.useBODPlaybook;
    this.configuration.get('useBODPlaybook')?.setValue(this.useBODPlaybook);
    this.notifyBODPlaybook.emit(this.useBODPlaybook);
  }

  selectTimeslice(evt: number) {
    this.displayValue = this.timesliceValues[evt];
    this.configuration.get('timeslice')?.setValue(this.displayValue);
    this.selectionUpdate();
  }

  formatLabel(value: number) {
    // not sure why using this.timesliceValues doesn't work ...
    return '' + [3, 6, 12, 18, 24, 36, 48, 60][value];
  }

}
