import { Component, OnInit, ViewChild, Output, EventEmitter, ViewEncapsulation, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { UserSelectionService } from 'src/services/ui/user-selection.service';
import { UserSelections } from 'src/classes/UserSelections';
import { mcbu_defaults } from 'src/constants/bu.constants';
import { Subject, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { InterfaceSectionDto } from 'src/app/classes/InterfaceSectionDto';
import { ModelControlsComponent } from '../model-controls/model-controls.component';
import { UserFavoriteService } from 'src/services/data/userFavorite.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ConfigurationGroupComponent } from '../configuration-group/configuration-group.component';

@Component({
  selector: 'view-controls',
  templateUrl: './view-controls.component.html',
  styleUrls: ['./view-controls.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ViewControlsComponent implements OnInit, OnDestroy{
  unsubscribe = new Subject();
  showModelControls = false;
  validateControls = new Subject();
  changeDetails = "";

  controlsAreInvalid = false;
  showModelControlA = false;
  showModelControlB = false;
  showModelControlC = false;
  collapsedControls = false;
  
  @Output() notifyExecute : EventEmitter<boolean> = new EventEmitter<boolean>(); 

  @ViewChild("modelControlsA") modelControlsA: ModelControlsComponent;
  @ViewChild("modelControlsB") modelControlsB: ModelControlsComponent;
  @ViewChild("modelControlsC") modelControlsC: ModelControlsComponent;
  @ViewChild("configurationGroup") configurationGroup: ConfigurationGroupComponent;

  constructor(private readonly formBuilder: FormBuilder,
    protected selectionService: UserSelectionService,
    protected userService: UserFavoriteService,
    private snackBar: MatSnackBar,
  ) { }

  location = this.formBuilder.group({
    businessUnit: [],
    basin: [],
    bench: [],
    product: [],
  });
  configuration = this.formBuilder.group({
    analogSet: [],
    scoringMethod: [],
    timeslice: [],
    useBODPlaybook: [],
  });
  selectedModels = new FormControl();
  userSelectionForm = this.formBuilder.group({
    location: this.location,
    configuration: this.configuration,
    selectedModels: this.selectedModels,
  });

  ngOnInit(): void {
    
    // Subscribe to change made to the form from other components
    this.selectionService.userSelections$.subscribe((value) => {
      this.userSelectionForm.patchValue(value);
    });


    this.userSelectionForm.patchValue(mcbu_defaults);
    this.selectionService.selections = new UserSelections(this.userSelectionForm.value);
    this.userSelectionForm.valueChanges.pipe(
      distinctUntilChanged(), // Prevents the emitting if the 'start' value and the 'end' value are the same
      takeUntil(this.unsubscribe)
    ).subscribe(change=>{ 
      change.configuration.timeslice = change.configuration.timeslice.toString(); // Mat slider returns a number instead of a string. Convert to string prior to saving 
      this.selectionService.selections = new UserSelections(change);
    });
    
    this.validateControls.pipe(debounceTime(500)).subscribe((searchValue) => {
      this.checkControlValidation();
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
    this.validateControls.next(null);
    this.validateControls.complete();
  }

  outputGraphs(): void {
    this.controlsAreInvalid = false;
    if(this.validateInput()) {
      this.notifyExecute.emit(true);
    }
  }
  
  validateInput(): boolean {
    const locationSelection = this.selectionService.getLocationInfo();
    let selectMsg = "You must select Business Unit, Basin, Product and Bench, to proceed.\n\n";

    const selected = new Array();
    if(locationSelection.businessUnit === null) {
      selected.push("Business Unit");
    }
    if(locationSelection.basin === null) {
      selected.push("Basin");
    }
    if(locationSelection.product === null) {
      selected.push("Product");
    }
    if(locationSelection.bench === null) {
      selected.push("Bench");
    }

    if(selected.length !== 0) {
      if(selected.length === 1) {
        selectMsg += selected[0] + " is not selected.";
      } else {
        for(let i = 0; i < selected.length; ++i) {
          if(i == selected.length - 1) {
            selectMsg += "and ";
            selectMsg += selected[i];
          } else if(i != 0 && i == selected.length -2){
            selectMsg += selected[i];
            selectMsg += " ";
          } else {
            selectMsg += selected[i];
            selectMsg += ", ";
          }
        }
        selectMsg += " were not selected";
      }
      this.snackBar.open(selectMsg, "OK", { duration: 3000, panelClass: ['PlotlyPage__Snackbar'] });
      return false;
    }

    return true;
  }

  recvModelControls(controls?: InterfaceSectionDto[]): void {
    if(controls === null) {
      this.showModelControlA = false;
      this.modelControlsA.applyData(null);
      this.showModelControlB = false;
      this.modelControlsB.applyData(null);
      this.showModelControlC = false;
      this.modelControlsC.applyData(null);

    } else {
      if(controls.length > 0) {
        if(controls[0].title !== undefined){
          this.showModelControlA = true;
          this.modelControlsA.applyData(controls[0]);
        }
        
        if(controls.length > 1 && controls[1].title !== undefined){
          this.showModelControlB = true;
          this.modelControlsB.applyData(controls[1]);
        }
        
        if(controls.length > 2 && controls[2].title !== undefined){
          this.showModelControlC = true;
          this.modelControlsC.applyData(controls[2]);
        }
      }
    }


    this.validateAnalogChoice();
  }

  validateAnalogChoice(): void {
    const analogRunId = this.selectionService.getAnalogSetRunId();
    this.configurationGroup.setAnalogChoice(analogRunId);
  }

  saveDefaultScenario() {
    
    this.userService.postUserDefault(this.selectionService.selections).subscribe(x => {
      this.snackBar.open("User Defaults Saved Successfully", null, {duration: 3000, panelClass: ['snackbar-info']});

    });
  }
  
  getDefaultScenario() {
    this.userService.getUserDefault().subscribe(x => {
      if(x) {
        this.userSelectionForm.patchValue(x.userSelections);
        this.snackBar.open("User Defaults Loaded Successfully", null, {duration: 3000, panelClass: ['snackbar-info']});
      }
    });
  }

  toggleBODPlaybook(event) {
    this.showModelControls = !event;
  }
  selectionChange(): void {
    this.validateControls.next(null);
  }

  checkControlValidation(): void {
    if(!this.selectionService.currentControlsAreValid()) {
      this.controlsAreInvalid = true;
      this.changeDetails = this.selectionService.getControlChangeDetails();
    } else {
      this.controlsAreInvalid = false;
    }
  }

  collapseControls(): void {
    this.collapsedControls = !this.collapsedControls;
  }
}
