import { PredictionDataRequest } from 'src/classes/dataTransfer/requests/PredictionDataRequest';
import { RequestDto } from 'src/classes/dataTransfer/dto/RequestDto';
import { Location, Model, Product, ScoreParams} from 'src/classes/dataTransfer/dto/DTO_Components'; 

export function createRequestBody(request: PredictionDataRequest, hexValues?: string[]): RequestDto
{   
    // where
    const location = new Location(request.basin, request.bench);
    if (hexValues && hexValues.length > 0) {
        location.hexes = hexValues;
    }

    // what and when
    const product = new Product(request.product, request.timeslice);

    // with which models
    let models = new Array<Model>();
    request.runIds.forEach(element => { models.push(new Model(element.id)) });
    // add model controls
    const controls = request.getModelControls();
    controls.forEach(control_bundle => {
        const control_id = control_bundle.runId;
        const control = control_bundle.controlValues;
        if (control != undefined) {
            models.some(model => {
                if (model.run_id == control_id) {
                    model.controls = control;
                    return true;  // break loop
                }
                return false;
            });
        }
    });
    // setup the package
    const request_body = new RequestDto(
        location, product, models
    )
    request_body.analog_model = new Model(request.analogChoice);
    return request_body;
}