
import {getResolution, getHexagonEdgeLengthAvg, gridDisk} from "h3-js";

const res = 7;

export function getHexesMiles(hex: string, radius: number): string[] {
    const meters = milesToMeters(radius);
    const k = calculateK(hex, meters);

    const hexes = gridDisk(hex, k);
    return hexes;
}

export function milesToMeters(miles: number): number {
    return miles * 1609.34;
}

function calculateK(hex: string, radiusInMeters: number): number {
    const edgeLength = getHexagonEdgeLengthAvg(res, "m");
    const apothem = Math.sqrt(3) / 2 * edgeLength;
    return Math.floor(radiusInMeters / (apothem * 2));
}

