import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { PlotlyViewComponent } from '../views/plotly-view/plotly-view.component';
import { ExportService, ExportType } from 'src/services/export.service';
import { ModelService } from 'src/services/data/model.service';
import { UserSelectionService } from 'src/services/ui/user-selection.service';
import { TabViewComponent } from '../base/tab-view-base.component';
import { LoadingScreenService } from 'src/services/ui/loading-screen-service';
import { PredictionService } from 'src/services/data/prediction.service';
import { ActionIndex } from 'src/classes/loadingActions/ActionIndex';
import { ActionTexts } from 'src/classes/loadingActions/ActionTexts';

@Component({
  selector: 'plotly-explainability',
  templateUrl: './plotly-explainability.component.html',
  styleUrls: ['./plotly-explainability.component.scss']
})
export class PlotlyExplainabilityComponent extends TabViewComponent {

  @ViewChild("featureImportance") featureImportance: PlotlyViewComponent;
  @ViewChild("pdp") pdp: PlotlyViewComponent;
  @ViewChild("shapMaps") shapMaps: PlotlyViewComponent;
  @ViewChild("qqMaps") qqMaps: PlotlyViewComponent;
  isRunIdAvailable: boolean = true;
  readyToExport:boolean=false;
  chartSelection: string = 'bar';
  exportTypes = ExportType;
  @Input() loaderStatusFlag: boolean;

  headers:{[key:string]:string}={
    featureImportance:'Feature Importances Based on Shapely Game Theory',
    pdp:'Partial Dependence Plots Based on Feature Importances',
    shapMaps:'Spatial Contribution of Features Based on Feature Importances',
    qqMaps:'Actual vs Predicted Combined QQ plot'
  }
  
  constructor(
    private modelSource: ModelService,
    override selectionService: UserSelectionService,
    private loadingService: LoadingScreenService,
    private predictionSource:PredictionService
  ) {
    super(selectionService);
  }

  applyData(data: any, component: PlotlyViewComponent): void {
    component.applyData(data, "");
    this.readyToExport=true;
  }

  initLoading(): void {
    this.featureImportance.initLoading();
    this.pdp.initLoading();
    this.shapMaps.initLoading();
    this.qqMaps.initLoading();
  }
  
  chartTypeChange(chartType: string) {
    this.chartSelection = chartType;
    this.featureImportance.initLoading();
    this.readyToExport = false;
    const mRequest = this.selectionService.getLastRunModelRequest();
    mRequest.plotType = chartType
    this.modelSource.getFeatureProdQueryPlot(mRequest).subscribe(data => {
      this.applyData(data["FEATURE_IMPORTANCE"], this.featureImportance);
        this.setDataLoaded(true);
        this.loadingService.actionComplete(ActionIndex.RETRIEVING_PLOTS);
    });
  }

  loadData(): void {
    if(!this.dataLoaded){ 
      this.isRunIdAvailable = !!this.selectionService.getMLRunId();    
      if(this.isRunIdAvailable) { // Get features and PDP data only if ML run id available
        this.initLoading();
        const mRequest = this.selectionService.getLastRunModelRequest();
        const pRequest = this.selectionService.getLastRunPredictionRequest(false);
      
        this.loadingService.addAction(ActionIndex.RETRIEVING_PLOTS, ActionTexts.RETRIEVING_FEATURE_IMPORTANCES_PLOT);
        this.modelSource.getModelFeatures("Features", mRequest).subscribe(data => {
          
          this.applyData(data["FEATURE_IMPORTANCE"], this.featureImportance);
          this.loadingService.actionComplete(ActionIndex.RETRIEVING_PLOTS);
        });
      
        this.loadingService.addAction(ActionIndex.RETRIEVING_SCORING, ActionTexts.RETRIEVING_PARTIAL_DEPENDENCE_PLOT);
        this.modelSource.getPDPProdQueryPlot(mRequest.mlRunId, mRequest.product).subscribe(data => {
          this.applyData(data["PDP"], this.pdp);
          this.setDataLoaded(true);
          this.loadingService.actionComplete(ActionIndex.RETRIEVING_SCORING);
        });

        this.loadingService.addAction(ActionIndex.RETRIEVING_SHAP_MAP, ActionTexts.RETRIEVING_SHAP_MAP);
        this.predictionSource.getPredictionShapMaps(pRequest).subscribe(data => {
            this.applyData(data["SHAP_MAP"], this.shapMaps);
            this.loadingService.actionComplete(ActionIndex.RETRIEVING_SHAP_MAP);
        });

        this.loadingService.addAction(ActionIndex.RETRIEVING_QQ_PLOT, ActionTexts.RETRIEVING_QQ_PLOTS);
        this.predictionSource.getMLPredictionQQPlot(pRequest).subscribe(data => {
          this.applyData(data["Actual vs Predicted combined plot"], this.qqMaps);
          this.loadingService.actionComplete(ActionIndex.RETRIEVING_QQ_PLOT);
        });
        this.setDataLoaded(true);
      }
    }    
  }
}
