import { Component, EventEmitter, Input, Output, Pipe, PipeTransform, ViewChild, ViewEncapsulation } from '@angular/core';
import { UserSelectionService } from 'src/services/ui/user-selection.service';
import { ExportService } from 'src/services/export.service';
import { LoadingScreenService } from 'src/services/ui/loading-screen-service';
import { ActionItem } from 'src/classes/loadingActions/ActionItem';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PredictionService } from 'src/services/data/prediction.service';
import { ActionIndex } from 'src/classes/loadingActions/ActionIndex';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { ActionTexts } from 'src/classes/loadingActions/ActionTexts';
import { PDFService } from 'src/services/pdf.service';

@Component({
  selector: 'data-export',
  templateUrl: './data-export.component.html',
  styleUrls: ['./data-export.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class DataExportComponent {
  mlModel: any;
  pdfData: any;
  defaultFormat: string = 'pdf';
  defaultTypeCurve: string;
  isReportAvailable: boolean;
  currentPage: number = 1;
  totalPage: number;
  pdf: PDFDocumentProxy;
  reportType: string;
  reportTitle: string;

  constructor(
    protected selectionService: UserSelectionService,
    private exportService: ExportService,
    private loadingService: LoadingScreenService,
    private snackBar: MatSnackBar,
    private predictionService: PredictionService,
    private pdfService: PDFService
  ) { }

  formatChange(event: any) {
    this.defaultFormat = event.value;
  }

  typeChange(event: any) {
    this.reportType = event.value;
    this.pdfData = [];
    this.isReportAvailable = null;
    if (this.reportType == 'run' || this.reportType == 'area')
      this.defaultFormat = 'pdf';
  }

  preview() {
    this.pdfData = [];
    this.currentPage = 1;
    if (this.reportType == 'area')
      this.loadAreaSummaryReport();

    if (this.reportType == 'run')
      this.loadRunSummaryReport();

  }
  loadAreaSummaryReport() {
    this.reportTitle = "APOLO Area Summary Report";
    this.exportService.getAreaSummaryReport().subscribe(data => {
      this.isReportAvailable = true;
      this.pdfData = data.output('arraybuffer') as Uint8Array;;
      this.exportService.setPdfData(this.arrayBufferToBase64(this.pdfData));
    });
  }
  loadRunSummaryReport() {
    this.reportTitle = "APOLO ML Summary Report";
    const models = this.selectionService.getLastRunModels();
    this.mlModel = models.find(model => model.modelType === "machine_learning");

    if (this.mlModel != undefined && this.defaultFormat == 'pdf') { // Get pdf report when mlmodel exists
      this.loadingService.addAction(ActionIndex.LOADING_REPORT, ActionTexts.LOADING_REPORT);
      this.exportService.getSummaryReport(this.mlModel.businessUnit, this.mlModel?.basin, this.mlModel.promotionStatus, this.selectionService.getMLBenchPath(), this.mlModel.runId)
        .subscribe(
          {
            next: data => {
              this.isReportAvailable = true;
              this.pdfData = data;
              this.exportService.setPdfData(this.arrayBufferToBase64(data));
              this.loadingService.actionComplete(ActionIndex.LOADING_REPORT);
            },
            error: error => {
              this.loadingService.actionFailed(ActionIndex.LOADING_REPORT, () => { this.notifyReportNotAvailable() });
            }
          });
    }
  }

  notifyReportNotAvailable() {
    this.isReportAvailable = false;
    this.snackBar.open("Report not available", "OK", { duration: 1000 });
  }

  downloadReport(): void {
    const models = this.selectionService.getLastRunModels();
    this.mlModel = models.find(model => model.modelType === "machine_learning");

    let filename = this.reportTitle.replace(' ', '_') + '_'
      this.mlModel.businessUnit.toLowerCase() + '_' +
      this.mlModel.basin + '_' +
      this.mlModel.bench + '_' +
      this.mlModel.friendlyName + '_' +
      (+new Date).toString(36); //hashing datetime

    this.pdfData = this.base64ToArrayBuffer(this.exportService.pdfData);
    this.exportService.exportToPdf(this.pdfData, filename);
  }
  pageChange(e: any) {
    if (e == 'Previous' && this.currentPage != 1)
      this.currentPage--;
    if (e == 'Next')
      this.currentPage++;
  }
  afterLoadComplete(pdf: PDFDocumentProxy) {
    this.pdf = pdf;
    }
    //convert arraybuffer to base64
   arrayBufferToBase64(buffer: ArrayBuffer): string {
      const uint8Array = new Uint8Array(buffer);
      const binaryString = [];
         for (let i = 0; i < uint8Array.length; i++) {
        binaryString.push(String.fromCharCode(uint8Array[i]));
      }
      return window.btoa(binaryString.join(''));
    }
    
  base64ToArrayBuffer(base64: string): ArrayBuffer {
      const binaryString = window.atob(base64);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
       return bytes.buffer;
    }
}
