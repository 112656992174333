import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { ControlValue } from "src/classes/dataTransfer/ControlValue";
import { DynamicComponent } from "src/classes/templates/dynamic.component";

@Component
({
  selector: "dyn-checkbox", 
  templateUrl: "./checkbox-control.html", 
  styleUrls: ["./checkbox-control.scss"]
})

export class CheckboxComponent extends DynamicComponent<boolean> implements OnInit{
  checkLabel: string;
  checkTitle: string;

  constructor() {
    super();
    this.defaultValue = false;
  }

  ngOnInit(): void {
    super.baseInit();

    this.defaultValue = (this.data.defaultValue.toString().toUpperCase( ) == "TRUE");
    this.checkLabel = this.data.checkLabel;
    this.checkTitle = this.data.checkTitle;

    this.dataName = this.data.data;

    super.emitValue(this.defaultValue);
  }

  onChange(evt: MatCheckboxChange): void {
    this.valid = true;
    this.dirty = (evt.checked != this.defaultValue);

    super.emitValue(evt.checked);
  }
}