import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CalGuardService } from '@cvx/cal-angular';
import { AppComponent } from '../components/home/app.component';
import { PlotlyPageComponent } from 'src/components/plotly-page/plotly-page.component';
import { LoginComponent } from 'src/components/login/login.component';
import { PlotlyDiagnosticsComponent } from 'src/components/plotly-diagnostics/plotly-diagnostics.component';
import { PlotlyPredictionsComponent } from 'src/components/plotly-predictions/plotly-predictions.component';
import { PlotlyExplainabilityComponent } from 'src/components/plotly-explainability/plotly-explainability.component';
import { ComparisonComponent } from 'src/components/comparison/comparison.component';
import { DataExportComponent } from 'src/components/data-export/data-export.component';
import { FeedbackComponent } from 'src/components/feedback/feedback.component';
import { MainPageComponent } from 'src/components/main-page/main-page.component';

export const routes: Routes = [
    { path: '', component: PlotlyPageComponent, canActivate: [CalGuardService]  },
    { path: 'home', component: AppComponent, canActivate: [CalGuardService]  },
    { path: 'login', component: LoginComponent },
    { path: 'main-page', component: MainPageComponent, canActivate: [CalGuardService]  },
    { path: 'toolbox', component: PlotlyPageComponent, canActivate: [CalGuardService] },
    { path: 'feedback', component: FeedbackComponent, canActivate: [CalGuardService] },
   
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})

export class AppRoutingModule {

}
