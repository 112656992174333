
<div class="HEADER">
    Percent Difference
</div>
<div class="SUB_HEADER">
    (B-A)/A
</div>

<div class="MAIN_VIEW">
    <div class="CONTROL_VIEW">
        <div class="CONTROLS">
            <table>
                <tr>
                    <td>
                        Model A
                    </td>
                    <td>
                        <mat-form-field>
                            <mat-select #compareA placeholder="Choose Method">
                                <mat-option value="type_curve">Type Curve</mat-option>
                                <mat-option value="rules_based">Forge</mat-option>
                                <mat-option value="machine_learning">Machine Learning</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                </tr>
                <tr>
                    <td>
                        Model B
                    </td>
                    <td>
                        <mat-form-field>
                            <mat-select #compareB placeholder="Choose Method">
                                <mat-option value="type_curve">Type Curve</mat-option>
                                <mat-option value="rules_based">Forge</mat-option>
                                <mat-option value="machine_learning">Machine Learning</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                </tr>
            </table>
            
            <button mat-button id="drop-menu" class="EXPORT_BUTTON" (click)="Compare()">
                Run Comparison
            </button>
        </div>
        
        <plotly-view #diffPlot
            graphName="diffPlot"
        >
        </plotly-view>
    </div>
</div>