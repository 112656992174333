import * as Plotly from 'plotly.js-dist-min';
import { PlotLayer } from 'src/classes/plots/layer';

export const MAP_LAYER: PlotLayer = {    
    colorbar: {
      len: 0.5,
      lenmode: 'fraction',
      // Orientation doesn't work on Plotly.ColorBar data type for some reason. That's why PlotLayer is used isntead
      orientation: "h",
      thickness: 5,
      x: 0.5,
      y: -0.1
  },
  colorscale:  [
    [
        0,
        "rgb(0,0,131)"
    ],
    [
        0.2,
        "rgb(0,60,170)"
    ],
    [
        0.4,
        "rgb(5,255,255)"
    ],
    [
        0.6,
        "rgb(255,255,0)"
    ],
    [
        0.8,
        "rgb(250,0,0)"
    ],
    [
        1,
        "rgb(128,0,0)"
    ]
],
// SHould be calculated
  zmin: 0,
  zmax: 1000000,
    type: 'choropleth',
    marker: {
      opacity: 1,
      line :{
        width: 1,
        color: "white",
      }
    },
    showscale: false,
    name: "pred",
    locations: null,
    geojson: null,
    z: null,
    locationmode: 'geojson-id',
    hoverinfo: 'skip',
    hovertemplate: null,
  };
  
  
export const MAP_LAYOUT: Partial<Plotly.Layout> = {
    geo: {
        // Shouldn't be const, does it need to be calculated? Or is it auto?
    //   center: {
    //       lat: 31.7611129593862,
    //       lon: -103.71908588661796
    //   },
      projection: {
          scale: 1,
          type: "mercator"
      },
      lataxis: {
          range: [
              -90,
              90
          ]
      },
      lonaxis: {
          range: [
              -180,
              180
          ]
      },
      resolution: 50,
      scope: "world",
      showcountries: true,
      showsubunits: true,
      fitbounds: "locations",
      visible: true
  },
  xaxis: {
      title: {
          text: ""
      },
      showgrid: false,
      visible: false,
      zeroline: false
  },
  yaxis: {
      title: {
          text: ""
      },
      showgrid: false,
      visible: false,
      zeroline: false,
      showticklabels: false
  },
  margin: {
      l: 0,
      r: 0,
      t: 0,
      b: 0
  },
  autosize: true,
  // Needs to be calculated by the UI
  height: 500,
  width: 400,
  showlegend: false,
  hovermode: 'closest',
  };

  export const MAP_CONFIG: Partial<Plotly.Config> = {
    responsive: true,
    displaylogo: false,
    modeBarButtonsToRemove: [
        "zoomInGeo",
        "zoomOutGeo"
    ]};