<div class="predictionPlotContainer">
    <div style="display: flex;justify-content: center;">
        <button ngClass="btn-medium btn-run" (click)="doPrediction()">Predict</button>
    </div>
    <br><br>
    <div class="plot-container-css">
        <app-card-box [plotFlag]="true">
            <div class="switch-tags">
                <button *ngFor="let tab of tabs" [ngClass]="getClassForTab(tab)" (click)="switchTag(tab)" title="{{tab | titlecase}}"> {{ tab | titlecase }}</button>
            </div>
            <div [hidden]="!(predictionTab === 'prediction summary')">
                <div class="plot-summary-tab">
                    <prediction-summary [selectedHexes]="selectedHexes"></prediction-summary>
                    <carousel-slick [graphNames]="['predictionsByHex', 'predictionsVsActualsSCurve']" [thumbnails]="predictionThumbnails" [currentSlide]="currentSlideSummaryView" (slideChanged)="onSlideChange($event)">
                        <div class="slide" [ngClass]="{'active': currentSlideSummaryView === 0}" [style.display]="currentSlideSummaryView !== 0 ? 'none' : ''">
                            <plotly-view graphType="scatter" [isExpandable]="false" [width]="1000" [height]="400" [graphName]="'predictionsByHex'" [headerText]="'Mean Prediction Across Area'" 
                            [exportType]="exportTypes.PredictionScore"></plotly-view>
                        </div>
                        <div class="slide" [ngClass]="{'active': currentSlideSummaryView === 1}" [style.display]="currentSlideSummaryView !== 1 ? 'none' : ''">
                            <plotly-view graphType="scatter" [isExpandable]="false" [width]="1000" [height]="400" [graphName]="'predictionsVsActualsSCurve'" [headerText]="'Predictions EV Against Actuals Distribution'"
                           ></plotly-view>
                        </div>
                    </carousel-slick>
                </div>
            </div>
            <div [hidden]="!(predictionTab === 'accuracy over time')">
                <div class="plot-tabs">
                    <h3>Comparision with Analogs</h3>
                    <p>based on the wells in the area in the chosen analog binning set, and predicted as completed</p>
                    <carousel-slick [graphNames]="detailedGraphNames" [thumbnails]="detailedThumbnails" [currentSlide]="currentSlideAOTView" (slideChanged)="onSlideChange($event)">
                        <div class="slide" *ngFor="let slide of detailedGraphNames; let i = index" [ngClass]="{'active': currentSlideAOTView === i}" [style.display]="currentSlideAOTView !== i ? 'none' : ''">
                            <plotly-view graphType="scatter" [isExpandable]="false" [width]="1000" [height]="400" [graphName]="getGraphName(i)" [headerText]="getHeaderText(i)" [exportType]="exportTypes.PredictionScore"
                        ></plotly-view>
                        </div>
                    </carousel-slick>
                </div>
            </div>
            <div [hidden]="!(predictionTab === 'accuracy at timeslice')">
                <div class="plot-tabs">
                    <h3>Comparision with Analogs</h3>
                    <p>based on the wells in the area in the chosen analog binning set, predicted as completed at the requested timeslice</p>
                    <carousel-slick [graphNames]="validationGraphNames" [thumbnails]="validationThumbnails" [currentSlide]="currentSlideAATView" (slideChanged)="onSlideChange($event)">
                        <div class="slide" *ngFor="let slide of validationGraphNames; let i = index" [ngClass]="{'active': currentSlideAATView === i}" [style.display]="currentSlideAATView !== i ? 'none' : ''">
                            <plotly-view graphType="scatter" [isExpandable]="false" [width]="1000" [height]="400" [graphName]="getValidationGraphName(i)" [headerText]="getValidationHeaderText(i)" 
                            [exportType]="exportTypes.PredictionScore"></plotly-view>
                        </div>
                    </carousel-slick>
                </div>
            </div>
        </app-card-box>
    </div>
    <br><br>
</div>