import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { ControlValue } from "src/classes/dataTransfer/ControlValue";
import { DynamicComponent } from "src/classes/templates/dynamic.component";

@Component
  (
    {
      selector: "dyn-slider", templateUrl: "./slider-control.html",
      styleUrls: ["./slider-control.scss"],
      encapsulation: ViewEncapsulation.None
    }
  )

export class SliderControlComponent extends DynamicComponent<number> implements OnInit {
  minimum: number;
  maximum: number;
  step: number;

  constructor() {
    super();

    this.minimum = this.maximum = 0;
    this.step = 1;
  }

  ngOnInit(): void {
    super.baseInit();
    this.defaultValue = parseFloat(this.data.defaultValue);
    this.minimum = this.data.minimum;
    this.maximum = this.data.maximum;
    this.step = this.data.step;

    this.emitValue(this.defaultValue);
  }

  onChange(value: string): void {
    const numValue = parseFloat(value);
    this.dirty = (numValue != this.defaultValue);

    this.emitValue(numValue);
  }
}