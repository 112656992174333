import { ControlValue } from "./ControlValue";

export class ModelControlValues {
    runId: string;
    values: Array<ControlValue<any>>;

    constructor(runId: string) {
        this.runId = runId;
        this.values = new Array<ControlValue<any>>();
    }

    copy(): ModelControlValues {
        const copy = new ModelControlValues(this.runId);
        this.values.forEach(x => copy.values.push(x.copy()));
        return this;
    }
}