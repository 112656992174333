import { HttpHeaders } from '@angular/common/http';
import { ModelDataResponse } from './dataTransfer/responses/ModelDataResponse';

export class CommonFunctions {

  public static headerInfo = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin':'*',
      'Authorization':'authKey'
    })
  };

  public static getCorrectModels(modelData: ModelDataResponse[], promotionStatus: string) : ModelDataResponse[] {
    const modelDataReturn = new Array<ModelDataResponse>();

    const machineLearning = modelData.find(d => d.type == "machine_learning" && d.promotion_status === promotionStatus);
    const rulesBased = modelData.find(d => d.type == "rules_based" && d.promotion_status === promotionStatus);
    const typeCurve = modelData.find(d => d.type == "type_curve" && d.promotion_status === promotionStatus);

    if(machineLearning != undefined && machineLearning != null) {
      modelDataReturn.push(machineLearning);
    }

    if(rulesBased != undefined && rulesBased != null) {
      modelDataReturn.push(rulesBased);
    }

    if(typeCurve != undefined && typeCurve != null) {
      modelDataReturn.push(typeCurve);
    }

    modelDataReturn.sort((a,b) => {
      if(a.type > b.type) {
        return -1;
      } else {
        return 1;
      }
    });

    return modelDataReturn;
  }
}