import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'carousel-slick',
  templateUrl: './carousel-slick.component.html',
  styleUrls: ['./carousel-slick.component.scss'],
})
export class CarouselSlickComponent {
  slideDirection :any = '';

  @Input() graphNames: string[] = [];
  @Input() thumbnails: string[] = [];
  @Input() currentSlide: number;

  @Output() slideChanged = new EventEmitter<number>();


  nextSlide() {
    this.slideDirection = '';
    this.currentSlide = (this.currentSlide + 1 + this.graphNames.length) % this.graphNames.length;
    this.slideChanged.emit(this.currentSlide);
    setTimeout(() => {
      this.slideDirection = 'slideIn';
    }, 1);
  }

  prevSlide() {
    this.slideDirection = '';
    this.currentSlide = (this.currentSlide - 1 + this.graphNames.length) % this.graphNames.length;
    this.slideChanged.emit(this.currentSlide);
    setTimeout(() => {
      this.slideDirection = 'slideOut';
    }, 1);
  }

  jumpToSlide(index: number): void {
    this.slideDirection = '';
    if(this.currentSlide < index){
      setTimeout(() => {
        this.slideDirection = 'slideIn';
      }, 1);
    }else{
      setTimeout(() => {
        this.slideDirection = 'slideOut';
      }, 1);
    }
    this.currentSlide = index;
    this.slideChanged.emit(this.currentSlide);
  }
}
