import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActionItem } from 'src/classes/loadingActions/ActionItem';
import { ActionStatus } from 'src/classes/loadingActions/ActionStatus';
import { LoadingScreenComponent } from 'src/components/controls/loading-screen/loading-screen.component';

@Injectable({
  providedIn: 'root'
})
export class LoadingScreenService {
    actions: Array<ActionItem> = [];
    dialogHandle: MatDialogRef<LoadingScreenComponent>;

    constructor(public dialogRef: MatDialog) {
    }

    addAction(index: number, text: string): void {
        console.log('Adding action: ' + text);
        this.actions.push( 
            { index: index, text: text, status: ActionStatus.LOADING }
        );
        if(this.actions.length === 1) {
            this.show();
        }
    }

    injectAction(itemId: number, text: string, rowNumber: number): void {
        const newAction = new ActionItem(itemId, text);
        this.actions.splice(rowNumber, 0, newAction);
        this.dialogHandle.componentInstance.updateActionList(this.actions);
    }

    actionComplete(index: number): void {
        const actions = this.actions.filter(action => action.index === index);
        if(actions.length != 0)
        {
            actions[0].status = ActionStatus.COMPLETE;
        }

        if(this.dialogHandle.componentInstance != null) {
            this.dialogHandle.componentInstance.updateActionStatus(index, ActionStatus.COMPLETE);
        }

        if(!this.hasPendingActions()) {
            this.actions = [];
            this.dialogHandle.close();
        }
    }

    hasPendingActions(): boolean {
        const remainingActions = this.actions.filter(action => action.status === ActionStatus.LOADING);
        return remainingActions.length > 0;
    }


    actionFailed(index: number, followUp: Function): void {
        this.actions.filter(action => action.index === index)[0].status = ActionStatus.FAILED;
        if(this.dialogHandle.componentInstance != null) {
            this.dialogHandle.componentInstance.updateActionStatus(index, ActionStatus.FAILED);
        }
        if(followUp !== null)
        {
            followUp();
        }
        setTimeout(()=> {
            this.actions = [];
            this.dialogHandle.close()
        },3000);
    }

    show(): void {
        this.dialogHandle = this.dialogRef.open(LoadingScreenComponent, {
            // width: '480px',
            // height: '320px',
            enterAnimationDuration: '100ms',
            exitAnimationDuration: '200ms',
            data: this.actions,
            disableClose: true,
            panelClass: 'custom-loading-dialog'
        });
    }
}
