<div class="Generic__DialogPadding">
    <div class="Generic__DialogHeader">Resolve Models</div>
    <div class="Generic__DialogView">
        
        <div *ngFor="let model of selectedModels; index as i" style="margin-bottom: 10px;">
            <div style="display: flex">
                <div class="ModelDisplay__Position--Position{{ positions[i] }}">
                    {{ positions[i] }}
                </div>
                <div class="ModelDisplay__RunIdText">
                    {{ model.runId }}
                </div>
                <div class="ModelDisplay__ModelType">
                    {{ model.modelType }}
                </div>
            </div>
            <div class="ModelResolve__ErrorText" *ngIf="suggestedModels[i] !== undefined">
                <div class="ModelResolve__ErrorIcon">!</div>
                This model is incompatible with the information specified
            </div>
            <div *ngIf="suggestedModels[i] !== undefined" class="ModelResolve__SuggestedModel">
                <div *ngIf="suggestedModels[i] !== null; else noSuggestedModel">
                    <div class="ModelResolve__ReplaceModel">
                        {{ suggestedModels[i].runId }} ({{ suggestedModels[i].modelType}}) will be used once this interface redirects.
                    </div>
                </div>
                <ng-template #noSuggestedModel>
                    No approximate matches were found.<br/>
                    <div (click)="showModelSelect()" class="ModelResolve__ReplaceModel">
                        Please use model selection tool.
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
    <div class="Generic__DialogFooter">
        <div class="ModelResolve__Status">
            This interface will redirect in {{ countdown }} seconds.
        </div>
        <div>
            <div class="btnSmall--cancel ModelResolve__CancelButton" (click)="cancelRedirect()">
                Cancel
            </div>
        </div>
    </div>
</div>