import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValue } from '../dataTransfer/ControlValue';

@Component({
    template: ''
  })
export abstract class DynamicComponent<T> {
  @Input("data") data: any;

  dirty: boolean;
  valid: boolean;
  dataName: string;
  defaultValue: T;
    
  @Input("visibility") visibility: boolean;
  @Output() updateValue : EventEmitter<ControlValue<T>> = new EventEmitter<ControlValue<T>>();


  constructor() {
    this.visibility = false;
    this.dirty = false;
    this.valid = true;
    this.dataName = "";
  }

  baseInit() {
    this.dataName = this.data.data;
    this.visibility = true;
  }

  emitValue(value: T): void {
    
    const val = new ControlValue<T>();
    val.name = this.dataName;
    val.value = value;

    this.updateValue.emit(val);
  }
}