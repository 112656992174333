export class ModelDataRequest {
    business_unit: string;
    basin: string;
    bench: string;
    product: string;
    modelType: string;
    promotion_status: string;
    startDate: Date;
    endDate: Date;
    latestModel: boolean;
    timeSlice?: number;
    mlRunId?: string;
    plotType?: string;

    extendedSearch: boolean;
    includeFinal: boolean;
    selectDates: boolean;


    constructor() {
        this.extendedSearch = false;
        this.includeFinal = false;
        this.selectDates = false;
        this.mlRunId = null;
    }

    validate(): void{
        if(this.business_unit === "all") {
            this.business_unit = "";
        }

        if(this.basin === "all") {
            this.basin = "";
        }

        if(this.bench === "all") {
            this.bench = "";
        }

        if(this.product === "all") {
            this.product = "";
        }

        if(this.modelType === "all") {
            this.modelType = "";
        }    

        if(this.includeFinal) {
            this.promotion_status = "final";
        }
    }
}