<div class="LoadingScreen__Header">
    <img src="./assets/img/APOLO Logo Circular.png" class="imgApolo"  alt="">
    <div class="sub-header-container">
        <div class="subHeader">Loading</div>
    </div>
</div>
<div class="LoadingScreen__Body">
    <mat-progress-bar class="LoadingScreen__ProgressBar" mode="determinate" value={{percentDone}}></mat-progress-bar>
    <div *ngFor="let action of actions" class="LoadingScreen__ActionList--DefaultView">
        <div class="LoadingScreen__Text">{{ action.text }}</div>
        <div *ngIf="action.status === ActionComplete">
            <img src="./assets/img/icons/success_apolo.svg" class="LoadingScreen__LoadingIcon" />
        </div>
        <div *ngIf="action.status === ActionLoading">
            <img src="./assets/img/icons/active_step_gif_v4.gif" class="LoadingScreen__LoadingIcon" />
        </div>
        <div *ngIf="action.status === ActionFailed">
            <img src="./assets/img/icons/failed_apolo.svg" class="LoadingScreen__LoadingIcon" />
        </div>
    </div>
</div>