import {  Compiler, Component, ViewChild, Input, Output, OnInit, EventEmitter, ViewContainerRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { InterfaceSectionDto } from 'src/app/classes/InterfaceSectionDto';
import { ControlGroup } from 'src/classes/templates/control.group';
import { ControlComponent } from 'src/components/dynamicControls/control-component/control-component';
import { ControlModule } from 'src/components/dynamicControls/control-component/control-module';
import { SectionComponent } from 'src/components/dynamicControls/section-component/section-component';
import { SectionModule } from 'src/components/dynamicControls/section-component/section-module';
import { UserSelectionService } from 'src/services/ui/user-selection.service';
import { ControlValue } from 'src/classes/dataTransfer/ControlValue';

@Component({
  selector: 'app-model-controls',
  templateUrl: './model-controls.component.html',
  styleUrls: ['./model-controls.component.scss']
})

export class ModelControlsComponent extends ControlGroup implements OnInit {
  @Input("data") data: InterfaceSectionDto | undefined;

  @ViewChild('templateView', {read: ViewContainerRef})
  templateView!: ViewContainerRef;
  title: string;
  runId: string;

  constructor(protected _compiler: Compiler, protected http: HttpClient, protected selectionService: UserSelectionService){
    super();
  }

  ngOnInit(): void {
    this.data = null;
  }

  applyData(data?: InterfaceSectionDto): void {
    this.data = null;
    this.templateView.clear();

    if(data === null || data === undefined) {
      return;
    }
    this.data = data;
    this.title = this.data?.title;
    this.runId = this.data?.run_id;

    if(this.data.items != null && this.data.items.length > 0)
    {
        this._compiler.compileModuleAndAllComponentsAsync(ControlModule)
        .then((factories) => {
    
            const folderData = this.data;
            if(folderData != undefined)
            {
                const itemData = this.data.items;

                for(let i = 0; i < itemData.length; ++i)
                {
                    const currentControl = itemData[i];
                    const f = factories.componentFactories[0];

                    const cmpRef = this.templateView.createComponent(f);

                    cmpRef.instance.name = "DynamicItem_" + i;
                    cmpRef.setInput("data", currentControl);

                    var componentX =  cmpRef.instance as ControlComponent;
                    componentX.updateValue.subscribe(x => this.receiveUpdate(x, this.runId));
                    //  componentX.submitForm.subscribe(x => this.receiveSubmit(x, i));

                    //  this.sections.push(cmpRef);
                }
            }
        });
    }
  }
  receiveUpdate(data: ControlValue<any>, runId: string) : void {
      this.selectionService.addModelControlValue(runId, data);
      this.selectionChange.emit();
  }
}
