<div [style.display]="this.data === null ? 'none' : 'block'">
    <div class="HEADER_CONTAINER">
        <div class="SUB_GROUP_NAME" (click)="collapse()" [title]="groupTitle">{{ title }}</div>
        <div *ngIf="collapsed" class="EXPAND_GROUP" (click)="collapse()"><mat-icon>chevron_right</mat-icon></div>
        <div *ngIf="!collapsed" class="EXPAND_GROUP" (click)="collapse()"><mat-icon>expand_more</mat-icon></div>
    </div>
    <mat-divider *ngIf="!collapsed" class="similar-margin"></mat-divider>
    <div [style.display]="collapsed ? 'none' : 'block'" class="panelContent">
        <template #templateView></template>
    </div>
</div>