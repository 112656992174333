export class ActionTexts
{
    static LOADING_DEFAULT_MODELS = "Loading Default Models";
    static REPLACING_WITH_DEFAULT_MODELS = "Replacing with Default Models";
    static VERIFYING_MODEL_COMPATIBILITY = "Verifying Model Compatibility";
    static RETRIEVING_MODEL_CONTROLS = "Retrieving Model Controls";
    static RETRIEVING_PLOTS = "Retrieving Plots";
    static RETRIEVING_FEATURE_IMPORTANCES_PLOT = "Retrieving Feature Importances Plots";
    static RETRIEVING_SCORING = "Retrieving Scoring";
    static RETRIEVING_PARTIAL_DEPENDENCE_PLOT = "Retrieving Scoring";
    static RETRIEVING_SHAP_MAP = "Retrieving SHAP Map";
    static RETRIEVING_QQ_PLOTS = "Retrieving QQ Plot";
    static LOADING_REPORT ='Loading Report';
    static GENERATING_AREA ='Generating Area';
    static LOADING_DATA ='Loading Data';
}