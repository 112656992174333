import { Component, Input, OnInit  } from '@angular/core';

@Component({
  selector: 'app-info-tooltip',
  templateUrl: './info-tooltip.component.html',
  styleUrls: ['./info-tooltip.component.scss']
})
export class InfoTooltipComponent implements OnInit {
  @Input() tooltipContent: string = '';
  @Input() location: string = 'above';  // TODO implement
  visible: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  showTooltip() {
    this.visible = true;
  }

  hideTooltip() {
    this.visible = false;
  }


}
