import { Component, Inject, OnInit} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ModelService } from 'src/services/data/model.service';
import { SelectedModel } from 'src/classes/SelectedModel';
import { UserSelectionService } from 'src/services/ui/user-selection.service';
import { ModelDataResponse } from 'src/classes/dataTransfer/responses/ModelDataResponse';

@Component({
  selector: 'app-model-selection',
  templateUrl: './model-selection.component.html',
  styleUrls: ['./model-selection.component.scss'],
})
export class ModelSelectionComponent implements OnInit{
  selectedModelType: string;
  selectedModelStatus: string;
  fromDate: Date;
  toDate: Date;
  latestModelOnly: boolean = false;

  slotNames: string[] = ['A','B','C'];
  selectedModels: SelectedModel[];
  modelsList: ModelDataResponse[];
  displayedColumns: string[] = ['friendly_name', 'run_id', 'status', 'date', 'bench', 'type', 'plot_location'];

  constructor(
    public dialogRef: MatDialogRef<ModelSelectionComponent>, 
    private modelSource: ModelService, 
    private selectionService: UserSelectionService) {
  }

  ngOnInit(): void {
    this.preSelectDefaultModels();
  }

  searchModels(): void {
    const modelRequest = this.selectionService.getCurrentModelRequest();

    modelRequest.modelType = this.selectedModelType;
    modelRequest.extendedSearch = true;
    modelRequest.includeFinal = false;  // Placeholder. API needs to be updated to accept the dropdown values
    modelRequest.startDate = this.fromDate;
    modelRequest.endDate = this.toDate;
    modelRequest.latestModel = this.latestModelOnly;
    modelRequest.validate();  // Does this work as expected? Replaces 'all' values with empty string.
    this.modelSource.getModelData(modelRequest).subscribe(resp => 
      {
        this.modelsList = resp.body;
    });
  }

  preSelectDefaultModels() {
    // Slice used to clone the list, otherwise list in selectionService updates prior to clicking 'confirm'
    this.selectedModels = this.selectionService.getCurrentModels().slice();
  }

  closeSlot(slot: number): void {
    this.selectedModels.splice(slot, 1);
  }

  selectForSlot(data: ModelDataResponse, slot: number): void {
    this.selectedModels[slot] = new SelectedModel(data);
  }

  cancel(): void {
    this.dialogRef.close(null);
  }

  confirm(): void {
    const outputModels = this.selectedModels.map((model, index) => ({
      ...model,
      position: this.slotNames[index],
    }));
    this.dialogRef.close(outputModels);
  }
}

