<div [hidden]="!visibility" class="d-flex align-items-center" style="padding: 5px;">
    <div class="ControlComponent__Label">{{label}}</div>
    <dyn-text-box *ngIf="type=='text'" [data]="data" (updateValue)="receiveUpdate($event)"></dyn-text-box>
    <dyn-text-area *ngIf="type=='textarea'" [data]="data" (updateValue)="receiveUpdate($event)"></dyn-text-area>
    <dyn-num-box *ngIf="type=='number'" [data]="data" (updateValue)="receiveUpdate($event)"></dyn-num-box>
    <dyn-dropdown *ngIf="type=='dropdown'" [data]="data" (updateValue)="receiveUpdate($event)"></dyn-dropdown>
    <dyn-checkbox *ngIf="type=='checkbox'" [data]="data" (updateValue)="receiveUpdate($event)"></dyn-checkbox>
    <dyn-radio-box *ngIf="type=='radio'" [data]="data" (updateValue)="receiveUpdate($event)"></dyn-radio-box>
    <dyn-image-radio *ngIf="type=='image-radio'" [data]="data" (updateValue)="receiveUpdate($event)"></dyn-image-radio>
    <dyn-slider *ngIf="type=='slider'" [data]="data" (updateValue)="receiveUpdate($event)"></dyn-slider>
    <dyn-slider-range *ngIf="type=='sliderRange'" [data]="data" (updateValue)="receiveUpdate($event)"></dyn-slider-range>
    <dyn-submit-button *ngIf="type=='submit'" [data]="data" (submitForm)="clickSubmit($event)"></dyn-submit-button>

    <dyn-paragraph *ngIf="type=='paragraph'" [data]="data"></dyn-paragraph>
    <dyn-static-image *ngIf="type=='image'" [data]="data"></dyn-static-image>

    <div *ngIf="labelDirection=='w'" style="display: inline;  margin-left: 5px;">{{label}}</div>
    <div *ngIf="labelDirection=='s'" style="display: block; margin-top: 5px;">{{label}}</div>
</div>
