import { Coordinates } from "../dataTransfer/areas/Coordinates";
import { MapGeometry } from "../dataTransfer/areas/MapGeometry";  
import * as cloneDeep from 'lodash/cloneDeep';
import { PlotLayer } from 'src/classes/plots/layer';
import { MAP_LAYER } from 'src/constants/plot.constants';

export class GraphFunctions {
    public static getGeoCenter(mapGeometry: MapGeometry): Coordinates{
        let minLatitude = 999;
        let minLongitude = 999;
        let maxLatitude = -999;
        let maxLongitude = -999;

        const coords = mapGeometry.coordinates[0];

        coords.forEach(coord => 
            {
                if(coord[0] < minLatitude)
                {
                    minLatitude = coord[0];
                }
                if(coord[0] > maxLatitude)
                {
                    maxLatitude = coord[0];
                }
                if(coord[1] < minLongitude)
                {
                    minLongitude = coord[1];
                }
                if(coord[1] > maxLongitude)
                {
                    maxLongitude = coord[1];
                }
            }
        );

        const centerLatitude = (maxLatitude + minLatitude) / 2;
        const centerLongitude = (maxLongitude + minLongitude) / 2;

        const retValue = new Coordinates();
        retValue.latitude = centerLatitude;
        retValue.longitude = centerLongitude;

        return retValue;
    }
}

interface results {
  hex: string,
  prediction: number,
  high_confidence: boolean
}  

const step = 50000;
  
  export function createCultureLayers(cultureData: any): PlotLayer[] {
    let layers = [];
    cultureData.forEach(culture => {
      let locs =  culture['features'].map(x => x.id);
      let zs =  culture['features'].map(x => 1);

      let layer = cloneDeep(MAP_LAYER);
      layer.geojson = culture;
      layer.colorscale =  [[0, "rgba(0,0,0,0)"],[1, "rgba(0,0,0,0)"]];
      layer.marker.line.color = "rgb(0,0,0)";
      layer.locations = locs;
      layer.z = zs; 
      layer.name = "cultures";

      layers.push(layer);
    });

    return layers;
  }

  export function createLayer(data: any, highConfidence: boolean, runId: string, min: number, max: number, colorscale?: string): PlotLayer {
    const values: results[] = data[runId].filter(x => x.high_confidence === highConfidence);
    let locations = values.map(x => x.hex);
    let predictions = values.map(x => x.prediction);

    let mapConfig: PlotLayer = cloneDeep(MAP_LAYER);

    mapConfig.locations = locations;
    mapConfig.z = predictions;
    mapConfig.geojson = data['geojson'];
    mapConfig.zmin = min;
    mapConfig.zmax = max;

    if(highConfidence) {
      mapConfig.showscale = true; // Only show scale on one layer
      mapConfig.marker.line.width = 0;
      mapConfig.hovertemplate = "%{z:,.3s}";
    } else {
      mapConfig.hovertemplate = "%{z:,.3s}<br><i>low confidence</i>";
      mapConfig.marker.line.width = 1.5;
    }

    if (colorscale) {
      mapConfig.colorscale = colorscale;
    }

    return mapConfig;
  }  
  
  export function filterZScores (array: any[]): number[] {
    let predictions = array.map(x => x.prediction);
    const n = predictions.length;
    const mean = predictions.reduce((a, b) => a + b) / n;
    let std = Math.sqrt(array.map(x => Math.pow(x.prediction - mean, 2)).reduce((a, b) => a + b) / n);
    let z_scores = predictions.map(x => {
      return {prediction: x, z_score: (x - mean)/std};
    });
    let filtered_z_scores: number[] = z_scores.flatMap(x => (x.z_score > -3 && x.z_score < 3) ? [x.prediction] : []);
    return filtered_z_scores;
  }

  export function getMin(currMin: number, data: any): number {
    let min = data.reduce((curr, prev) => {
      if(!curr)
        return prev;
      return prev < curr ? prev : curr;
    });

    if(!currMin || min < currMin)
      currMin = Math.floor(min/step)*step;
    return currMin;
  }
    
  export function getMax(currMax: number, data: any): number {
    let max = data.reduce((curr, prev) => {
      if(!curr)
        return prev;
      return prev > curr ? prev : curr;
    });
    
    if(!currMax || max > currMax)
      currMax = Math.ceil(max/step)*step;
    return currMax;
  }