import { Component, EventEmitter, Input, Output, OnInit, ViewChild } from '@angular/core';
import { PredictionService } from 'src/services/data/prediction.service';
import { UserSelectionService } from 'src/services/ui/user-selection.service';
import { TabViewComponent } from '../base/tab-view-base.component';
import { LoadingScreenService } from 'src/services/ui/loading-screen-service';
import { ExportService } from 'src/services/export.service';
import { MapPlotService } from 'src/services/map-plot.service';
import { ActionIndex } from 'src/classes/loadingActions/ActionIndex';
import { MatSelectionList } from '@angular/material/list';
import { AreaService } from 'src/services/data/area.service';
import { PredictionDataRequest } from 'src/classes/dataTransfer/requests/PredictionDataRequest';
import { MapArea } from 'src/classes/dataTransfer/areas/MapArea';
import { PlotlyViewSelectComponent } from '../views/plotly-view-select/plotly-view-select.component';
import { SelectedModel } from 'src/classes/SelectedModel';
import { MapAreaEx } from 'src/classes/dataTransfer/areas/MapAreaEx';
import { UserProfileService } from 'src/services/auth/user-profile.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { FormBuilder, FormControl, FormGroup, RequiredValidator, Validators } from '@angular/forms';
import { ActionItem } from 'src/classes/loadingActions/ActionItem';
import { ActionTexts } from 'src/classes/loadingActions/ActionTexts';
import { MatSliderChange } from '@angular/material/slider';

@Component({
  selector: 'plotly-predictions',
  templateUrl: './plotly-predictions.component.html',
  styleUrls: ['./plotly-predictions.component.scss']
})
export class PlotlyPredictionsComponent extends TabViewComponent {
  @Input() customTagForm: FormGroup;

  @ViewChild("areaTagList") areaTagList: MatSelectionList;
  @ViewChild("plotlyShowSelected") plotlyShowSelected: PlotlyViewSelectComponent;

  selectedModels: Array<SelectedModel>;
  readyToExport: boolean = false;
  switchTagFlag: boolean = false;
  modelSelectedMap: SelectedModel;
  tagSelection: string = "";
  tagSelectionOverlay: MapAreaEx = null;
  itemListSelection: string = "All";
  areaList: Array<MapArea>;
  filteredAreaList: Array<MapArea>;
  searchFilteredAreaList: Array<MapArea>;
  public searchText: string = '';
  switchCustomToggle: boolean = false;
  selectionOverlay: MapAreaEx;
  customAreaStep: Number = 0;
  selectedArea: string = '';
  plotTitle: string = '';
  constructor(private readonly formBuilder: FormBuilder,
    private predictionService: PredictionService,
    private loadingService: LoadingScreenService,
    private areaService: AreaService,
    override selectionService: UserSelectionService,
    private userService: UserProfileService,
    private snackBar: MatSnackBar,
    private mapPlotService: MapPlotService) {
    super(selectionService);
  }

  ngOnInit(): void {

    this.customTagForm = this.formBuilder.group({
      tagTitle: "",
      latitudeInput: [null, Validators.required],
      longitudeInput: [null, Validators.required],
      radiusInput: 4,
    });
    localStorage.removeItem('selectedHexList');
    this.plotTitle = this.capitalizeTitle("Cumulative " + this.selectionService.selections.location.product + " at " +
      this.selectionService.selections.configuration.timeslice + " months for 2 mile laterals");
  }

  applyActualVsPredicted(data: any): void {
    this.readyToExport = true;
  }
  initLoading(): void {
  }

  tagChange(tag: any): void {
    this.tagSelection = tag.id;
    this.selectedArea = tag.area;
    if (tag.id === "") {
      this.plotlyShowSelected.applySelectionOverlay(null);
      localStorage.removeItem('selectedHexList');
    } else {
      this.areaService.getArea(tag.id).subscribe(data => {
        this.tagSelectionOverlay = data;
        this.plotlyShowSelected.applySelectionOverlay(data);
        this.savePlot();
      });
      localStorage.setItem('selectedHexList', JSON.stringify(this.tagSelectionOverlay.hexValues));
    }
  }

  refreshTagList(predictionDataRequest: PredictionDataRequest): void {
    this.areaService.filterAreas(predictionDataRequest).subscribe(data => {
      this.areaList = data;
      this.filteredAreaList = data;
      this.searchFilteredAreaList = data;

    });
  }

  loadData(): void {
    if (!this.dataLoaded) {
      this.plotlyShowSelected.initLoading();
      const pRequest = this.selectionService.getLastRunPredictionRequest(true);
      this.refreshTagList(pRequest);
      this.modelSelectedMap = this.selectionService.getLastRunModels()[0];

      this.loadMap();
      this.dataLoaded = true;
    }
  }

  loadMap() {
    this.mapPlotService.getMap(this.modelSelectedMap.runId).subscribe(
      data => {
        this.plotlyShowSelected.createMap(data.layers, this.modelSelectedMap.modelType);
        this.plotlyShowSelected.applySelectionOverlay(this.tagSelectionOverlay);
      }
    );
  }

  modelTypeChange(chartType: SelectedModel) {
    this.modelSelectedMap = chartType
    this.plotlyShowSelected.initLoading();
    this.loadMap();
  }

  switchTag(flag: any) {
    this.switchTagFlag = flag;
    this.customTagForm.get('tagTitle')?.setValue("");
  }

  getSelectedModels(): Array<SelectedModel> {
    return this.selectionService.getLastRunModels();
  }

  itemListSelectionEvent(item: any) {
    this.itemListSelection = item;
    this.filteredAreaList = [];
    this.areaList.map((value) => {
      if (value.areaType == item && item != "All") {
        this.filteredAreaList.push(value);
      } else if (item == "All") {
        this.filteredAreaList.push(value);
      }
    })
    this.searchFilteredAreaList = this.filteredAreaList
  }

  onSearchChange(event: Event) {
    const target = event.target as HTMLInputElement;
    this.searchText = target.value;
    this.filteredAreaList = this.searchFilteredAreaList.filter(option =>
      option.area.toLowerCase().includes(this.searchText.toLowerCase())
    );
  }

  switchToggleEvent(flag: boolean) {
    this.switchCustomToggle = flag
  }
  saveTag() {
    const tagTitle = this.customTagForm.get('tagTitle')?.value;

    if (this.plotlyShowSelected.selectionOverlay == null) {
      this.showMessage('Please select the area on Map');
    }
    else if (tagTitle == '') {
      this.showMessage('Please enter tag title');
    }
    else if (this.areaList.filter(x => (x.area.toLowerCase()).includes(tagTitle.toLowerCase())
      && x.userId == this.userService.getUserProfile().objectId).length != 0) {
      this.showMessage('Area name already exists. Please enter unique area name');
    }
    else {
      const pRequest = this.selectionService.getLastRunPredictionRequest(true);
      const mapAreaEx = this.plotlyShowSelected.selectionOverlay;
      mapAreaEx.businessUnit = pRequest.businessUnit;
      mapAreaEx.basin = pRequest.basin;
      mapAreaEx.bench = pRequest.bench;
      mapAreaEx.area = tagTitle;
      mapAreaEx.userId = this.userService.getUserProfile().objectId;
      mapAreaEx.areaType = "Custom";
      mapAreaEx.hexValues = this.plotlyShowSelected.selectionOverlay.hexValues
      this.areaService.saveArea(this.plotlyShowSelected.selectionOverlay).subscribe(x => {
        this.refreshTagList(pRequest);
        this.showMessage('Custom Tag saved successfully');
        this.clearTag();
      }
      );
    }
  }

  clearTag() {
    this.plotlyShowSelected.selectionOverlay = null;
    this.customTagForm.get('tagTitle')?.setValue('');
    this.customTagForm.get("latitudeInput")?.setValue('');
    this.customTagForm.get("longitudeInput")?.setValue('');
  }
  showMessage(message: string) {
    const config = new MatSnackBarConfig();
    config.duration = 3000;
    config.panelClass = ['snackbar-info'];
    this.snackBar.open(message, 'Close', config);
  }

  onSliderChange(): void {
    this.renderLatitudeLongitude();
  }

  renderLatitudeLongitude(): void {
    this.customAreaStep = 0;

    const latitude = this.customTagForm.get("latitudeInput").value;
    const longitude = this.customTagForm.get("longitudeInput").value;
    const radius = this.customTagForm.get("radiusInput").value;

    if (!this.customTagForm.valid)
      this.showMessage('Please enter lat/long values');
    else {
      const radiusInMeters = radius * 1609.34;
      this.loadingService.addAction(ActionIndex.GENERATING_AREA, ActionTexts.GENERATING_AREA);

      this.predictionService.getLatLongData(longitude, latitude, radiusInMeters)
        .subscribe(data => {
          this.tagSelectionOverlay = data;
          const area = new MapAreaEx();
          area.applyData(data);
          this.plotlyShowSelected.applySelectionOverlay(area);
          this.loadingService.actionComplete(ActionIndex.GENERATING_AREA);
          localStorage.setItem('selectedHexList', JSON.stringify(this.tagSelectionOverlay['hex_list']));
        });
    }
  }
  lassoSelected(area: MapAreaEx): void {
    this.tagSelectionOverlay = area;
    this.customAreaStep = 1;
    localStorage.setItem('selectedHexList', JSON.stringify(this.tagSelectionOverlay.hexValues));
    this.savePlot();
  }

  rectangleSelected(area: MapAreaEx): void {
    this.tagSelectionOverlay = area;
    this.customAreaStep = 2;
    localStorage.setItem('selectedHexList', JSON.stringify(this.tagSelectionOverlay.hexValues));
    this.savePlot();
  }

  pointSelected(pointData: any): void {
    const lon = pointData.points[0].ct[0];
    const lat = pointData.points[0].ct[1];

    this.customTagForm.get('latitudeInput')?.setValue(lat);
    this.customTagForm.get('longitudeInput')?.setValue(lon);
    this.switchTag(true);
    this.switchToggleEvent(true);
    this.renderLatitudeLongitude();
  }

  getBoundingPoints(): Array<string> {
    const boundingArray = new Array<string>();
    if (this.tagSelectionOverlay != null) {
      this.tagSelectionOverlay.geometry.coordinates[0].forEach((value) => {
        boundingArray.push(value[0] + ", " + value[1]);
      });
    }
    return boundingArray;
  }
  savePlot() {
    this.mapPlotService.resetPlotStorage(); 
    let subTitle = 'Local Area Predictions';
    if (this.selectedArea != '') {
      subTitle += ' - ' + this.selectedArea
    }
    if (this.plotlyShowSelected.selectionOverlay != null) // Save map for dataexport when the location selected
      this.mapPlotService.savePlot(this.plotlyShowSelected.graphName, this.plotTitle, subTitle,'Local Area Predictions');
  }
}
