import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { ComparisonSettings } from 'src/classes/ComparisonChoices';
import { SelectedModel } from 'src/classes/SelectedModel';
import { UserSelectionService } from 'src/services/ui/user-selection.service';

@Component({
  selector: 'app-comparison-group',
  templateUrl: './comparison-group.component.html',
  styleUrls: ['./comparison-group.component.scss']
})
export class ComparisonGroupComponent implements OnInit {
  @Output() notifyCompare: EventEmitter<any> = new EventEmitter();

  constructor(private readonly formBuilder: FormBuilder,
    protected selectionService: UserSelectionService,
  ) { }

  comparison = this.formBuilder.group({
    type: ['difference',Validators.required],
    baselineModel: [],
    alternativeModel: [],
    varianceModels: [],    
    metric: ['percentDiff'], // Set to required once this is handled by API
  });
  selectedModels: SelectedModel[];

  metrics = [
    {id: "percentDiff", name: "Percent Difference", type: "difference"},
    {id: "absoluteDiff", name: "Absolute Difference", type: "difference"},
    {id: "rawDiff", name: "Raw Difference", type: "difference"},
    {id: "variance", name: "Variance", type: "variance"},
    {id: "stdDev", name: "Standard Deviation", type: "variance"},
  ];

  ngOnInit(): void {
  }

  compare(): void {
    var selections = new ComparisonSettings(this.comparison.value);
    this.notifyCompare.emit(selections);
  }

  onTypeChange(event) {
    this.selectedModels = this.selectionService.getLastRunModels();  // Should be done on "run" event
    this.comparison.get('metric').reset();  

    if(event.value === 'difference') {
      this.setValidators('baselineModel',[Validators.required]);
      this.setValidators('alternativeModel',[Validators.required]);
      this.setValidators('varianceModels',[]);
    } else if (event.value === 'variance') { 
      this.setValidators('baselineModel',[]);
      this.setValidators('alternativeModel',[]);
      this.setValidators('varianceModels',[Validators.required, Validators.minLength(2)]);
    }
  }

  setValidators(formField: string, validators: ValidatorFn[]) {
    this.comparison.get(formField).setValidators(validators);
    this.comparison.get(formField).updateValueAndValidity();    
  }

  loadDefaultValues(){
    this.comparison.controls['baselineModel'].setValue(this.selectedModels[0]);
    this.comparison.controls['alternativeModel'].setValue(this.selectedModels[1]);
  }
  
  filterBaseModels() {
    this.selectedModels = this.selectionService.getLastRunModels();
      return this.selectedModels.filter(b => b !== this.comparison.get('alternativeModel').value);
  }

  filterAltModels() {
    this.selectedModels = this.selectionService.getLastRunModels();
    return this.selectedModels.filter(b => b !== this.comparison.get('baselineModel').value)
  }

  getMetrics() {
    return this.metrics.filter(b => b.type === this.comparison.get('type').value);
  }

}
