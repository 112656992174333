<form [formGroup]="comparison" (ngSubmit)="compare()">  
    <div class="row">
        <div class="column">  
            <div class="input-group">
                <label class="input-label">Comparison Type: </label>
                <mat-form-field class="custom-mat-dropdown" subscriptSizing="dynamic">
                    <mat-select placeholder="Comparison Type" formControlName="type" (selectionChange)="onTypeChange($event)">            
                        <mat-option value="difference">% Difference</mat-option>
                        <mat-option value="variance">Standard Deviation</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="input-group" *ngIf="false">
                <label class="input-label">Metric: </label>
                <mat-form-field class="custom-mat-dropdown" subscriptSizing="dynamic">
                    <mat-select placeholder="Metric"  formControlName="metric"> 
                        <mat-option *ngFor="let option of getMetrics()" [value]="option.id">{{ option.name }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div> 
        </div>
        <div class="column">
            <div *ngIf="comparison.get('type').value === 'difference'">
                <div class="input-group">
                    <label class="input-label">Baseline Model: </label>
                    <mat-form-field class="custom-mat-dropdown" subscriptSizing="dynamic">
                        <mat-select placeholder="Baseline Model" formControlName="baselineModel">
                            <mat-option *ngFor="let option of filterBaseModels()" [value]="option">{{ option.friendlyName }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="input-group">
                    <label class="input-label">Alternative Model: </label>
                    <mat-form-field class="custom-mat-dropdown" subscriptSizing="dynamic">
                        <mat-select placeholder="Alternative Model"  formControlName="alternativeModel">
                            <mat-option *ngFor="let option of filterAltModels()" [value]="option">{{ option.friendlyName }}</mat-option>
                        </mat-select>
                    </mat-form-field> 
                </div>
            </div>
            <div *ngIf="comparison.get('type').value  === 'variance'">
                <mat-selection-list formControlName="varianceModels">
                    <mat-list-option *ngFor="let option of selectedModels" checkboxPosition="before" [value]="option">
                        {{option.friendlyName}}
                    </mat-list-option>
                </mat-selection-list>
            </div>            
            <div *ngIf="comparison.get('type').value  !== 'variance' && comparison.get('type').value  !== 'difference'">                 
                <label class="input-label">Select a Comparison Type</label>
            </div>
        </div>
    </div>
    <div class="row">
        <button class="btn-medium btn-run"  [disabled]="!comparison.valid" type="submit">Compare</button>
    </div>
</form>
