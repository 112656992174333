import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { CalAngularService, CvxClaimsPrincipal, ICvxClaimsPrincipal } from '@cvx/cal-angular';
import { environment } from 'src/environments/environment';
import { UserTracker } from 'src/models/user-tracker';
import { UserProfileService } from 'src/services/auth/user-profile.service';
import { UserTrackerService } from 'src/services/ui/user-tracker.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'app';
  isIframe = false;
  isLoggedIn: boolean;
  inAccessGroup: boolean;
  errorText: string;
  showLogin: boolean=true;
  isDisabled: boolean=false;
  showWorkFlowBuilder:boolean=false;
  
  constructor(public router: Router, 
    private authService: CalAngularService,
    private userService: UserProfileService,
    private _formBuilder: FormBuilder,
    private userTracker:UserTrackerService) {}
  currentUserProfile: ICvxClaimsPrincipal;
  ngOnInit(): void { 
    this.authService.isUserSignedIn().subscribe(signIn => {
      if (signIn) {
        this.isLoggedIn = true;
        this.userTracker.SaveUserLoginInfo('User logged in').subscribe();
      }
    });
   }}
