import { Injectable } from "@angular/core";
import { BaseService } from "./service-base.service";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { RequestBuilder } from "src/classes/functions/RequestBuilder";
import { Observable, catchError, map } from "rxjs";
import { environment } from "src/environments/environment";
import { ModelDataRequest } from "src/classes/dataTransfer/requests/ModelDataRequest";
import { ModelService } from "./data/model.service";
import { UserSelectionService } from "./ui/user-selection.service";
import { PredictionDataRequest } from "src/classes/dataTransfer/requests/PredictionDataRequest";
import { PredictionService } from "./data/prediction.service";
import jsPDF from 'jspdf';
import * as XLSX from 'xlsx';
import * as Plotly from 'plotly.js-dist-min';
import { PDFService } from "./pdf.service";
import { formatDate } from "@angular/common";

export enum ExportType {
    Maps = 'Maps',
    FeatureImportance = 'FeatureImportance',
    PDP = 'PDP',
    SHAP = 'SHAP',
    QQMaps = 'QQMaps',
    PredictionScore = 'PreidctionScore',
    Predictions = 'Predictions',
    PredictionsFutureInventory = 'PredictionsFutureInventory',
    SectionLevel = 'SectionLevel',

}

@Injectable({
    providedIn: 'root'
})

export class ExportService extends BaseService {

    isReportAvailable: boolean = true;
    pdfData: any;
    pRequest = new PredictionDataRequest();
    mRequest = new ModelDataRequest();
    constructor(http: HttpClient,
        snackBar: MatSnackBar,
        private modelSource: ModelService,
        private selectionService: UserSelectionService,
        private predictionSource: PredictionService,
        private pdfService: PDFService
    ) { super(http, snackBar) }

    setPdfData(value): void {
        this.pdfData = value;
    }

    public exportData(type: ExportType) {
        this.mRequest = this.selectionService.getLastRunModelRequest();
        switch (type) {
            case ExportType.Maps:
            // Handled in diagnostics because it requires Map information from the page
            case ExportType.FeatureImportance:
                this.modelSource.getModelFeatures('FeaturesData', this.mRequest).subscribe(mData => {
                    this.exportToCSV(mData, this.getExportFileName('feature_importance'));
                });
                break;
            case ExportType.PDP:
                this.modelSource.getPDPProdQueryData(this.mRequest.mlRunId, this.mRequest.product).subscribe(mData => {
                    this.exportToCSV(mData['SHAP_VALUES'], this.getExportFileName('pdp'));
                });
                break;
            case ExportType.SHAP:
                this.pRequest = this.selectionService.getLastRunPredictionRequest(true);
                this.predictionSource.getPredictionShapMapData(this.pRequest).subscribe(pData => {
                    this.exportToCSV(pData, this.getExportFileName('shap_map_data'));
                });
                break;
            case ExportType.QQMaps:
                this.pRequest = this.selectionService.getLastRunPredictionRequest(true);
                this.predictionSource.getMLPredictionQQData(this.pRequest).subscribe(pData => {
                    this.exportToCSV(pData, this.getExportFileName('qq_map_data'));
                });
                break;
            case ExportType.PredictionScore:
                this.pRequest = this.selectionService.getLastRunPredictionRequest(true);
                this.predictionSource.getScoreSummaryData(this.pRequest).subscribe(pData => {
                    this.exportToCSV(pData, this.getExportFileName('prediction_score'));
                });
                break;
            case ExportType.Predictions:   // Preditions and SectionLevel are same?
                this.exportPredictions(false);
                break;
            case ExportType.SectionLevel:   // Preditions and SectionLevel are same?
                this.exportPredictions(false);
                break;
            case ExportType.PredictionsFutureInventory:
                this.exportPredictions(true);
                break;
            default:
                return;
        }
        const config = new MatSnackBarConfig();
        config.duration = 3000;     // Duration the snackbar should be displayed (in milliseconds)
        config.panelClass = ['snackbar-info'];     // Optional: custom CSS class for styling
        this.snackBar.open("Downloading Started Successfully", 'Close', config);
    }

    exportPredictions(future: boolean) {
        this.pRequest = this.selectionService.getLastRunPredictionRequest(true);

        if (future)
            this.pRequest.addInventory = true;
        this.predictionSource.getPredictionMapDataExport(this.pRequest, null).subscribe(pData => {
            this.exportToCSV(pData, this.getExportFileName('predictions'));
        });
    }

    public getSummaryReport(bu: string, basin: string, promotionStatus: string, benchPath: string, runId: string): Observable<any> {

        const qString = new RequestBuilder(environment.apoloWebApiURL, "DataExport/Report");
        qString.addItem('bu', bu);
        qString.addItem('basin', basin);
        qString.addItem('promotionStatus', promotionStatus);
        qString.addItem('benchPath', benchPath);
        qString.addItem('runId', runId);
        const headers = new HttpHeaders()
            .set('Access-Control-Allow-Origin', '*',)
            .set('Authorization', 'authKey')
        const params = { bu, basin, promotionStatus, benchPath, runId };
        return this.http.get(qString.getUrl(), { headers: headers, responseType: 'arraybuffer' as 'json' });
    }

    getAreaSummaryReport(): Observable<any> {
        const images = JSON.parse(localStorage.getItem('savedPlots') || '[]').filter(x => x.data?.value != '');
        const models = this.selectionService.getLastRunModels();
        const mlModel = models.find(model => model.modelType === "machine_learning");
        const rulesBased = models.find(model => model.modelType === "rules_based");
        const typeCurve = models.find(model => model.modelType === "type_curve");
        let data = {
            BUSINESS_UNIT: mlModel.businessUnit,
            REGION: mlModel.basin,
            BENCH: mlModel.bench,
            DATE: formatDate(new Date(), 'MM-dd-yyyy', 'en'),
            ML_MODEL: mlModel.friendlyName,
            RULES_BASED: rulesBased.friendlyName,
            TYPE_CURVE: typeCurve.friendlyName,
        }
        let coverPageInfo = { title: 'APOLO Area Summary Report', data: data };
        return this.pdfService.createPDFDocument(coverPageInfo, images);
    }
  

    public exportToPdf(buffer: any, fileName: string) {
        return new Promise<any>((resolve, reject) => {
            const data = new Blob([buffer], { type: 'application/pdf' })
            const fileUrl = window.URL.createObjectURL(data);
            const link = document.createElement('a');
            link.setAttribute('target', '_blank');
            link.setAttribute('href', fileUrl);
            link.setAttribute('download', fileName + '.pdf');
            document.body.appendChild(link);
            link.click();
            link.remove();
        });
    }

    public exportToCSV(data: any, filename: string) {

        let csvData = this.convertToCSV(data, Object.keys(data[1]));
        let blob = new Blob(['\ufeff' + csvData], {
            type: 'text/csv;charset=utf-8;'
        });
        let dwldLink = document.createElement("a");
        let url = URL.createObjectURL(blob);
        let isSafariBrowser = navigator.userAgent.indexOf(
            'Safari') != -1 &&
            navigator.userAgent.indexOf('Chrome') == -1;

        // If Safari open in new window to
        // save file with random filename.
        if (isSafariBrowser) {
            dwldLink.setAttribute("target", "_blank");
        }
        dwldLink.setAttribute("href", url);
        dwldLink.setAttribute("download", filename + ".csv");
        dwldLink.style.visibility = "hidden";
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
    }
    private convertToCSV(objArray, headerList) {
        let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        let row = 's.no,';
        for (let index in headerList) {
            row += headerList[index] + ',';
        }
        row = row.slice(0, -1);
        str += row + '\r\n';
        for (let i = 0; i < array.length; i++) {
            let line = (i + 1) + '';
            for (let index in headerList) {
                let head = headerList[index];

                line += ',' + array[i][head];
            }
            str += line + '\r\n';
        }
        return str;
    }

    public exportToExcel(data:any,filename:string): void {
        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data);
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Users_Logs');
        XLSX.writeFile(wb, filename +'.xlsx');
      }
      
    getExportFileName(param) {
        this.mRequest = this.selectionService.getLastRunModelRequest();
        let filename = this.mRequest.business_unit.toLowerCase() + '-' +
            this.mRequest.basin + '_' +
            this.mRequest.bench + '_' +
            param + '_' +
            (+new Date).toString(36); //hashing datetime
        return filename;
    }
}