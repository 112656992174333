import { Compiler, Component, ComponentRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef } from "@angular/core";
import { InterfacePageDto } from "src/app/classes/InterfacePageDto";
import { InterfaceSectionDto } from "src/app/classes/InterfaceSectionDto";
import { ControlComponent } from "../control-component/control-component";
import { ControlModule } from "../control-component/control-module";
import { SectionModule } from "./section-module";

@Component
({
    selector: "dyn-page", 
    templateUrl: "./section-component.html",
    styleUrls: ["./section-component.scss"],
})
export class SectionComponent implements OnInit{
    @Input("data") data: InterfaceSectionDto | undefined;
    @Input("visibility") visibility: boolean;

    @Output() updateValue : EventEmitter<any> = new EventEmitter<any>(); 
    @Output() submitForm : EventEmitter<any> = new EventEmitter<any>(); 


    @ViewChild('templateView', {read: ViewContainerRef})
    templateView!: ViewContainerRef;

    sections: ComponentRef<any>[];
    controls: ComponentRef<any>[];
    title: string;

    constructor(protected _compiler: Compiler) {
        this.visibility = false;
        this.title = "";
        this.sections = new Array<ComponentRef<any>>();
        this.controls = new Array<ComponentRef<any>>();
    }
  

    
    

    ngOnInit(): void {

      this.build();
    }

    build(): void {

      

      //  Now that you are in a section, you must render controls AND render sections
      if(this.data == undefined)
      {
        return;
      }

      var sectionData = this.data as InterfaceSectionDto;

      this.title = sectionData.title;
      
      if(sectionData.items.length > 0)
      {
        this._compiler.compileModuleAndAllComponentsAsync(ControlModule)
          .then((factories) => {
      
              const folderData = this.data;
              if(folderData != undefined)
              {
                  const itemData = sectionData.items;

                  for(let i = 0; i < itemData.length; ++i)
                  {
                      const currentControl = itemData[i];
                      const f = factories.componentFactories[0];

                      const cmpRef = this.templateView.createComponent(f);

                      cmpRef.instance.name = "DynamicItem_" + i;
                      cmpRef.setInput("data", currentControl);

                      var componentX =  cmpRef.instance as ControlComponent;
                      componentX.updateValue.subscribe(x => this.receiveUpdate(x, i));
                      componentX.submitForm.subscribe(x => this.receiveSubmit(x, i));

                      this.sections.push(cmpRef);
                  }
              }
            //  Get the Folder
        
        });
      }

      if(sectionData.sections.length > 0)
      {
        this._compiler.compileModuleAndAllComponentsAsync(SectionModule)
          .then((factories) => {
      
              const folderData = this.data;
              if(folderData != undefined)
              {
                  const sections = sectionData.sections;

                  for(let i = 0; i < sections.length; ++i)
                  {
                      const currentControl = sections[i];
                      const f = factories.componentFactories[0]
                      const cmpRef = this.templateView.createComponent(f);
                      cmpRef.instance.name = "DynamicSection_" + i;
                      cmpRef.setInput("data", currentControl);
                      cmpRef.setInput("visibility",  true);

                      var componentX =  cmpRef.instance as ControlComponent;
                      componentX.updateValue.subscribe(x => this.receiveUpdate(x, i));
                      componentX.submitForm.subscribe(x => this.receiveSubmit(x, i));

                      this.sections.push(cmpRef);
                  }
              }
        });
      }
    }
  
    receiveSubmit(data: any, index: number){
      this.submitForm.emit(data);
    }

    receiveUpdate(data: any, index: number){
      this.updateValue.emit(data);
    }
}