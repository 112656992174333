import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient, HttpErrorResponse,HttpResponse } from '@angular/common/http';
import { Observable, catchError, map} from 'rxjs';
import {  CommonFunctions } from 'src/classes/CommonFunctions';
import { ModelDataResponse } from 'src/classes/dataTransfer/responses/ModelDataResponse';
import { environment } from 'src/environments/environment';
import { RequestBuilder } from 'src/classes/functions/RequestBuilder';
import { PredictionDataRequest, PredictionDataRequestBOD, PredictionDataRequestComparison } from 'src/classes/dataTransfer/requests/PredictionDataRequest';
import { BaseService } from '../service-base.service';
import { DefaultUrlSerializer, Router, UrlSerializer } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MapAreaEx } from 'src/classes/dataTransfer/areas/MapAreaEx';
import { ActionIndex } from 'src/classes/loadingActions/ActionIndex';
import { LoadingScreenService } from '../ui/loading-screen-service';
import { createRequestBody } from 'src/services/data/postbody.service';
import { Location, Model } from 'src/classes/dataTransfer/dto/DTO_Components'; 

@Injectable({
  providedIn: 'root'
})
export class PredictionService extends BaseService {
    router = new Router();
    serializer: UrlSerializer;
    public constructor(http: HttpClient, snackBar: MatSnackBar,private loadingService:LoadingScreenService) {
        super(http, snackBar);
        this.serializer = new DefaultUrlSerializer();
    }
    
    public getInventoryData(request: PredictionDataRequest, hexList: string[] = null): Observable<HttpResponse<any>> {
        const requestBody = createRequestBody(request, hexList);
        return this.doRequest(requestBody, "Predictions/InventoryData");
    }

    public getPredictionMap(request: PredictionDataRequest): Observable<HttpResponse<any>> {
        const requestBody = createRequestBody(request);
        return this.doRequest(requestBody, "Predictions/PredictionMaps");
    }

    public getPredictionMapDataExport(request: PredictionDataRequest, hexList: string[] = null): Observable<HttpResponse<any>> {
        const requestBody = createRequestBody(request, hexList);
        return this.doRequest(requestBody, "Predictions/PredictionMapsData");
    }

    public getPredictionOvertimeVolumePlot(request: PredictionDataRequest, hexValues: string[]): Observable<HttpResponse<any>>
    {
        const requestBody = createRequestBody(request, hexValues);
        return this.doRequest(requestBody, "Predictions/PredictionOvertimeVolumePlot");
    }

    public getPredictionsVsActualsSCurve(request: PredictionDataRequest, hexValues: string[]): Observable<HttpResponse<any>>
    {
        const requestBody = createRequestBody(request, hexValues);
        return this.doRequest(requestBody, "Predictions/PredictionsVsActualsSCurve");
    }

    public getScoreOvertimeVolumePlot(request: PredictionDataRequest, hexValues: string[]): Observable<HttpResponse<any>>
    {
        const requestBody = createRequestBody(request, hexValues);
        return this.doRequest(requestBody, "Predictions/ScoreOvertimeVolumePlot");
    }

    public getScoreOvetimePercentErrorPlot(request: PredictionDataRequest, hexValues: string[]): Observable<HttpResponse<any>>
    {
        const requestBody = createRequestBody(request, hexValues);
        return this.doRequest(requestBody, "Predictions/ScoreOvetimePercentErrorPlot");
    }
    
    public getScoreProbitPlot(request: PredictionDataRequest, hexValues: string[]): Observable<HttpResponse<any>>
    {
        const requestBody = createRequestBody(request, hexValues);
        return this.doRequest(requestBody, "Predictions/ScoreProbitPlot");
    }

    public getScoreSCurvePlot(request: PredictionDataRequest, hexValues: string[]): Observable<HttpResponse<any>>
    {
        const requestBody = createRequestBody(request, hexValues);
        return this.doRequest(requestBody, "Predictions/ScoreSCurvePlot");
    }

    public getScoreSummaryPlot(request: PredictionDataRequest): Observable<HttpResponse<any>> {
        const requestBody = createRequestBody(request);
        if(request.summarize != null) {
            requestBody.scoring_params.how = request.summarize;
        }
        return this.doRequest(requestBody, "Predictions/ScoreSummaryPlot");
    }

    public getScoreQQPlot(request: PredictionDataRequest, hexValues: string[]): Observable<HttpResponse<any>>
    {
        const requestBody = createRequestBody(request, hexValues);
        return this.doRequest(requestBody, "Predictions/ScoreQQPlot");
    }
   
    public getPredictionMapComparison(request: PredictionDataRequestComparison): Observable<HttpResponse<any>> {
        const params = this.router.createUrlTree(["Predictions", "GetPredictionComparison"], { queryParams: request });
        const fullUrl = environment.apoloWebApiURL.slice(0,-1) + params;    // Slice should be replaced by removing the trailing slash from base URL

        return this.http.get<any>(fullUrl, {headers:CommonFunctions.headerInfo.headers,observe:'response'})
        .pipe(
            map(response=>{
                if(response.status==201)
                console.log('Hit prediction service while getting prediction map comparison:' + response.status);
                return response.body;
                }),
                catchError(this.handleError));;
    }
    public getPredictionMapVariance(request: PredictionDataRequestComparison): Observable<HttpResponse<any>> {
        const params = this.router.createUrlTree(["Predictions", "GetPredictionVariance"], { queryParams: request });
        const fullUrl = environment.apoloWebApiURL.slice(0,-1) + params;    // Slice should be replaced by removing the trailing slash from base URL

        return this.http.get<any>(fullUrl, {headers:CommonFunctions.headerInfo.headers,observe:'response'})
        .pipe(
            map(response=>{
                if(response.status==201)
                console.log('Hit prediction service while getting prediction map variance:' + response.status);
                return response.body;
                }),
            catchError(this.handleError));;
    }
    public getScoreSummaryData(request: PredictionDataRequest): Observable<any> {
        const requestBody = createRequestBody(request);
        if(request.summarize != null) {
            requestBody.scoring_params.how = request.summarize;
        }
        return this.doRequest(requestBody, "Predictions/ScoreSummaryData");
    }

    public getPredictionShapMaps(request: PredictionDataRequest): Observable<HttpResponse<any>> {

        const qString = new RequestBuilder(environment.apoloWebApiURL, "Predictions/GetPredictionShapMaps");
        const runId=request.getRunIdByType("machine_learning");
        if(runId !=null){
             qString.addItem("runId", runId);
        }
        if(request.cultures != null) {
            qString.addItem("cultures", request.cultures);
        }
        if(request.basin != null) {
            qString.addItem("basin", request.basin);
        }
        if(request.bench != null) {
            qString.addItem("bench", request.bench);
        }
        if(request.product != null) {
            qString.addItem("product", request.product);
        }
        if(request.basin != null) {
            qString.addItem("timeslice", request.timeslice.toString());
        }

        return this.http.get<any>(qString.getUrl(), {headers:CommonFunctions.headerInfo.headers,observe:'response'})
            .pipe(
                map(response=>{
                    if(response.status==201)
                    console.log('Hit prediction service for shap maps:' + response.status);
                    return response.body;
                    }),
                catchError((error: HttpErrorResponse) => {this.loadingService.actionFailed(ActionIndex.RETRIEVING_SHAP_MAP,()=>{});return this.handleError(error)}));
    }

    public getMLPredictionQQPlot(request: PredictionDataRequest): Observable<HttpResponse<any[]>> {

        const qString = new RequestBuilder(environment.apoloWebApiURL, "Predictions/GetQQPlot");
        const runId=request.getRunIdByType("machine_learning");
        if(runId !=null){
             qString.addItem("runId", runId);
        }
        if(request.cultures != null) {
            qString.addItem("cultures", request.cultures);
        }
        if(request.basin != null) {
            qString.addItem("basin", request.basin);
        }
        if(request.bench != null) {
            qString.addItem("bench", request.bench);
        }
        if(request.product != null) {
            qString.addItem("product", request.product);
        }
        if(request.basin != null) {
            qString.addItem("timeslice", request.timeslice.toString());
        }
        if(request.analogChoice != null) {
            qString.addItem("analogSetRunId", request.analogChoice.toString());
        }

        return this.http.get<any>(qString.getUrl(), {headers:CommonFunctions.headerInfo.headers,observe:'response'})
            .pipe(
                map(response=>{
                    if(response.status==201)
                    console.log('Hit prediction service for QQ plot data:' + response.status);
                    return response.body;
                    }),
                catchError((error: HttpErrorResponse) => {this.loadingService.actionFailed(ActionIndex.RETRIEVING_QQ_PLOT,()=>{});return this.handleError(error)}));

    }

     public getPredictionShapMapData(request: PredictionDataRequest): Observable<HttpResponse<any[]>> {

        const qString = new RequestBuilder(environment.apoloWebApiURL, "Predictions/getPredictionShapMapData");
        const runId=request.getRunIdByType("machine_learning");
        if(runId !=null){
             qString.addItem("runId", runId);
        }
        if(request.cultures != null) {
            qString.addItem("cultures", request.cultures);
        }
        if(request.basin != null) {
            qString.addItem("basin", request.basin);
        }
        if(request.bench != null) {
            qString.addItem("bench", request.bench);
        }
        if(request.product != null) {
            qString.addItem("product", request.product);
        }
        if(request.basin != null) {
            qString.addItem("timeslice", request.timeslice.toString());
        }
        if(request.modelControlsJson != null) {
            qString.addItem("modelControlsJson", request.modelControlsJson);
        }

        return this.http.get<any>(qString.getUrl(), {headers:CommonFunctions.headerInfo.headers,observe:'response'})
            .pipe(
                map(response=>{
                    if(response.status==201)
                    console.log('Hit prediction service for shap map data:' + response.status);
                    return response.body;
                    }),
                catchError((error: HttpErrorResponse) => { return this.handleError(error,)}));

    }

    public getMLPredictionQQData(request: PredictionDataRequest): Observable<HttpResponse<any[]>> {

        const qString = new RequestBuilder(environment.apoloWebApiURL, "Predictions/GetQQPlotData");
        const runId=request.getRunIdByType("machine_learning");
        if(runId !=null){
             qString.addItem("runId", runId);
        }
        if(request.cultures != null) {
            qString.addItem("cultures", request.cultures);
        }
        if(request.basin != null) {
            qString.addItem("basin", request.basin);
        }
        if(request.bench != null) {
            qString.addItem("bench", request.bench);
        }
        if(request.product != null) {
            qString.addItem("product", request.product);
        }
        if(request.basin != null) {
            qString.addItem("timeslice", request.timeslice.toString());
        }
        if(request.analogChoice != null) {
            qString.addItem("analogSetRunId", request.analogChoice.toString());
        }
        if(request.modelControlsJson != null) {
            qString.addItem("modelControlsJson", request.modelControlsJson);
        }

        return this.http.get<any>(qString.getUrl(), {headers:CommonFunctions.headerInfo.headers,observe:'response'})
            .pipe(
                map(response=>{
                    if(response.status==201)
                    console.log('Hit prediction service for QQ map data:' + response.status);
                    return response.body;
                    }),
                catchError((error: HttpErrorResponse) => { return this.handleError(error,)}));

    }

    public getGunBarrelPlot(request: PredictionDataRequest): Observable<HttpResponse<any>> {
        // const requestBody = createRequestBody(request);
        const location = new Location(request.basin, request.bench);
        // with which models
        let models = new Array<Model>();
        request.runIds.forEach(element => { models.push(new Model(element.id)) });
        // add model controls
        const controls = request.getModelControls();
        controls.forEach(control_bundle => {
            const control_id = control_bundle.runId;
            const control = control_bundle.controlValues;
            if (control != undefined) {
                models.some(model => {
                    if (model.run_id == control_id) {
                        model.controls = control;
                        return true;  // break loop
                    }
                    return false;
                });
            }
        });
        
        let model = models.find(model => model.controls !== undefined);
        if (!model) {
            model = new Model("placeholder")
        }
        const requestData = new Object({ location, model });
        const qString = new RequestBuilder(environment.apoloWebApiURL, "Predictions/GunBarrelPlot");
        return this.http.post<any>(qString.getPostUrl(), requestData, { headers:CommonFunctions.headerInfo.headers,observe:'response' })
            .pipe(
                map(response=>{
                    if(response.status==201)
                    console.log('Hit prediction service while calling: ' + "Predictions/GunBarrelPlot" + response.status);
                    return response.body;
                    }),
                catchError(this.handleError));
    }

    public getLatLongData(longitude: number, latitude: number, radius: number): Observable<MapAreaEx> {
        const qString = new RequestBuilder(environment.apoloWebApiURL, "Predictions/GetLatLongData");
        qString.addItem("latitude", latitude.toString());
        qString.addItem("longitude", longitude.toString());
        qString.addItem("radius", radius.toString());
        
        return this.http.get<MapAreaEx>(qString.getUrl(), {headers:CommonFunctions.headerInfo.headers,observe:'response'})
        .pipe(
            map(response=>{
                if(response.status==201)
                console.log('Hit prediction service for actuals versus predicted data:' + response.status);
                return response.body;
                }),
            catchError((error: HttpErrorResponse) => { return this.handleError(error,)}));
    }

    
    getProbitPlot(request: PredictionDataRequestBOD) {
        const qString = new RequestBuilder(environment.apoloWebApiURL, "Predictions/GetBODActualProbitPlot");

        // qString.addItem("runId", request.bodRunId);
        qString.addItem("analogSetRunId", request.analogChoice);
        qString.addItem("bodProperty", request.property);
        qString.addItem("bodRunId", request.bodRunId);
        qString.addItem("bodWindowPercent", request.window.toString());
        qString.addItem("isPredicted", request.isPredicted.toString());
        qString.addItem("basin", request.basin);
        qString.addItem("bench", request.bench);
        qString.addItem("product", request.product);
        qString.addItem("timeslice", request.timeslice.toString());

        for(let i = 0; i < request.hexValues.length && i < 310; i++) {
            qString.addItem("hexList", request.hexValues[i]);
        }

        return this.http.get<any>(qString.getUrl(), {headers:CommonFunctions.headerInfo.headers,observe:'response'})
        .pipe(
            map(response=>{
                if(response.status==201)
                console.log('Hit prediction service while getting probit plot:' + response.status);
                return response.body;
                }),
            catchError(this.handleError));
    }
}