import { ActionStatus } from "./ActionStatus";

export class ActionItem {
    index: number;
    text: string;
    status: ActionStatus;

    public constructor(index: number, text: string)
    {
        this.index = index;
        this.text = text;
        this.status = ActionStatus.LOADING;
    }
}