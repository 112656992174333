import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";

@Component
({
  selector: "dyn-paragraph", templateUrl: "./paragraph-control.html"
})

export class ParagraphComponent implements OnInit{
    @Input("data") data: any;
    @Input("visibility") visibility: boolean;

    defaultValue: string;
    fontSize: string;

    constructor() {
      this.visibility = false;
      this.defaultValue = "";
      this.fontSize = "";
    }
  
    ngOnInit(): void {
      this.defaultValue = this.data.defaultValue;
      this.visibility = true;
      switch(this.data.size)
      {
        case "t":
          this.fontSize = "9px";
          break;
        case "s":
          this.fontSize = "12px";
          break;
        case "l":
          this.fontSize = "15px";
          break;
        case "x":
          this.fontSize = "18px";
          break;
        default:
        case "m":
          this.fontSize = "14px";
          break;
      }
    }
  }