import { Injectable } from '@angular/core';
import { LocationInfo } from 'src/classes/LocationInfo';
import { SelectedModel } from 'src/classes/SelectedModel';
import { ModelDataResponse } from 'src/classes/dataTransfer/responses/ModelDataResponse';
import { ModelDataRequest } from 'src/classes/dataTransfer/requests/ModelDataRequest';
import { CommonFunctions } from 'src/classes/CommonFunctions';
import { PredictionDataRequest, PredictionDataRequestBase } from 'src/classes/dataTransfer/requests/PredictionDataRequest';
import { UserSelections } from 'src/classes/UserSelections';
import { ControlValue } from 'src/classes/dataTransfer/ControlValue';
import { ModelControlValues } from 'src/classes/dataTransfer/ModelControlValues';
import { ModelControlTranslation } from 'src/classes/functions/ModelControlTranslation';
import { InterfaceSectionDto } from 'src/app/classes/InterfaceSectionDto';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserSelectionService {
  selections: UserSelections;
  currentChanges: string | null;
  modelControlValues: Array<ModelControlValues> | null;
  lastModelControlValues: Array<ModelControlValues> | null;
  controlChangeDetails: Array<string> | null;
  
  lastRunSelections: UserSelections;

  
  private selections$ = new BehaviorSubject<UserSelections>(null);
  userSelections$ = this.selections$.asObservable();

  constructor() {
      this.modelControlValues = null;
      this.lastModelControlValues = null;
      this.lastRunSelections = new UserSelections({selectedModels: []});
      this.selections = new UserSelections({selectedModels: []});
  }  

  saveUserSelections() {
    this.lastRunSelections = this.selections;
    this.setLastModelControlValues(this.modelControlValues);
  }
  
  setLastModelControlValues(values: Array<ModelControlValues> | null) {
    if(values == null) {
      this.lastModelControlValues = null;
    } else if (values === undefined) {
      this.lastModelControlValues = undefined;
    } else {
      this.lastModelControlValues = eval(JSON.stringify(values));
    }
  }
  addModelControlValue(runId: string, controlValue: ControlValue<any>): void {
    if(this.modelControlValues == null) {
      this.modelControlValues = new Array<ModelControlValues>();
    }

    let correctModel = this.modelControlValues.find(x => x.runId === runId);
    if(correctModel == undefined) {
      correctModel = new ModelControlValues(runId);
      this.modelControlValues.push(correctModel);
    }

    let correctValue = correctModel.values.find(x => x.name === controlValue.name);
    if(correctValue == undefined){
      correctModel.values.push(controlValue);
    } else {
      correctValue.value = controlValue.value;
    }
  }
  
  addModelControlValues(values: InterfaceSectionDto[]): void {
    values.forEach(controlList => {
        if(controlList != undefined)
        {
            controlList.items.forEach(item => {
                const control = new ControlValue<any>();
                control.name = item.data;
                control.value = item.defaultValue;
    
                this.addModelControlValue(controlList.run_id, control);
            });
        }
    });
  }

  purgeModelControlValues(): void {
    this.modelControlValues = null;
  }

  getCurrentModelRequest(): ModelDataRequest {
    return this.getModelRequest(this.selections);
  }

  getLastRunModelRequest(): ModelDataRequest {
    return this.getModelRequest(this.lastRunSelections);
  }

  private getModelRequest(selections: UserSelections) {
    const mRequest = new ModelDataRequest();
    mRequest.business_unit = selections.location.businessUnit;
    mRequest.basin = selections.location.basin;
    mRequest.bench = selections.location.bench;
    mRequest.product = selections.location.product;
    mRequest.timeSlice = +selections.configuration.timeslice; // This is a string in the form, cast to number
    
    mRequest.mlRunId = selections.selectedModels.find(model => model.modelType === "machine_learning")?.runId;

    return mRequest;
  }

  getLocationInfo(): LocationInfo {
      return this.selections.location;
  }
    
  clearModels(): void {
    this.selections.selectedModels = new Array<SelectedModel>();
    this.selections$.next(this.selections);
  }

  addSelectedModel(model: SelectedModel): boolean {
    switch(this.selections.selectedModels.length) {
      case 0:
        model.position = "A";
        break;
      case 1:
        model.position = "B";
        break;
      case 2:
        model.position = "C";
        break;
      case 3:
        return false;
    }

    this.selections.selectedModels.push(model);
    return true;
  }

  hasSelectedModels(): boolean {
    return this.selections.selectedModels.length > 0;
  }

  getMLRunId(): string {
    const machineLearningModel = this.selections.selectedModels.find(model => model.modelType );

    if(machineLearningModel == null) {
      return null;
    }

    return machineLearningModel.runId;
  }

  getLastRunModels(): Array<SelectedModel>{
    return this.lastRunSelections.selectedModels;
  }

  getCurrentModels(): Array<SelectedModel>{
    return this.selections.selectedModels;
  }

  getAnalogSetRunId(): string {
    if(this.selections.selectedModels.length === 0) {
      return null;
    }

    return this.selections.selectedModels[this.selections.selectedModels.length - 1].runId;
  }

  setCurrentModels(modelData: ModelDataResponse[]): void {
    this.clearModels();

    const selectedModelData = CommonFunctions.getCorrectModels(modelData, "final");

    selectedModelData.forEach(model => {
      var selectedModel = new SelectedModel(model);

      this.addSelectedModel(selectedModel);
    });
    this.selections$.next(this.selections);
  }

  getCurrentPredictionRequest(includeModelControls: boolean): PredictionDataRequest {    
    return this.getPredictionRequest(this.selections, includeModelControls);
  }
  getLastRunPredictionRequest(includeModelControls: boolean): PredictionDataRequest {    
    return this.getPredictionRequest(this.lastRunSelections, includeModelControls);
  }

  private getPredictionRequest(selections: UserSelections, includeModelControls: boolean) {
    const pRequest = new PredictionDataRequest();    
    //  Get them by type
    selections.selectedModels.forEach(model => pRequest.addRunId(model.runId, model.modelType));

    pRequest.businessUnit = selections.location.businessUnit;
    pRequest.basin = selections.location.basin;
    pRequest.bench = selections.location.bench;
    pRequest.product = selections.location.product;
    pRequest.timeslice = +selections.configuration.timeslice;

    pRequest.analogChoice = selections.configuration.analogSet;
      
    if(includeModelControls && !selections.configuration.useBODPlaybook) {
      const modelControls =  ModelControlTranslation.translateModelControlRequest(this.modelControlValues);
      pRequest.modelControls = modelControls;
      pRequest.modelControlsJson = JSON.stringify(modelControls);
    }

    return pRequest;
  }

  getPredictionRequestBase(): PredictionDataRequestBase {
    const pRequest = new PredictionDataRequestBase();
    pRequest.runId = this.lastRunSelections.selectedModels.map(model => (model.runId));
    pRequest.basin = this.lastRunSelections.location.basin;
    pRequest.bench = this.lastRunSelections.location.bench;
    pRequest.product = this.lastRunSelections.location.product;
    pRequest.timeslice = +this.lastRunSelections.configuration.timeslice;

    return pRequest;
  }
  getMLBenchPath(): string {
    const machineLearningModel = this.lastRunSelections.selectedModels.find(model => model.modelType === "machine_learning");
    if(machineLearningModel == null) {
      return null;
    }
    return machineLearningModel.bench_path;
  }

  currentControlsAreValid(): boolean {
    this.controlChangeDetails = new Array<string>();
    let valid = true;
    if(!UserSelections.isEqual(this.selections, this.lastRunSelections, this.controlChangeDetails)) {
      valid = false;
    }

    if(this.lastModelControlValues === undefined || this.lastModelControlValues === null)
    {
      valid = false;
    } else {
      this.lastModelControlValues.forEach(modelControl => {
        const currentModelControl = this.modelControlValues.find(x => x.runId === modelControl.runId);
        if(currentModelControl == undefined) {
          this.controlChangeDetails.push("Model control group (" + modelControl.runId + ")");
          valid = false;
        }
        
        modelControl.values.forEach(ctrl => {
          const currentControl = currentModelControl.values.find(x => x.name === ctrl.name);
          if(currentControl == undefined) {
            this.controlChangeDetails.push("Model control " + ctrl.name);
            valid = false;
          }
  
          if(currentControl.value != ctrl.value) {
            this.controlChangeDetails.push("Model control " + ctrl.name);
            valid = false;
          }
        });
      });
    }
    return valid;
  }

  getControlChangeDetails(): string {
    let str = "";
    for(let i = 0; i < this.controlChangeDetails.length; i++) {
      str += "\t";
      str += this.controlChangeDetails[i];
      if(i < this.controlChangeDetails.length - 1) {
        str += "\n";
      }
    }
    return str;
  }
}
