import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, OnDestroy } from '@angular/core';
import * as Plotly from 'plotly.js-dist-min';
import { MAP_CONFIG, MAP_LAYOUT } from 'src/constants/plot.constants';
import { GraphTypes } from 'src/enums/enums';
import * as cloneDeep from 'lodash/cloneDeep';
import { ExportService, ExportType } from 'src/services/export.service';


@Component({
  selector: 'plotly-view',
  templateUrl: 'plotly-view.component.html',
  styleUrls: ["./plotly-view.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PlotlyViewComponent implements OnInit, OnDestroy{
  @Input() slotPosition: string;
  @Input() width:number;
  @Input() height:number;
  @Input() showMargin:boolean=true;
  @Input() isExpandable:boolean=true;
  @Input() plotCssClass:string='PlotlyView__Container';
  @Input() graphName: string;
  @Input() graphType: string = GraphTypes.MAP;
  @Input() headerText: string = null;
  @Input() headerSubText: string = null;
  @Input() exportType: ExportType;

  @Output() updateLayout: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedSlotChange: EventEmitter<string> = new EventEmitter<string>();

  plotResize: boolean;
  loadStatus: Number;
  graphRef: Plotly.PlotlyHTMLElement | null;
  headerType: string;
  GraphData: any = { data: null, layout: null };
  plotWidth: any;
  plotHeight: any;
  debouncedResize: () => void;

  constructor(
    public exportService: ExportService,
  ) {
    this.headerType = "";
    this.loadStatus = -1;
    this.graphRef = null;
    this.debouncedResize = this.debounce(this.onWindowResize.bind(this), 250);
  }

  ngOnInit(): void {
    localStorage.setItem('plotResize', JSON.stringify(false));
    localStorage.setItem('focusModePlots', JSON.stringify([]));
    window.addEventListener('resize', this.debouncedResize);
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.debouncedResize);
  }

  getLoadingClass(): string {
    switch (this.graphType) {
      case GraphTypes.MAP:
        return 'plot-pre-load-container';
      case GraphTypes.BAR:
        return 'plot-pre-load-container-bardata';
      case GraphTypes.SCATTER:
        return 'plot-pre-load-container-scatterdata';
      default:
        return '';
    }
  }

  onWindowResize(): void {
    if (this.graphRef) {
      const update = {
        width: window.innerWidth * this.plotWidth,
        height: window.innerHeight * this.plotHeight
      };
      const tempArray = localStorage.getItem('focusModePlots')
      if (localStorage.getItem('plotResize') == 'true' && tempArray.includes(this.graphName)) {
        update.width = window.innerWidth * this.plotWidth * 2;
        update.height = window.innerHeight * this.plotHeight * 2;
      }
      Plotly.relayout(this.graphRef, update);
    }
  }

  debounce(func: Function, wait: number): () => void {
    let timeout: any;
    return function (...args: any[]) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  initLoading(): void {
    this.loadStatus = 0;
  }
  applyData(data: any, type: string): void {
    this.headerType = type;
    this.loadStatus = 1;
    this.GraphData = data;
    // Convert pixel values to viewport units
    this.plotWidth = this.GraphData.layout['width'] / (window.innerWidth / 100) / 100;
    this.plotHeight = this.GraphData.layout['height'] / (window.innerHeight / 100) / 100;
    // Assign converted values to layout
    // this.GraphData.layout.width = window.innerWidth * this.plotWidth;
    // this.GraphData.layout.height = window.innerHeight * this.plotHeight;
    //this.addCustomButton();
    //var config = {responsive: true};  // This enables the chart to auto-center on resizing of window. Doesn't center on initial load though?
    Plotly.newPlot(this.graphName, this.GraphData.data, this.GraphData.layout, MAP_CONFIG).then(x => {
      this.graphRef = x;
      this.bindEvents();
    }
    );
  }

  createMap(layers: any[], type: string): void {
    this.plotWidth = MAP_LAYOUT.width / (window.innerWidth / 100) / 100;
    this.plotHeight = MAP_LAYOUT.height / (window.innerHeight / 100) / 100;

    this.GraphData.data = layers;
    this.GraphData.layout = cloneDeep(MAP_LAYOUT);
    this.headerType = type;
    this.loadStatus = 1;
    Plotly.newPlot(this.graphName, layers, this.GraphData.layout, MAP_CONFIG).then(x => {
      this.graphRef = x;
      this.bindEvents();
    }
    );
  }

  bindEvents(): void {
    const that = this;
    this.graphRef.on("plotly_relayout", function(data) {
      that.updateLayout.emit(data);
    });
  }

  forceMoveGraph(layoutData: any): void
  {
      Plotly.update(this.graphName, {}, layoutData);
  }

  forceScale(scale: number): void{
    this.GraphData.layout.geo.center.scale = scale;

    Plotly.update(this.graphName, {}, this.GraphData.layout).then(x => {
    });
  }

  plotZoomEvent(): void{
    this.plotResize = !this.plotResize
    localStorage.setItem('plotResize', JSON.stringify(this.plotResize));
    this.selectedSlotChange.emit(this.plotResize ? this.slotPosition : null); // Emit the updated value

    // Update the layout width based on the plotResize flag
    // this.GraphData.layout.width = this.plotResize ? window.innerWidth * this.plotWidth * 2 : window.innerWidth * this.plotWidth;
    // this.GraphData.layout.height = this.plotResize ? window.innerHeight * this.plotHeight * 2 : window.innerHeight * this.plotHeight;
    
    let expandablePlots = localStorage.getItem('focusModePlots');
    expandablePlots = expandablePlots ? JSON.parse(expandablePlots) : [];
    if (Array.isArray(expandablePlots)) {
      if (this.plotResize) {
        expandablePlots.push(this.graphName);
        localStorage.setItem('focusModePlots', JSON.stringify(expandablePlots));
      }else {
        const updatedArray = expandablePlots.filter(plot => plot !== this.graphName);
        localStorage.setItem('focusModePlots', JSON.stringify(updatedArray));
      }
    }

    // Re-render the plot with the updated layout
    Plotly.relayout(this.graphName, { width: this.GraphData.layout.width, height: this.GraphData.layout.height });
  }
  // addCustomButton() {
  //   MAP_CONFIG.modeBarButtonsToAdd = [
  //     {
  //       name: 'savePlot',
  //       title: 'Save for Data Export',
  //       icon: Plotly.Icons['disk'],
  //       click: this.savePlot.bind(this)
  //     }
  //   ]
  // }
}