export class ConfigurationInfo {
    analogSet?: string;
    timeslice?: string;
    useBODPlaybook?: boolean = true;
    
    public constructor(init?: Partial<ConfigurationInfo>) {
        Object.assign(this, init);
    }

    public static isEqual(first: ConfigurationInfo, second: ConfigurationInfo, details: Array<string>): boolean {
        let valid = true;

        if(first === undefined || second === undefined) {
            valid = false;
        }
        else
        {
            if(first.analogSet !== second.analogSet) {
                details.push("Analog Set");
                valid = false;
            }
            if(first.timeslice !== second.timeslice) {
                details.push("Timeslice");
                valid = false;
            }
        }

        return valid;
    }
}