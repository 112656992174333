<div class="sub-header-container">
    <div class="subHeader">
        {{ plotTitle}}
    </div>
</div>

<div class="plotViewContainer">
    <div class="plotView">
        <ng-container *ngFor="let model of selectedModels; index as index">
            <plotly-view
                #slotMap
                [style.display]="(!selectedSlot || selectedSlot === slotNames[index]) ? 'block' : 'none'"
                slotPosition="{{slotNames[index]}}"
                (updateLayout)="updateLayout($event, index)"
                [width]=200  [height] =400 
                [showMargin]=false
                [plotCssClass]="'PlotlyView__Container--Slot'+slotNames[index]"
                [graphName]="slotNames[index] +'Map'"
                (selectedSlotChange)="onSelectedSlotChange($event)"
                [exportType]="exportTypes.Predictions"
            >
            </plotly-view>
        </ng-container>
    </div>
    <div class="scoring">
        <div class="sub-header-container">
            <div class="subHeader">Model Evaluation on Actuals</div>
            <div class="divWrapperCss">
            <mat-form-field class="custom-mat-dropdown" subscriptSizing="dynamic">
                <mat-select [(value)]="scoringSelection" (selectionChange)="scoringChange($event.value)">
                    <mat-option value="mape">MAPE</mat-option>
                    <mat-option value="r2">R2</mat-option>
                </mat-select>
            </mat-form-field>
            <app-info-tooltip 
                    [tooltipContent] = "scoringSelection === 'mape' ? 'MAPE: Mean Absolute Percent Error, exclusive of outliers. A lower MAPE is better, the best model is highlighted in green':
                    'R2: also known as the coefficient of determination, represents the proportion of the variance in actuals that is explained by the model. A higher R2 is better, the best model is highlighted in green'"
                    style="margin-top: 3.5px;"
            />
            </div>
        </div>
        <div class="plotView">
            <ng-container *ngFor="let model of selectedModels; index as index">
                <mape-view 
                    [style.display]="(!selectedSlot || selectedSlot === slotNames[index]) ? 'block' : 'none'"
                    #slotScore
                    slotPosition="{{slotNames[index]}}"
                >
                </mape-view>
            </ng-container>
        </div>
    </div>
    <br>
    <div>
        <!-- <div class="sub-header-container">
            <div class="subHeader">
                Data Downloads
            </div>
        </div> -->
        <app-card-box style="display: flex; justify-content: center; margin-top: 18px;">
            <div class="sub-header-container">
                <div class="subHeader downloads-subheader-overrides">
                    Data Downloads
                </div>
            </div>
            <div class="exportSec">
                <div class="d-flex flex-column align-items-center">
                    <button class="btn-medium-secondary--cancel" [disabled]="!readyToExport" (click)="exportMaps()">Maps</button> 
                    <div class="pDesc">Download images of the maps shown</div> 
                </div>
                <mat-divider [vertical]="true" style="margin-right: 1vw; margin-left: 1vw;"></mat-divider>
                <div class="d-flex flex-column align-items-center">
                    <button class="btn-medium-secondary--cancel" [disabled]="!readyToExport" (click)="exportService.exportData(exportTypes.SectionLevel)">by Lat/Long</button>
                    <div class="pDesc">Download a CSV of predictions by hex centroid</div>
                </div>
                <mat-divider [vertical]="true" style="margin-right: 1vw; margin-left: 1vw;"></mat-divider>
                <div class="d-flex flex-column align-items-center">
                    <button class="btn-medium-secondary--cancel" [disabled]="!readyToExport" (click)="exportService.exportData(exportTypes.PredictionsFutureInventory)">for Well Inventory</button>
                    <div class="pDesc">Download a CSV of predictions for future wells</div>
                </div>
            </div>
        </app-card-box>
    </div>
   </div>
