import { UserSelections } from "../UserSelections";

export class Scenario {
    // id: string;
    userId: string;
    name: string;
    description: string;
    isDefault: boolean;
    
    userSelections: UserSelections;
}