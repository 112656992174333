import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as Plotly from 'plotly.js-dist-min';
import { GraphTypes } from 'src/enums/enums';
import { PlotlyViewComponent } from '../plotly-view/plotly-view.component';
import { ExportService } from 'src/services/export.service';

@Component({
  selector: 'plotly-view-predictions',
  templateUrl: 'plotly-view-predictions.component.html',
  styleUrls: ["../plotly-view/plotly-view.component.scss", "./plotly-view-predictions.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PlotlyViewPredictionComponent extends PlotlyViewComponent implements OnInit, OnDestroy{
  @Input() hasSelection: boolean = false;
  @Input() selectionDefault: string;
  @Input() selectionTitle: string;
  @Input() selectionFirst: string;
  @Input() selectionSecond: string;

  constructor(
    override exportService: ExportService,
  ) {
    super(exportService);
  }
}