import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
@Component
(
  {
    selector: "dyn-control", 
    templateUrl: "./control-component.html", 
    styleUrls: ["./control-component.scss"],
    encapsulation: ViewEncapsulation.None 
  }
)

export class ControlComponent implements OnInit{
    @Input("data") data: any;
    @Output() updateValue : EventEmitter<any> = new EventEmitter<any>(); 
    @Output() submitForm: EventEmitter<any> = new EventEmitter<any>();

    @Input("visibility") visibility: boolean;

    type: string;
    label: string;
    labelDirection: string;

    constructor() {
      this.visibility = false;
      this.type = this.label = this.labelDirection = "";
    }
  
    ngOnInit(): void {
      this.type = this.data.type;
      this.label = this.data.label;
      this.labelDirection = this.data.labelDirection;
      this.visibility = true;
    }

    receiveUpdate(val: any): void{
      this.updateValue.emit(val);
    }

    clickSubmit(val: any): void{
      this.submitForm.emit(val);
    }
  }