import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as Plotly from 'plotly.js-dist-min';

@Component({
  selector: 'mape-view',
  templateUrl: './mape-view.component.html',
  styleUrls: ['./mape-view.component.scss']
})
export class MapeViewComponent implements OnInit, OnDestroy{
  @Input() description: string;
  @Input() url: string;
  @Input() slotPosition: string;

  graphName: string;
  loadStatus: number;
  GraphData : any;
  graphLoaded: boolean;
  graphRef: Plotly.PlotlyHTMLElement | null;
  debouncedResize: () => void;

  constructor(private httpClient: HttpClient) {
    this.loadStatus = -1;
    this.graphRef = null;
    this.debouncedResize = this.debounce(this.onWindowResize.bind(this), 230);
  }

  ngOnInit(): void {
    this.graphName = this.slotPosition+"Score";
    localStorage.setItem('plotResize', JSON.stringify(false));
    window.addEventListener('resize', this.debouncedResize);
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.debouncedResize);
  }

  onWindowResize(): void {
    if (this.graphRef) {
      const update = {
        width: window.innerWidth * 0.196,
        height: window.innerHeight * 0.265
      };
      Plotly.relayout(this.graphRef, update);
    }
  }

  debounce(func: Function, wait: number): () => void {
    let timeout: any;
    return function(...args: any[]) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  initLoading(): void {
    this.loadStatus = 0;
  }

  applyData(data: any): void {
    this.loadStatus = 1;
    this.GraphData = data;
    // if(this.graphName == 'AScore' || this.graphName == 'BScore' || this.graphName == 'CScore'){
      // this.GraphData.layout.width = window.innerWidth * 0.196;
      // this.GraphData.layout.height = window.innerHeight * 0.265;
    // }
    Plotly.newPlot(this.graphName, this.GraphData.data, this.GraphData.layout).then(x =>
      {
        this.graphRef = x;
      }
    );
  }
}
