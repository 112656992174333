import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ControlGroup } from 'src/classes/templates/control.group';
import { ModelSelectionComponent } from 'src/components/dialogs/model-selection/model-selection.component';
import { LoadingScreenService } from 'src/services/ui/loading-screen-service';
import { ModelService } from 'src/services/data/model.service';
import { UserSelectionService } from 'src/services/ui/user-selection.service';
import { ActionItem } from 'src/classes/loadingActions/ActionItem';
import { InterfaceSectionDto } from 'src/app/classes/InterfaceSectionDto';
import { FormGroup } from '@angular/forms';
import { ActionIndex } from 'src/classes/loadingActions/ActionIndex';
import { SelectedModel } from 'src/classes/SelectedModel';
import { ActionTexts } from 'src/classes/loadingActions/ActionTexts';

@Component({
  selector: 'model-navigation',
  templateUrl: './model-navigation.component.html',
  styleUrls: ['./model-navigation.component.scss']
})
export class ModelNavigationComponent extends ControlGroup implements OnInit{

  @Input() userSelectionForm: FormGroup;
  @Output() runQueries: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() recvModelControls: EventEmitter<InterfaceSectionDto[]> = new EventEmitter<InterfaceSectionDto[]>();

  constructor(private selectionService: UserSelectionService, 
    public modelSelection: MatDialog, 
    public modelService: ModelService,
    public loadingService: LoadingScreenService) {
    super();
  }
  ngOnInit(): void {}

  clearSelectedModels(): void {
    this.userSelectionForm.controls['selectedModels'].setValue([]);
    this.recvModelControls.emit(null);
    this.selectionChange.emit();
  }

  getDefaultModels(): void {
    this.loadingService.addAction(0, ActionTexts.LOADING_DEFAULT_MODELS);

    const mRequest = this.selectionService.getCurrentModelRequest();
    mRequest.includeFinal = true;
    mRequest.latestModel = true;

    this.modelService.getModelData(mRequest).subscribe(resp => {
      const data=resp.body;
      data.sort((a,b) => {
        if(a.type > b.type) {
          return -1;
        } else {
          return 1;
        }
      });
      data.forEach(model => {
        var selectedModel = new SelectedModel(model);
        this.selectionService.addSelectedModel(selectedModel);        
      });
      this.getModelControls();
      this.loadingService.actionComplete(0);
    });

  }

  getModelControls() {
    if(this.selectionService.getMLRunId() !== null) {
      this.loadingService.addAction(1, ActionTexts.RETRIEVING_MODEL_CONTROLS);

      const pRequest = this.selectionService.getCurrentPredictionRequest(false);
      this.modelService.getModelControls(pRequest).subscribe(controls => {
        const modelControlArray = Object.values(controls) as InterfaceSectionDto[];
        
        this.recvModelControls.emit(modelControlArray);

        this.loadingService.actionComplete(1);
      });
    } else {
      this.recvModelControls.emit(null);
    }
  }

  showModelSelect(): void {
    const dialogRef = this.modelSelection.open(ModelSelectionComponent, {
      enterAnimationDuration: '200ms',
      exitAnimationDuration: '200ms',
      panelClass: 'custom-model-dialog'
    });

    dialogRef.afterClosed().subscribe( result => {
      if(result) {
        this.userSelectionForm.controls['selectedModels'].setValue(result);
        this.getModelControls();
      }
    });
  }
}
