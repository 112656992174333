import { DropdownChoice } from "src/classes/DropdownChoice";
import { LocationInfo } from "src/classes/LocationInfo";
import { ConfigurationInfo } from "src/classes/ConfigurationInfo";
import { UserSelections } from "src/classes/UserSelections";

export class BUConstants {
    public static cbu = 'cbu';
    public static mcbu = 'mcbu';
    public static rbu = 'rbu';
}
export class CBUConstants {
    public static cbuBasin = 'kaybob';
    public static cbuBench = 'duvernay';
}
export class MCBUConstants {
    public static mcbuBasin = 'delaware';
    public static mcbuBench = 'wca';
}
export class LookupConstants {
    public static basin = 'BASIN';
    public static bod = 'BASIS_OF_DESIGNS';
    public static sequence = 'RUN_SEQUENCE';
    public static moduleFeatures = 'MODULE_FEATURES';
    public static scorer = 'SCORER';
}


export const bus: DropdownChoice[] = [
    { id: 'cbu', name: 'CBU' },
    { id: 'mcbu', name: 'MCBU' },
    { id: 'rbu', name: 'RBU' }
];
export const basins: DropdownChoice[] = [
    { id: 'kaybob', name: 'Kaybob', parentId: ['cbu'] },
    { id: 'delaware', name: 'Delaware', parentId: ['mcbu'] },
    { id: 'midland', name: 'Midland', parentId: ['mcbu'] },
    { id: 'central', name: 'Central', parentId: ['mcbu'] },
    { id: 'dj', name: 'DJ', parentId: ['rbu'] },
    { id: 'niobrara', name: 'Niobrara', parentId: ['rbu'] },
];
export const benches: DropdownChoice[] = [
    // CBU
    { id: 'duvernay', name: 'Duvernay', parentId: ['kaybob'] },

    //MCBU
    // Midland and Delaware
    { id: 'wca', name: 'WCA', parentId: ['delaware', 'midland'] },
    { id: 'wca1', name: 'WCA1', parentId: ['delaware', 'midland'] },
    { id: 'wca2', name: 'WCA2', parentId: ['delaware', 'midland'] },
    { id: 'wcb', name: 'WCB', parentId: ['delaware', 'midland'] },
    { id: 'wcc', name: 'WCC', parentId: ['delaware', 'midland'] },
    { id: 'wcd', name: 'WCD', parentId: ['delaware', 'midland'] },

    // Just Delaware
    { id: 'avu', name: 'AVU', parentId: ['delaware'] },
    { id: 'avl', name: 'AVL', parentId: ['delaware'] },
    { id: 'fbs', name: 'FBS', parentId: ['delaware'] },
    { id: 'sbu', name: 'SBU', parentId: ['delaware'] },
    { id: 'sbl', name: 'SBL', parentId: ['delaware'] },
    { id: 'tbs', name: 'TBS', parentId: ['delaware'] },

    // Just Midland
    { id: 'spu_sh', name: 'SPU SH', parentId: ['midland'] },
    { id: 'spl_sh', name: 'SPL SH', parentId: ['midland'] },
    { id: 'spl_jml', name: 'SPL JML', parentId: ['midland'] },
    { id: 'cfu', name: 'CFU', parentId: ['midland'] },
    { id: 'cfl', name: 'CFL', parentId: ['midland'] },
    { id: 'dean', name: 'Dean', parentId: ['midland'] },
    { id: 'cln', name: 'CLN', parentId: ['midland'] },

    // RBU
    { id: 'niobrara', name: 'Niobrara', parentId: ['dj'] },
    { id: 'codell', name: 'Codell', parentId: ['dj'] },
];

export const products: DropdownChoice[] = [
    { id: 'oil', name: 'Oil' },
    { id: 'gas', name: 'Gas' },
    { id: 'water', name: 'Water' },
];

export const mcbu_defaults: UserSelections = {
    location: new LocationInfo({
        businessUnit: 'mcbu',
        basin: 'delaware',
        product: 'oil',
        bench: 'wca',
    }),
    configuration: new ConfigurationInfo({
        timeslice: '24'
    }),
    selectedModels: []
}

export const cbu_defaults: UserSelections = {
    location: new LocationInfo({
        businessUnit: 'cbu',
        basin: 'kaybob',
    }),
    configuration: new ConfigurationInfo({
        timeslice: '24'
    }),
    selectedModels: []
}
