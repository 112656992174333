import { UserSelectionService } from "src/services/ui/user-selection.service";
import { GraphTypes } from 'src/enums/enums';
import { capitalizeTitle } from 'src/services/ui/titles-service';

export class TabViewComponent {
    dataLoaded: boolean;

    constructor(protected selectionService: UserSelectionService) {
        this.dataLoaded = true;
    }

    setDataLoaded(loaded: boolean){
        this.dataLoaded = loaded;
    }

    getMapGraphyType() {
        return GraphTypes.MAP
    }

    getBarGraphyType() {
        return GraphTypes.BAR
    }

    getScatterGraphyType() {
        return GraphTypes.SCATTER
    }

    capitalizeTitle(title: string){
        return capitalizeTitle(title)
    }
}