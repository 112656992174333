<!-- Uses visibility to leave this div in the DOM supporting the Plotly view updating. -->
<div class="d-flex flex-column align-items-center justify-content-center"> <!--card-container-->
    <h3>Predictions Mean</h3>
    <p>from the area selected and the requested timeslice</p>
    <!-- <div class="d-flex flex-row align-items-center justify-content-center" [style.visibility]="selectedHexes.length == 0 ? 'hidden': 'visible'"> -->
    <div #predictionSummary class="d-flex flex-row align-items-center justify-content-center"> 
        <!-- Uses ngIf to avoid taking up space when visibility is false. -->
        <!-- <div class="table-container" *ngIf="selectedHexes.length > 0"> -->
        <div class="table-container">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                <!-- Dynamic column definitions -->
                <ng-container matColumnDef="model">
                    <th mat-header-cell *matHeaderCellDef> Model </th>
                    <td mat-cell *matCellDef="let element"> 
                        <div class="d-flex align-items-center">                       
                            <div class="ModelDisplay__Position--Position{{ element.position }}">
                                {{ element.position }}
                            </div>                                
                            <div class="selectedModelRunIdHolder">
                                {{element.model}}
                            </div>
                        </div> 
                    </td>
                </ng-container>
                <ng-container matColumnDef="prediction">
                    <th mat-header-cell *matHeaderCellDef> Prediction </th>
                    <td mat-cell *matCellDef="let element"> 
                        {{ element.prediction !== 0 ? (element.prediction | number: '1.0-0') : '' }} 
                    </td>
                </ng-container>
            
                <!-- Header and Row Definitions -->
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
        <div class="PlotlyView__Container">            
            <div id="{{ graphName }}"></div>
        </div>
    </div>
</div>
<br>