import { Component, OnInit, Input, ViewChild, ViewEncapsulation, EventEmitter, Output } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { MatSlider, MatSliderThumb } from '@angular/material/slider';
import { LocationInfo } from 'src/classes/LocationInfo';
import { ControlGroup } from 'src/classes/templates/control.group';
// import { LocationCriteriaComponent } from 'src/components/base/location.component';
import { CBUConstants, MCBUConstants, basins, benches, bus, products } from 'src/constants/bu.constants';
import { UserSelectionService } from 'src/services/ui/user-selection.service';
import { LookupService } from 'src/services/data/lookup.service';
import { LoadingScreenService } from 'src/services/ui/loading-screen-service';
import { FormGroup } from '@angular/forms';
import { DropdownChoice } from 'src/classes/DropdownChoice';

@Component({
  selector: 'location-group',
  templateUrl: './location-group.component.html',
  styleUrls: ['./location-group.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LocationGroupComponent extends ControlGroup implements OnInit {
  @Input() location: FormGroup;
  basins: DropdownChoice[];
  benches: DropdownChoice[];
  bus: DropdownChoice[] = bus;
  products: DropdownChoice[] = products;
  initialLoad: boolean;

  constructor(protected selectionService: UserSelectionService,
    protected loading: LoadingScreenService) {
      super();
      // this.initialLoad = true;

      // this.loading.init();
      // this.loading.addAction(0, "Loading Basins");
      // this.loading.addAction(1, "Loading Benches");
      // this.loading.show();
  }

  ngOnInit(): void {
    this.baseInit();
  }
  filterBasins(): DropdownChoice[] {
    return basins.filter(b => b.parentId.indexOf(this.location.get('businessUnit').value) !== -1)
  }

  filterBenches(): DropdownChoice[] {
    return benches.filter(b => b.parentId.indexOf(this.location.get('basin').value) !== -1)
  }


  basinChange(): void {
    this.location.get('bench').reset();
    this.selectionUpdate();
  }

  buChange(): void {
    this.location.get('bench').reset();
    this.location.get('basin').reset();
    this.selectionUpdate();
  }
}