import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError} from 'rxjs';
import {  CommonFunctions } from 'src/classes/CommonFunctions';
import { ModelDataResponse } from 'src/classes/dataTransfer/responses/ModelDataResponse';
import { environment } from 'src/environments/environment';
import { RequestBuilder } from 'src/classes/functions/RequestBuilder';
import { PredictionDataRequest, PredictionDataRequestComparison } from 'src/classes/dataTransfer/requests/PredictionDataRequest';
import { BaseService } from '../service-base.service';
import { DefaultUrlSerializer, Router, UrlSerializer } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserProfileService } from '../auth/user-profile.service';
import { UserSelections } from 'src/classes/UserSelections';
import { Scenario } from 'src/classes/dataTransfer/Favorites';

@Injectable({
    providedIn: 'root'
})

export class UserFavoriteService extends BaseService {
    public constructor(
        http: HttpClient,
        snackBar: MatSnackBar,
        private userService: UserProfileService
    ) {
        super(http, snackBar);
    }

    public getUserDefault(): Observable<Scenario> { 
        const userId = this.userService.getUserProfile().objectId;
        const qString = new RequestBuilder(environment.apoloWebApiURL, "users/"+userId+"/scenarios/default");
        return this.http.get<any>(qString.getUrl(), CommonFunctions.headerInfo)
            .pipe(catchError(this.handleError));
    }

    public postUserDefault(selections: UserSelections): Observable<any> {
        const userId = this.userService.getUserProfile().objectId;
        const qString = new RequestBuilder(environment.apoloWebApiURL, "users/"+userId+"/scenarios/default");
        const scenario = new Scenario();
        scenario.userId = userId;
        scenario.name = "Default";
        scenario.isDefault = true;
        scenario.userSelections = selections;
        let header = Object.assign({}, CommonFunctions.headerInfo);
        header['responseType'] = 'text';
        return this.http.post<string>(qString.getUrl(), scenario, header)
            .pipe(catchError(this.handleError));
    }
}