export class QueryStringItem {
    name: string;
    value: string;

    public constructor(name: string, value: string) {
        this.name = name;
        this.value = value;
    }

    public toText(): string {
        return this.name + "=" + this.value;
    }
}