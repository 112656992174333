import { Injectable } from '@angular/core';
import { CalAngularService, CvxClaimsPrincipal, ICvxClaimsPrincipal } from '@cvx/cal-angular';
import { Observable, Subject, catchError, forkJoin, map, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UserPermission } from 'src/models/user-permisson.model';
import { environment } from 'src/environments/environment';
import { LoginComponent } from 'src/components/login/login.component';

@Injectable({
  providedIn: 'root',
})
export class UserProfileService {
  scopes: string[] = environment.root.apiScope;
  loggedIn: boolean = false;

  userCAI: string = null;
  private currentUserToken = null;
  private currentUserProfile: ICvxClaimsPrincipal = null;

  public _userPermissionReceived = new Subject<any>();
  public userPermissionReceived$ = this._userPermissionReceived.asObservable();

  private _tokenReceived = new Subject<any>();
  public tokenReceived$ = this._tokenReceived.asObservable();

  public userContextObs: Observable<UserPermission>;

  constructor(
    private authService: CalAngularService,
    private httpClient: HttpClient,
    private userContext: UserPermission
  ) {
    this.userContextObs = new Observable((observer) => {
      if (Object.keys(this.userContext).length === 0) {
        this.tokenReceived$.subscribe((token) => {
          this.GetUserContextFromWebApi().subscribe((result) => {
            this.MapUserContext(result);
            observer.next(this.userContext);
          });
        });
      } else {
        observer.next(this.userContext);
      }
    });
  }

  checkAccount( callback: (loginStatus: boolean, sender: LoginComponent) => void, sender: LoginComponent) {
    this.authService.isUserSignedIn().subscribe((isLoggedIn: boolean) => {
      this.loggedIn = isLoggedIn;
      callback(this.loggedIn, sender)
      if (isLoggedIn) {
        this.setCurrentUserProps();
      } else {
        this.authService.userInitiatedSignIn();
      }
    });
  }

  getToken() {
    this.authService.getAADToken(this.scopes).subscribe((data) => {
      this.SetUserDetails('userToken', data);
      this._tokenReceived.next({ userToken: data });
    });
  }

  refreshToken() {
    this.authService.getAADToken(this.scopes).subscribe((data) => {
      this.SetUserDetails('userToken', data);
    });
  }

  setCurrentUserProps() {
    this.currentUserProfile = this.authService.cvxClaimsPrincipal;
    this.userCAI = this.currentUserProfile.cai;
    this.getCurrentUser();
    this.getToken();
  }

  private SetUserDetails(userProp: string, userDetails) {
    const user = localStorage.getItem(userProp);
    if (user != null) {
      localStorage.removeItem(userProp);
      localStorage.setItem(userProp, userDetails);
    } else {
      localStorage.setItem(userProp, userDetails);
    }
  }

  getUserDetails(userProp: string) {
    const content = localStorage.getItem(userProp);
    if (content) {
      return content;
    } else {
      return null;
    }
  }

  login() {
    this.authService.userInitiatedSignIn().subscribe(() => {
      this.setUserLoggedIn();
    });
  }

  logout() {
    this.authService.userInitiatedSignOut().subscribe((value: boolean) => {
      this.loggedIn = value;
    });
  }

  getCurrentUser() {
    let cvxToken: string = this.authService.getAccessTokenFromCache();
    this.currentUserToken = cvxToken ? JSON.parse(cvxToken) : null;
  }
  
  getUserProfile(): ICvxClaimsPrincipal {
    return this.authService.cvxClaimsPrincipal;
  }

  getCurrentUserSecret() {
    let secret = this.currentUserToken ? this.currentUserToken.secret : null;
    return secret;
  }

  setUserLoggedIn() {
    this.authService.isUserSignedIn().subscribe((value: boolean) => {
      this.loggedIn = value;
      if (this.loggedIn) {
        this.setCurrentUserProps();
      } else {
        console.error('user not yet signed in');
        this.authService.userInitiatedSignIn();
      }
    });
  }

  isUserInGroup(groupId: string): any {
    let flag: boolean = false;

    this.authService.isInGroup(groupId).subscribe((value: boolean) => {
      flag = value;
      return flag;
    });
  }

  GetUserDetails(): Observable<UserPermission> {
    let url: string = environment.apoloWebApiURL + 'UserPermission/';
    return this.httpClient.get<UserPermission>(`${url}`);
  }

  private GetUserContextFromWebApi(): Observable<{
    commonContext: UserPermission;
  }> {
    return forkJoin([this.GetUserDetails()]).pipe(
      map(([commonContext]) => {
        return {
          commonContext,
        };
      })
    );
  }

  private MapUserContext(userContext: { commonContext: any }) {
    this.userContext.IsAdmin = userContext.commonContext.isAdmin;
       this._userPermissionReceived.next(userContext.commonContext);
  }

  GetUserContext(): Observable<UserPermission> {
    return this.userContextObs;
  }
}
