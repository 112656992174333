export class ActionIndex
{
    static LOADING_DEFAULT_MODELS = 0;
    static VERIFYING_MODEL_COMPATIBILITY = 1;
    static RETRIEVING_MODEL_CONTROLS = 2;
    static RETRIEVING_PLOTS = 10;
    static RETRIEVING_SCORING = 20;
    static RETRIEVING_SHAP_MAP = 30;
    static RETRIEVING_QQ_PLOT = 60;
    static LOADING_REPORT = 40;
    static GENERATING_AREA = 50;
    static LOADING_DATA=60;    
}