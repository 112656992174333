<textarea #textInput type="text"
style="border: 1px solid red;" 
[style.color]="dirty ? '#000' : '#aaa'"
[style.backgroundColor]="valid ? '' : '#fbb'"
(input)="onChange(textInput.value)" 
[value]="defaultValue"
[attr.maxlength]="maxLength"
[style.width]="sizeWidth"
[style.height]="sizeHeight"
>
</textarea>