import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FolderComponent } from "./folder-component";

@NgModule({
    declarations: [
        FolderComponent
    ],
    imports: [
        CommonModule
    ],
    providers: []
  })
  export class FolderModule { }