<div style="padding: 7px;">
    <div style="padding: 5px;">
        <template #templateView></template>
    </div>
    <div style="display:flex; width: 100%; justify-content: space-between;">
        <div style="width: 30px;">
            <div class="PAGINATE" *ngIf="currentPage != 0" (click)="prevPage()">
                &lt;
            </div>
        </div>
        <div style="text-align: center; padding: auto; justify-content: center; display: flex;">
            <table cellspacing="5">
                <tr>
                    <td *ngFor="let pageNum of data.pages; index as i;">
                        <div *ngIf="currentPage == i" class="PAGE_NUMBER CURRENT_PAGE">
                            {{ i + 1 }}
                        </div>
                        <div *ngIf="currentPage != i" (click)="selectPage(i)" class="PAGE_NUMBER OTHER_PAGE">
                            {{ i + 1 }}
                        </div>
                    </td>
                </tr>
            </table>
        </div>
        <div style="width: 30px;">
            <div class="PAGINATE" (click)="nextPage()" *ngIf="currentPage != totalPages - 1">
                &gt;
            </div>
        </div>
    </div>
    
</div>