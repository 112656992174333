import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { UserSelectionService } from 'src/services/ui/user-selection.service';
import { SelectedModel } from 'src/classes/SelectedModel';
import { ModelDataResponse } from 'src/classes/dataTransfer/responses/ModelDataResponse';
import { ModelSelectionComponent } from '../model-selection/model-selection.component';

@Component({
  selector: 'app-model-resolve',
  templateUrl: './model-resolve.component.html',
  styleUrls: ['./model-resolve.component.scss']
})
export class ModelResolveComponent implements OnInit{

  suggestedModels: SelectedModel[];
  selectedModels: SelectedModel[];
  missingModels: SelectedModel[];
  allModels: ModelDataResponse[];
  positions = ['A','B','C'];
  countdown: number;
  running: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ModelResolveComponent>,
    private selectionService: UserSelectionService,
    public modelSelection: MatDialog, 
   )
  {
    this.countdown = 6;
    this.selectedModels = this.selectionService.getCurrentModels();
    this.missingModels = this.data.missing;
    this.allModels = this.data.allModels;

    this.calculateSuggestedModels();
    this.running = true;
  }

  ngOnInit(): void {
    this.continueCountdown();
  }

  continueCountdown(): void {
    if(this.countdown === 1)
    {
      this.finishRedirect();
    } else {
      --this.countdown;
      setTimeout(() => { this.continueCountdown() }, 1000);
    }
  }

  getSuggestedModel(modelType: string): SelectedModel {
    let suggested = this.allModels.filter(model => model.type === modelType);

    switch(suggested.length){
      case 1:
        return new SelectedModel(suggested[0]);
      default:
        const finalStatus = suggested.filter(model => model.promotion_status === "final");
        if(finalStatus.length === 1) {
          return new SelectedModel(suggested[0]);
        }

        //  We may want to add some more filtering for time
    }
    return null;
  }

  calculateSuggestedModels(): void {
    this.suggestedModels = [ undefined, undefined, undefined ];

    for(let i = 0; i < this.selectedModels.length; ++i) {
      const model = this.selectedModels[i];

      if(this.modelIsMissing(model.runId, model.modelType)) {
        this.suggestedModels[i] = this.getSuggestedModel(model.modelType);
      }
    }
  }

  modelIsMissing(runId: string, modelType: string): boolean 
  {
    return this.missingModels.some(x => x.runId === runId && x.modelType === modelType);
  }

  showModelSelect(): void {
    this.dialogRef.close();

    const dialogRef = this.modelSelection.open(ModelSelectionComponent, {
      width: '1200px',
      height: '810px',
      enterAnimationDuration: '200ms',
      exitAnimationDuration: '200ms',
      data: {
        missingModels: this.missingModels
      }
    });
  }
  cancelRedirect(): void {
    this.dialogRef.close(false);
  }

  finishRedirect(): void {
    for(let i = 0; i < this.suggestedModels.length; ++i)
    {
      if(this.suggestedModels[i] !== undefined) {
        const oldModel = this.selectedModels[i];
        this.selectedModels[i] = this.suggestedModels[i];
        this.selectedModels[i].position = oldModel.position;
      }
    }
    this.dialogRef.close(true);
  }
}