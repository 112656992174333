<div class="containerDivWrapper">
  <div class="tableContainer">
    <div class="sub-header-container">
      <div class="subHeader">
        Users Logs
        <div class="export">
        <input type="button" (click)="downloadLogs()" *ngIf="dataSource.data?.length > 0" class="btn-export" title="Download logs">
      </div>
    </div>
    </div>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
        <th mat-header-cell *matHeaderCellDef class="sticky-header"> {{ ColumnAlias[column]}} </th>
        <td mat-cell *matCellDef="let element" style="text-align: center !important;"> {{ element[column] }} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>