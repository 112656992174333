import { Component, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { MapAreaEx } from 'src/classes/dataTransfer/areas/MapAreaEx';
import { PredictionService } from 'src/services/data/prediction.service';
import { UserSelectionService } from 'src/services/ui/user-selection.service';
import { PlotlyViewComponent } from '../plotly-view/plotly-view.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { capitalizeTitle } from 'src/services/ui/titles-service';
import { ExportType } from 'src/services/export.service';
import { MapPlotService } from 'src/services/map-plot.service';
import { PlotlyService } from 'angular-plotly.js';

@Component({
  selector: 'app-prediction-view',
  templateUrl: './prediction-view.component.html',
  styleUrls: ['./prediction-view.component.scss']
})
export class PredictionViewComponent {
  customTimelineDistributionHeader: string = "Timeline Distribution";

  predictionHeaders = [
    "Mean Prediction Across Area",
    "Predictions EV Against Actuals Distribution"
  ];

  detailedGraphNames = [
    "actualVsPredicted",
    "percentErrorOverTime",
    // "predictionsByHex",
  ];
  detailedHeaders = [
    "Actuals vs Predicted Over Time",
    "Percent Error Over Time",
    // "Mean Prediction Across Area",
  ];
  detailedThumbnails = [
    './assets/img/detailed_map_placeholder_1.png',
    './assets/img/detailed_map_placeholder_2.png',
    // './assets/img/detailed_map_placeholder_3.png',
  ];
  validationGraphNames = [
    "qqPlot",
    "s-curve",
    "probit"
  ];
  validationHeaders = [
    "Actual Quantiles vs Predicted Quantiles",
    "Actuals vs Predicted S-Curve",
    "Actuals vs Predicted Probit",
  ];
  validationThumbnails = [
    './assets/img/validation_map_placeholder_1.png',
    './assets/img/validation_map_placeholder_2.png',
    './assets/img/validation_map_placeholder_3.png',
  ];

  predictionThumbnails = [
    './assets/img/pred_overtime.png',
    './assets/img/pred_ev_vs_actuals_scurve.png',
  ];
  currentSlideSummaryView = 0; // state for summary view current slide
  currentSlideAOTView = 0; // state for Accuracy over time view current slide
  currentSlideAATView = 0; // state for Accuracy at timeslice view current slide  predictionTab = 'prediction summary';
  predictionTab = 'prediction summary';
  tabs: string[] = ['prediction summary', 'accuracy over time', 'accuracy at timeslice'];
  selectedHexes = [];
  exportTypes = ExportType;

  @Input() mapData: MapAreaEx;
  @ViewChildren(PlotlyViewComponent) plotComponents: QueryList<PlotlyViewComponent>;

  constructor(private selectionService: UserSelectionService, private predictionsService: PredictionService, 
    private snackBar: MatSnackBar,private mapPlotService:MapPlotService,private plotlyService:PlotlyService) {
  }

  finishPlot(plotlyView: PlotlyViewComponent, data: any, dataSubset: string = null): void {
    if (dataSubset != null) {
      data = data[dataSubset];
    }
    // data.layout.width = window.innerWidth * 0.340;
    // data.layout.height = window.innerHeight * 0.380;
    // data.layout.margin = {"t": 0};
    plotlyView.applyData(data, "custom");
  }

  switchTag(flag: any) {
    this.predictionTab = flag
  }

  getClassForTab(tab: string): string {
    return this.predictionTab === tab ? 'tag-toggle-css' : 'tag-toggle-disabled-css';
  }

  onSlideChange(slideIndex: number) {
    if(this.predictionTab =="prediction summary"){
      this.currentSlideSummaryView = slideIndex; 
    }else if(this.predictionTab =="accuracy over time"){
      this.currentSlideAOTView = slideIndex; 
    }else if(this.predictionTab == "accuracy at timeslice"){
      this.currentSlideAATView = slideIndex;
    }  }

  getGraphName(index: number): string {
    return this.detailedGraphNames[index];
  }

  getHeaderText(index: number): string {
    return this.detailedHeaders[index];
  }

  getValidationGraphName(index: number): string {
    return this.validationGraphNames[index];
  }

  getValidationHeaderText(index: number): string {
    return this.validationHeaders[index];
  }

  doPrediction(): void {
    const predictionRequest = this.selectionService.getLastRunPredictionRequest(true);
    // update prediction summary 
    if(this.mapData !== null)
    {
      this.selectedHexes = this.mapData.hexValues;
    }
    this.customTimelineDistributionHeader = predictionRequest.timeslice.toString() + " Months " + predictionRequest.product + " Distribution";

    // Fetch all components before the API calls
    const plotlyComponentsArray = this.plotComponents.toArray();

    plotlyComponentsArray.forEach((plotComponent) => {
      if (plotComponent) {
        plotComponent.initLoading();
      }
    });
    let title='Local Area Predictions';
    this.predictionsService.getPredictionOvertimeVolumePlot(predictionRequest, this.mapData?.hexValues).subscribe(data => {
      this.finishPlot(plotlyComponentsArray[0], data, "plot");
      this.mapPlotService.savePlot(plotlyComponentsArray[0].graphName,title,this.predictionHeaders[0],title);
    });

    this.predictionsService.getPredictionsVsActualsSCurve(predictionRequest, this.mapData?.hexValues).subscribe(data => {
      this.finishPlot(plotlyComponentsArray[1], data, "plot");
      this.mapPlotService.savePlot(plotlyComponentsArray[1].graphName,title,plotlyComponentsArray[1].headerText,title);
    });

    this.predictionsService.getScoreOvertimeVolumePlot(predictionRequest, this.mapData?.hexValues).subscribe(data => {
        this.finishPlot(plotlyComponentsArray[2], data, "plot");
        this.mapPlotService.savePlot(plotlyComponentsArray[2].graphName,title,plotlyComponentsArray[2].headerText,title);
    });

    this.predictionsService.getScoreOvetimePercentErrorPlot(predictionRequest, this.mapData?.hexValues).subscribe(data => {
        this.finishPlot(plotlyComponentsArray[3], data, "plot");
        this.mapPlotService.savePlot(plotlyComponentsArray[3].graphName,title,plotlyComponentsArray[3].headerText,title);
    });
    
    this.predictionsService.getScoreQQPlot(predictionRequest, this.mapData?.hexValues).subscribe(data => {
        this.finishPlot(plotlyComponentsArray[4], data, "plot");
        this.mapPlotService.savePlot(plotlyComponentsArray[4].graphName,title,plotlyComponentsArray[4].headerText,title);
    });

    this.predictionsService.getScoreSCurvePlot(predictionRequest, this.mapData?.hexValues).subscribe(data => {
        this.finishPlot(plotlyComponentsArray[5], data, "plot");
        this.mapPlotService.savePlot(plotlyComponentsArray[5].graphName,title,plotlyComponentsArray[5].headerText,title);
    });

    this.predictionsService.getScoreProbitPlot(predictionRequest, this.mapData?.hexValues).subscribe(data => {
        this.finishPlot(plotlyComponentsArray[6], data, "plot");
        this.mapPlotService.savePlot(plotlyComponentsArray[6].graphName,title,plotlyComponentsArray[6].headerText,title);
    });
  }
 }
