<div class="carousel-container">
    <img src="./assets/img/icons/backward_navigation_prev.png" width="80" height="80" (click)="prevSlide()">
    <div class="carousel-slide" [ngClass]="slideDirection">
        <ng-content />
    </div>
    <img src="./assets/img/icons/forward_navigation_next.png" width="80" height="80" (click)="nextSlide()">
</div>
<br><br>
<div class="thumbnail-container" style="display: flex; justify-content: center;">
    <div *ngFor="let thumbnail of thumbnails; let i = index" class="thumbnail" (click)="jumpToSlide(i)">
        <img 
            [src]="thumbnail" 
            width="130" 
            height="85" 
            alt="Placeholder for plot {{i+1}}" 
            [ngClass]="currentSlide === i ? 'active-thumbnail' : 'inactive-thumbnail'"
        >
    </div>
</div>
  