import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ControlValue } from "src/classes/dataTransfer/ControlValue";
import { DynamicComponent } from "src/classes/templates/dynamic.component";

@Component
(
{selector: "dyn-text-box", templateUrl: "./text-box-control.html" }
)

export class TextBoxControlComponent extends DynamicComponent<string> implements OnInit{

    sizeWidth: string;
    minLength: number;
    maxLength: number;

    constructor() {
      super();
    }
  
    ngOnInit(): void {
      this.defaultValue = this.data.defaultValue;
      this.minLength = this.data.minimum;
      this.maxLength = this.data.maximum;
      this.visibility = true;
      switch(this.data.size)
      {
        case "t":
          this.sizeWidth = "20px";
          break;
        case "s":
          this.sizeWidth = "50px";
          break;
        case "l":
          this.sizeWidth = "200px";
          break;
        case "x":
          this.sizeWidth = "300px";
          break;
        default:
        case "m":
          this.sizeWidth = "100px";
          break;
      }
    }

    onChange(value: any): void {
      this.valid = true;
      if(this.minLength != undefined && value.length < this.minLength) {
        this.valid = false;
      }
      


      this.dirty = (value != this.defaultValue);

      const strValue = new ControlValue<string>();
      strValue.value = value;
      strValue.name = this.data.data;
      this.updateValue.emit(strValue);
    }
  }