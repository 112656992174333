import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    template: ''
  })
export abstract class ControlGroup {
  @Input() groupName;
  @Input() groupTitle='';
  @Output() selectionChange = new EventEmitter();
  
  collapsed: boolean;

  collapse(): void {
    this.collapsed = !this.collapsed;
  }

  baseInit(): void {
    this.collapsed = false;
  }

  selectionUpdate(): void {
    this.selectionChange.emit();
  }
}