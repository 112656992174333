<div class="HEADER_CONTAINER">
    <div class="GROUP_NAME" (click)="collapse()" [title]="groupTitle">{{ groupName }}</div>
    <div *ngIf="collapsed" class="EXPAND_GROUP" (click)="collapse()"><mat-icon>chevron_right</mat-icon></div>
    <div *ngIf="!collapsed" class="EXPAND_GROUP" (click)="collapse()"><mat-icon>expand_more</mat-icon></div>
</div>
<mat-divider *ngIf="!collapsed" class="similar-margin"></mat-divider>
<div style="min-height: 100px;" [style.display]="collapsed ? 'none' : 'block'">    
    <div [formGroup]="location" class="location-group">
        <mat-form-field class="location-custom-mat-dropdown" subscriptSizing="dynamic">
            <!-- <mat-label>Business Unit</mat-label> -->
            <mat-select #businessUnit placeholder="Business Unit" formControlName="businessUnit"
                (selectionChange)="buChange()"
                matTooltip="Business unit" [matTooltipShowDelay]="500"
                >
                <mat-option *ngFor="let option of bus" [value]="option.id">{{ option.name }}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="location-custom-mat-dropdown" subscriptSizing="dynamic">
            <!-- <mat-label>Basin</mat-label> -->
            <mat-select #basin placeholder="Basin"  formControlName="basin"
                (selectionChange)="basinChange()"
                matTooltip="Basin" [matTooltipShowDelay]="500"
                >
                <mat-option *ngFor="let option of filterBasins()" [value]="option.id">{{ option.name }}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="location-custom-mat-dropdown" subscriptSizing="dynamic">
            <!-- <mat-label>Product</mat-label> -->
            <mat-select #product placeholder="Product"  formControlName="product"
                (selectionChange)="selectionUpdate()"
                matTooltip="Product" [matTooltipShowDelay]="500"
                >
                <mat-option *ngFor="let option of products" [value]="option.id">{{ option.name }}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="location-custom-mat-dropdown" subscriptSizing="dynamic">
            <!-- <mat-label>Bench</mat-label> -->
            <mat-select #bench placeholder="Bench"  formControlName="bench"
                (selectionChange)="selectionUpdate()"
                matTooltip="Bench" [matTooltipShowDelay]="500"
                >
                <mat-option *ngFor="let option of filterBenches()" [value]="option.id">{{ option.name }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>