import jsPDF, { jsPDFOptions } from "jspdf";
import { BaseService } from "./service-base.service";
import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable } from "rxjs";
import { PageData } from "src/classes/pdf/PageData";

@Injectable({
    providedIn: 'root'
})


export class PDFService {
    pageSize = [225, 250];
    apoloDarkBlue: string = '#00213B';
    defaultFooterText: string = 'APOLO | ';

    constructor(private http: HttpClient) {
    }
    loadFont(fontPath: string): Observable<String> {
        return this.http.get(fontPath, { responseType: 'text' });
    }
    // Helper function to convert array buffer to base64  
    private arrayBufferToBase64(buffer: ArrayBuffer): string {
        const byteArray = new Uint8Array(buffer);
        let binary = '';
        for (let i = 0; i < byteArray.byteLength; i++) {
            binary += String.fromCharCode(byteArray[i]);
        }
        return window.btoa(binary);
    }
    loadMultipleFonts(): Observable<any> {
        const fontRequests = [
            this.loadFont('./assets/fonts/Gotham-Bold.ttf'),
            this.loadFont('./assets/fonts/GothamNarrow-Book.ttf')
        ]
        return forkJoin(fontRequests);

    }
    createPDFDocument(coverPageData, otherPageData): Observable<jsPDF> {
        return new Observable(observer => {
            const doc = new jsPDF('p', 'mm', this.pageSize);
            this.loadMultipleFonts().subscribe(fonts => {

                // Add font to VFS
                doc.addFileToVFS('Gotham-Bold.ttf', fonts[0]);
                doc.addFont('./assets/fonts/Gotham-Bold.ttf', 'Gotham-Bold', 'normal');
                doc.addFileToVFS('GothamNarrow-Book.ttf', fonts[1]);
                doc.addFont('./assets/fonts/GothamNarrow-Book.ttf', 'GothamNarrow-Book', 'normal');
                doc.setFont('GothamNarrow-Book')

                const pageHeight = doc.internal.pageSize.height;
                const pageWidth = doc.internal.pageSize.width;
                const scale = window.devicePixelRatio || 1

                this.addCoverPage(doc, coverPageData);
                //doc.addPage();
                doc.setFillColor('#ffffff');
                let dataWidth, dataHeight;
                let yPosition = 20;

                otherPageData.forEach((item: PageData, index: number) => {

                    if (item.imgSize != null) { // if content is image
                        let { imgWidth, imgHeight } = item.imgSize;
                        dataHeight = imgHeight / scale;
                        dataWidth = imgWidth / scale;
                    }
                    else { //if content is text 
                        dataHeight = item.data.textHeight() / scale;
                        dataWidth = item.data.textWidth() / scale
                    }
                    if (index == 0 || (yPosition + dataHeight > pageHeight)) { // add pages for data
                        doc.addPage();
                        this.addFooter(doc, item.module);
                        yPosition = 20; //Reset y position when new page is added
                    }
                    // draw rectangle box for placing content
                    doc.rect(5, 5, pageWidth - 10, pageHeight - 10);

                    if (item.title != '')
                        this.setPageTitle(doc, item.title, yPosition, 14, this.apoloDarkBlue);

                    if (item.subTitle != '') {
                        yPosition += 7 // add space after title
                        this.setPageTitle(doc, item.subTitle, yPosition, 14, this.apoloDarkBlue);
                    }

                    yPosition += 10 // add space after title

                    let xPosition = (pageWidth - dataWidth) / 2 // center the content

                    if (item.imgSize != null) { // addImage when content is image
                        doc.addImage(item.data, 'PNG', xPosition, yPosition, dataWidth, dataHeight);
                    }
                    else
                        doc.text(item.data, xPosition, yPosition, dataWidth, dataHeight);

                    yPosition += dataHeight + 10; //add space after image
                });
                observer.next(doc);
                observer.complete();
            });
        });
    }

    addCoverPage(doc: jsPDF, coverPageData: any) {
        const imgWidth = 70;
        const imgHeight = 80;
        let x = 10;
        let y = 35;
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();

        doc.setFillColor(this.apoloDarkBlue);
        doc.rect(0, 0, pageWidth, pageHeight, 'F');

        //Add title
        let titlePos = this.setPageTitle(doc, coverPageData.title, y, 36, '#ffffff');

        // Add logo
        x = (pageWidth - imgWidth) / 2;
        y = y + 20;
        const logo = 'assets/img/apolo_logo.png';
        doc.addImage(logo, 'PNG', x, y, imgWidth, imgHeight);
        y = pageHeight - 10 - (Object.keys(coverPageData.data).length * 10);
        //Add text
        this.addCoverPageTextInfo(doc, coverPageData.data, titlePos, y);
    }

    setPageTitle(doc: jsPDF, description: string, yPosition: number, fontsize: number = 40, color: string = '#000000') {
        doc.setFont('Gotham-Bold');
        doc.setFontSize(fontsize);
        doc.setTextColor(color);
        const titleWidth = doc.getTextWidth(description);
        const pageWidth = doc.internal.pageSize.width;
        const xPosition = (pageWidth - titleWidth) / 2; // Centering the text horizontally
        doc.text(description, xPosition, yPosition);
        return xPosition;
    }

    addCoverPageTextInfo(
        doc: jsPDF,
        data: any,
        xPos: number,
        yPos: number,
        color: string = '#ffffff',
        fontsize: number = 12
    ) {
        const line_spacing = 10;
        doc.setTextColor(color);
        doc.setFontSize(fontsize);
        for (const [key, value] of Object.entries(data)) {
            doc.setFont('Gotham-Bold');
            doc.setFontSize(12);
            const key_name = key.toUpperCase().replace("_", " ");
            const key_width = doc.getTextWidth(key_name + ": ");
            doc.text(`${key_name}:`, xPos, yPos);
            doc.setFont('GothamNarrow-Book');
            doc.setFontSize(12);
            doc.text(String(value), xPos + key_width, yPos);
            yPos += line_spacing;
        }
    }

    addFooter(doc: jsPDF, text: string) {
        const pageNum = doc.getCurrentPageInfo().pageNumber;
        const pageHeight = doc.internal.pageSize.height;
        const pageWidth = doc.internal.pageSize.width;
        let xPos = pageWidth - 10;
        let yPos = pageHeight - 10;
        doc.setFontSize(10);
        doc.setTextColor('#000000');
        doc.setFont('GothamNarrow-Book');
        doc.text('Page ' + pageNum, 10, yPos);
        xPos = xPos - doc.getTextWidth(text);
        doc.text(text, xPos, yPos);
        doc.setFont('Gotham-Bold');
        xPos = xPos - doc.getTextWidth(this.defaultFooterText);
        doc.text(this.defaultFooterText, xPos, yPos);
    }
}
