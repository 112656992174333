import { ɵAnimationStyleNormalizer } from '@angular/animations/browser';
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { capitalizeTitle } from 'src/services/ui/titles-service';
import { CalAngularService, ICvxClaimsPrincipal } from '@cvx/cal-angular';
import { CommonService } from 'src/services/common.service';
import { MenuItem, MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})


export class HeaderComponent implements OnInit {
  items: MenuItem[];
  activeMenu: string = "Standard";
  currentUserProfile: any;
  username: string;
  showMenu: boolean = false;
  showVersion: boolean = !environment.production;
  versionText: string = capitalizeTitle(environment.environmentTag.toLowerCase()) + " " + environment.root.version;
  constructor(private router: Router, private authService: CalAngularService,
    public commonService: CommonService, private messageService: MessageService) {
    this.username = this.authService.cvxClaimsPrincipal?.name;
  }

  ngOnInit(): void {
    this.commonService.setWorkFlowMode("standard");
    this.items = [
      {
        label: 'Standard',
        command: () => {
          this.navigate('Standard');
        }
      },
      {
        label: 'Custom',
        command: () => {
          this.navigate('Custom');
        }

      },
      {
        label: 'What-If',
        command: () => {
          this.navigate('What-If');
        }
      },];
  }
  navigate(event: any) {
    if (event == 'home') { this.router.navigate(["/main-page"]); }
    else {
      if (this.commonService.getBusinessUnit() == undefined) {
        this.messageService.add({ severity: 'warn', summary: 'Warning', detail: 'Please Select a BU to proceed' });
        this.router.navigate(["/select-bu"]);
      }
      else {
        if (this.commonService.getBusinessUnit() == 'mcbu' && this.commonService.getBUBench() == undefined) {
          this.messageService.add({ severity: 'warn', summary: 'Warning', detail: 'Please Select a Formation to proceed' });
        }
        else {

          this.activeMenu = event;

          this.commonService.setWorkFlowMode(event.toLowerCase());
          if (event == 'Standard') {
            this.router.navigate(["/workflow-stepper"]);
          }
          else if (event == 'Custom') {
            this.router.navigate(["/custom-workflow"]);
          }

        }
      }
    }
  }
}
