import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, catchError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BaseService } from '../service-base.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MapArea } from 'src/classes/dataTransfer/areas/MapArea';
import { PredictionDataRequest } from 'src/classes/dataTransfer/requests/PredictionDataRequest';
import { MapAreaEx } from 'src/classes/dataTransfer/areas/MapAreaEx';
import { CommonFunctions } from 'src/classes/CommonFunctions';
import { UserProfileService } from '../auth/user-profile.service';

@Injectable({
    providedIn: 'root'
})

export class AreaService extends BaseService {
    public constructor(
        http: HttpClient,
        snackBar: MatSnackBar,
        private userService: UserProfileService,
    ) {
        super(http, snackBar);
    }

    public listAreas(predictionDataRequest: PredictionDataRequest): Observable<MapArea> {
        let areaLookupUrl = environment.apoloWebApiURL + 'areas/listAreas';

        return this.http.get<MapArea>(areaLookupUrl);
    }

    public filterAreas(predictionDataRequest: PredictionDataRequest): Observable<Array<MapArea>> {
        let areaLookupUrl = environment.apoloWebApiURL + 'areas/filterAreas';
        areaLookupUrl += "?businessUnit=" + predictionDataRequest.businessUnit;
        areaLookupUrl += "&basin=" + predictionDataRequest.basin;
        areaLookupUrl += "&userId=" + this.userService.getUserProfile().objectId;
        return this.http.get<Array<MapArea>>(areaLookupUrl);
    }

    public getArea(areaId: string): Observable<MapAreaEx> {
        let areaLookupUrl = environment.apoloWebApiURL + 'areas/getArea';
        areaLookupUrl += "?areaId=" + areaId;

        return this.http.get<MapAreaEx>(areaLookupUrl);
    }
    public saveArea(mapAreaEx: MapAreaEx): Observable<any> {
        let areaLookupUrl = environment.apoloWebApiURL + 'areas/postArea';
        const headers= new HttpHeaders({
            'Access-Control-Allow-Origin':'*',
            'Authorization':'authKey',
            'content-type': 'application/json'
          })
          const payLoad = {
            "id": mapAreaEx.id,
            "area": mapAreaEx.area,
            "areaType": mapAreaEx.areaType,
            "businessUnit": mapAreaEx.businessUnit,
            "basin": mapAreaEx.basin,
            "bench":mapAreaEx.bench,
            "geometry":mapAreaEx.geometry,
            "hexValues": mapAreaEx.hexValues,
            "userId": mapAreaEx.userId
            };
        return this.http.post<string>(areaLookupUrl, payLoad, {headers})
            .pipe(catchError(this.handleError));
    }
}