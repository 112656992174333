import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Action } from 'rxjs/internal/scheduler/Action';
import { ActionIndex } from 'src/classes/loadingActions/ActionIndex';
import { ActionTexts } from 'src/classes/loadingActions/ActionTexts';
import { TableElement } from 'src/components/well-inventory/well-inventory.component';
import { UserProfileService } from 'src/services/auth/user-profile.service';
import { ExportService } from 'src/services/export.service';
import { LoadingScreenService } from 'src/services/ui/loading-screen-service';
import { UserTrackerService } from 'src/services/ui/user-tracker.service';

@Component({
  selector: 'users-logs',
  templateUrl: './users-logs.component.html',
  styleUrls: ['./users-logs.component.scss']
})
export class UsersLogsComponent {
  displayedColumns: string[] = ['userName', 'date', 'action', 'count'];
  dataSource: any = new MatTableDataSource<TableElement>([]);
  ColumnAlias: { [key: string]: string } = {
    // userId:'User ID',
    userName: 'User Name',
    date: 'Date',
    action: 'Action',
    count: 'Hits Count',

  }
  constructor(private userService: UserTrackerService, private exportService: ExportService, private loadingService: LoadingScreenService) {
   }

  loadData(): void {
    this.dataSource.data=[];
    this.loadingService.addAction(ActionIndex.LOADING_DATA, ActionTexts.LOADING_DATA);
    this.userService.GetUserLogs().subscribe(data => {
      let tableKeys = Object.keys(data[0])
      this.displayedColumns = tableKeys;
      this.dataSource.data = data;
      this.loadingService.actionComplete(ActionIndex.LOADING_DATA);
    })
  }
  downloadLogs() {
    const headers=this.displayedColumns.map(column=>this.ColumnAlias[column]);
    const rows=this.dataSource.data.map(row=>this.displayedColumns.map(column=>row[column]));
    const data=[headers,...rows];
    this.exportService.exportToExcel(data, 'Users_logs');
  }
}
