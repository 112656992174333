import { CUSTOM_ELEMENTS_SCHEMA, Component, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './Routing/app-routing.module';
import { AppComponent } from './components/home/app.component';
import { WorkflowBuilderService } from './services/workflow_builder.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserCacheLocation, PublicClientApplication } from '@azure/msal-browser';
import { environment } from './environments/environment';
import { CalAngularModule } from '@cvx/cal-angular';

import { MatNativeDateModule } from '@angular/material/core';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu'
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSidenavContainer, MatSidenavContent, MatSidenavModule } from '@angular/material/sidenav';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import {
  MatSnackBarModule
} from '@angular/material/snack-bar';
import { MAT_RADIO_DEFAULT_OPTIONS, MatRadioModule } from '@angular/material/radio';
import { MatListModule } from '@angular/material/list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { OverlayModule } from '@angular/cdk/overlay';
import { DropdownModule } from 'primeng/dropdown';
import { MenuModule } from 'primeng/menu';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptorService } from './services/auth/auth-interceptor.service';
import { ProgressBarModule } from 'primeng/progressbar';
import { TooltipModule } from 'primeng/tooltip';
import * as PlotlyJS from 'plotly.js-dist-min';
import { PlotlyModule } from 'angular-plotly.js';
import { PlotlyPageComponent } from './components/plotly-page/plotly-page.component';
import { PlotlyViewComponent } from './components/views/plotly-view/plotly-view.component';
import { MapeViewComponent } from './components/views/mape-view/mape-view.component';
import { DiffViewComponent } from './components/views/diff-view/diff-view.component';
import { ViewControlsComponent } from './components/controls/view-controls/view-controls.component';
import { LocationGroupComponent } from './components/controls/location-group/location-group.component';
import { MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { ConfigurationGroupComponent } from './components/controls/configuration-group/configuration-group.component';
import { LoginComponent } from './components/login/login.component';
import { HeaderComponent } from './components/header/header.component';
import { PlotlyPredictionsComponent } from './components/plotly-predictions/plotly-predictions.component';
import { PlotlyExplainabilityComponent } from './components/plotly-explainability/plotly-explainability.component';
import { ComparisonComponent } from './components/comparison/comparison.component';
import { PlotlyDiagnosticsComponent } from './components/plotly-diagnostics/plotly-diagnostics.component';
import { PadScenarioBuilderComponent } from './components/pad-scenario-builder/pad-scenario-builder.component';
import { DataExportComponent } from './components/data-export/data-export.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { MainPageComponent } from './components/main-page/main-page.component';
import { ModelSelectionComponent } from './components/dialogs/model-selection/model-selection.component';
import { ModelNavigationComponent } from './components/controls/model-navigation/model-navigation.component';
import { LoadingScreenComponent } from './components/controls/loading-screen/loading-screen.component';
import { ComparisonGroupComponent } from './components/controls/comparison-group/comparison-group.component';
import { ModelResolveComponent } from './components/dialogs/model-resolve/model-resolve.component';
import { ActionLoaderComponent } from './components/action-loader/action-loader.component';
import { MatCardModule } from '@angular/material/card';
import { RippleModule } from 'primeng/ripple';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ModelControlsComponent } from './components/controls/model-controls/model-controls.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { CardBoxComponent } from './components/card-box/card-box.component';
import { PdfViewerComponent, PdfViewerModule } from 'ng2-pdf-viewer';
import { InfoTooltipComponent } from './components/info-tooltip/info-tooltip.component';
import { PlotlyViewSelectComponent } from './components/views/plotly-view-select/plotly-view-select.component';
import { PlotlyViewSelectHexComponent } from './components/views/plotly-view-select-hex/plotly-view-select-hex.component';
import { PredictionViewComponent } from './components/views/prediction-view/prediction-view.component';
import { PlotlyViewPredictionComponent } from './components/views/plotly-view-predictions/plotly-view-predictions.component';
import { WellInventoryComponent } from './components/well-inventory/well-inventory.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { PredictionSummaryComponent } from './components/prediction-summary/prediction-summary.component';
import { CarouselSlickComponent } from './components/carousel-slick/carousel-slick.component';
import { SegmentedToggleComponent } from './components/controls/segmented-toggle/segmented-toggle.component';
import { UsersLogsComponent } from './components/admin/users-logs/users-logs.component';

PlotlyModule.plotlyjs = PlotlyJS;

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    MainPageComponent,
    PlotlyPageComponent,
    PlotlyViewComponent,
    PlotlyViewPredictionComponent,
    MapeViewComponent,
    DiffViewComponent,
    PlotlyDiagnosticsComponent,
    PadScenarioBuilderComponent,
    ViewControlsComponent,
    LocationGroupComponent,
    PlotlyPredictionsComponent,
    PlotlyExplainabilityComponent,
    ConfigurationGroupComponent,
    ComparisonComponent,
    DataExportComponent,
    FeedbackComponent,
    SegmentedToggleComponent,

    ModelSelectionComponent,
    ModelNavigationComponent,
    LoadingScreenComponent,
    ComparisonGroupComponent,
    ModelResolveComponent,
    ActionLoaderComponent,
    ModelControlsComponent,
    SideNavComponent,
    CardBoxComponent,
    InfoTooltipComponent,
    PlotlyViewSelectComponent,
    PlotlyViewSelectHexComponent,
    PredictionViewComponent,
    WellInventoryComponent,
    PredictionSummaryComponent,
    CarouselSlickComponent,
    UsersLogsComponent
  ],
  imports:
    [
      HttpClientModule,
      BrowserModule,
      CalAngularModule.forRoot({
        autoSignIn: environment.autoSignIn,
        popupForLogin: environment.root.popupForLogin,
        cacheLocation: environment.root.cacheLocation,
        instance: environment.root.instance,
        tenantId: environment.root.tenantId,
        clientId: environment.root.clientId,
        // redirectUri will need to match a redirectUri setup in your app registration
        redirectUri: environment.redirectUri,
        oidcScopes: environment.root.scopes,
        graphScopes: environment.root.graphScopes,
      }),
      AppRoutingModule,
      FormsModule,
      ReactiveFormsModule,
      NgxSpinnerModule,
      BrowserAnimationsModule,
      MatCheckboxModule,
      MatDatepickerModule,
      MatToolbarModule,
      MatIconModule,
      MatButtonModule,
      MatButtonToggleModule,
      MatMenuModule,
      MatSelectModule,
      MatSliderModule,
      MatSlideToggleModule,
      MatSnackBarModule,
      MatRadioModule,
      MatSidenavModule,
      MatStepperModule,
      MatListModule,
      MatFormFieldModule,
      MatInputModule,
      MatProgressBarModule,
      MatTableModule,
      DragDropModule,
      MatDialogModule,
      OverlayModule,
      DropdownModule,
      MenuModule,
      ToastModule,
      ButtonModule,
      ProgressBarModule,
      TooltipModule,
      MatTooltipModule,
      PlotlyModule,
      MatExpansionModule,
      MatTabsModule,
      MatNativeDateModule,
      MatCardModule,
      RippleModule,
      ProgressSpinnerModule,
      PdfViewerModule,
      MatPaginatorModule
    ],
  exports: [],
  providers:
    [MatNativeDateModule, MessageService,
      {
        provide: MAT_RADIO_DEFAULT_OPTIONS,
        useValue: { color: 'red' },
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptorService,
        multi: true,
      },
      WorkflowBuilderService,
      PdfViewerComponent,
    ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class AppModule {

}
