import { InterfaceSectionDto } from "src/app/classes/InterfaceSectionDto";
import { ModelControlJson } from "../ModelControlJson";
import { RunIdTypeBundle } from "./RudIdTypeBundle";
import { ModelControlValues } from "../ModelControlValues";
import { ControlValue } from "../ControlValue";
import { ModelControlTranslation } from "src/classes/functions/ModelControlTranslation";

export class PredictionDataRequest {
    runIds: Array<RunIdTypeBundle>;
    cultures?:string
    businessUnit: string;
    basin: string;
    bench: string;
    product: string;
    timeslice: number;
    modelControls?: ModelControlJson[];
    modelControlsJson?:string;
    analogChoice: string;
    summarize: string = "mape";
    plotType: string;
    lat:string;
    long:string;
    radius:string;
    addInventory:boolean=false;


    public constructor() {
        this.runIds = new Array<RunIdTypeBundle>();
    }

    public addRunId(runId: string, type: string): void {
        const bundle = new RunIdTypeBundle();
        bundle.id = runId;
        bundle.type = type;
        if(!this.runIds.includes(bundle))
        this.runIds.push(bundle);
    }

    public getRunIdByType(type: string): string {
        return this.runIds.find(o => o.type === type)?.id;
    }

    public addModelControls(modelControls: InterfaceSectionDto[]): void {
        // NOT USED?
        const controlValues = Array<ModelControlValues>();
        modelControls.forEach(controlList => {
            if(controlList != undefined)
            {
                const modelControl = new ModelControlValues(controlList.title);
                controlList.items.forEach(item => {
                    const control = new ControlValue<any>();
                    control.name = item.data;
                    control.value = item.defaultValue;
                    modelControl.values.push(control)
                });
                controlValues.push(modelControl);
            }
        });
        this.modelControls = ModelControlTranslation.translateModelControlRequest(controlValues);
        this.modelControlsJson = JSON.stringify(this.modelControls);
    }

    public getModelControls(): Array<ModelControlJson> {
        if(this.modelControls)
            return this.modelControls;
        else
            return [];
    }
}

export class PredictionDataRequestBase {
    runId: string[];
    cultures: string[];
    basin: string;
    bench: string;
    product: string;
    timeslice: number;
}

export class PredictionDataRequestComparison extends PredictionDataRequestBase {
    metric: string;
}

export class PredictionDataRequestBOD extends PredictionDataRequest {
    isPredicted?: boolean;
    property?: string;
    window?: number;
    bodRunId?: string;    
    hexValues?: string[];
}