<div class="model-selection-popup">
    <div class="sub-header-container">
        <div class="subHeader">
            Select Models
        </div>
    </div>
    <div class="control-container">
        <div class="input-label-container">
            <div class="input-label">
                Model Type
            </div>
            <mat-form-field class="custom-mat-dropdown" subscriptSizing="dynamic">
                <mat-select [(ngModel)]="selectedModelType" placeholder="Model Type" value="all">
                    <mat-option value="all">All</mat-option>
                    <mat-option value="rules_based">Rules Based</mat-option>
                    <mat-option value="type_curve">Type Curve</mat-option>
                    <mat-option value="machine_learning">Machine Learning</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="input-label-container">
            <div class="input-label">
                Status
            </div>
            <mat-form-field class="custom-mat-dropdown" subscriptSizing="dynamic">
                <mat-select [(ngModel)]="selectedModelStatus" placeholder="Model Status" value="final">
                    <mat-option value="final">Final</mat-option>
                    <mat-option value="evaluation">Evaluation</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="input-label-container">
            <div class="input-label">
                Start Date
            </div>
            <mat-form-field class="custom-mat-input-short" subscriptSizing="dynamic">
                <input readonly matInput [matDatepicker]="picker1" placeholder="MM/DD/YYYY" [(ngModel)]="fromDate">
                <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="input-label-container">   
            <div class="input-label">
                End Date
            </div> 
            <mat-form-field class="custom-mat-input-short" subscriptSizing="dynamic">
                <input readonly matInput [matDatepicker]="picker2" placeholder="MM/DD/YYYY" [(ngModel)]="toDate">
                <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
        </div>    
        <div class="input-label-container">   
            <div class="input-label"></div> 
            <mat-checkbox [(ngModel)]="latestModelOnly">Include Only Latest</mat-checkbox>
        </div>  
        <div class="input-label-container">
            <div class="btn-medium-secondary ModelSelection__SearchButton" (click)="searchModels()">
                Search
            </div>
        </div>
    </div>
    <div class="GRID_CONTAINER">
        <table mat-table [dataSource]="modelsList">
            <ng-container matColumnDef="friendly_name">
                <th mat-header-cell *matHeaderCellDef> Friendly Name </th>
                <td mat-cell *matCellDef="let element"> {{element.friendly_name}} </td>
            </ng-container>
            <ng-container matColumnDef="run_id">
                <th mat-header-cell *matHeaderCellDef> Run ID </th>
                <td mat-cell *matCellDef="let element"> {{element.run_id}} </td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef> Status </th>
                <td mat-cell *matCellDef="let element"> {{element.status}} </td>
            </ng-container>              
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef> Date </th>
                <td mat-cell *matCellDef="let element"> {{element.timestamp | date}} </td>
            </ng-container>              
            <ng-container matColumnDef="bench">
                <th mat-header-cell *matHeaderCellDef> Bench </th>
                <td mat-cell *matCellDef="let element"> {{element.bench}} </td>
            </ng-container>              
            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef> Type </th>
                <td mat-cell *matCellDef="let element"> {{element.type}} </td>
            </ng-container>              
            <ng-container matColumnDef="plot_location">
                <th mat-header-cell *matHeaderCellDef> Plot Location </th>
                <td mat-cell *matCellDef="let element">
                    <div style="white-space: nowrap;">
                        <button class="btn-square-a" (click)="selectForSlot(element, 0)">A</button>
                        <button class="btn-square-b" (click)="selectForSlot(element, 1)" [disabled]="selectedModels.length < 1">B</button>
                        <button class="btn-square-c" (click)="selectForSlot(element, 2)" [disabled]="selectedModels.length < 2">C</button>
                    </div>
                </td>
            </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;let model"></tr>
        </table>
    </div>
    <div class="footer-container">
        <ng-container *ngFor="let model of selectedModels; index as index">
            <div class="SLOT_SECTION">
                <div class="{{'ModelDisplay__Position--Position'+slotNames[index]}} align-center">{{slotNames[index]}}</div>
                <div class="SLOT_INFO">
                    <div class="CLOSE_SELECTION_BUTTON" (click)="closeSlot(index)">x</div>
                    <div><b>Run Id:</b> {{ model.runId }}</div>
                    <div><b>Type:</b> {{ model.modelType }}</div>
                </div>
            </div>
        </ng-container>
        <div class="CONTROL_BUTTONS stacked-btn-container">
            <div class="btn-medium-secondary--cancel" (click)="cancel()">Cancel</div>
            <div class="btn-medium btn-run" (click)="confirm()">Confirm</div>
        </div>
    </div>
</div>