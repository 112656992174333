import { ModelControlJson } from "../dataTransfer/ModelControlJson";
import { ModelControlValues } from "../dataTransfer/ModelControlValues";

export class ModelControlTranslation {
    public static translateModelControlRequest(modelControls: Array<ModelControlValues>): Array<ModelControlJson> {
        const modelControlsJson = new Array<ModelControlJson>();
        
        if(modelControls != null)
        {
            modelControls.forEach(mc => {
                const mcJson = new ModelControlJson(mc.runId);
                mcJson.controlValues = new Object();
    
                mc.values.forEach(val => {
                    mcJson.controlValues[val.name] = val.value;
                });

                modelControlsJson.push(mcJson);
            });
        }
        return modelControlsJson;
    }
}