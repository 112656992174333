import { Component, EventEmitter, Output } from '@angular/core';
import { PlotlyViewComponent } from '../plotly-view/plotly-view.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as Plotly from 'plotly.js-dist-min';
import { MapGeometry } from 'src/classes/dataTransfer/areas/MapGeometry';
import { MapAreaEx } from 'src/classes/dataTransfer/areas/MapAreaEx';
import { GraphFunctions } from 'src/classes/functions/GraphFunctions';
import { MAP_CONFIG, MAP_LAYOUT } from 'src/constants/plot.constants';
import * as cloneDeep from 'lodash/cloneDeep';
import { ExportService } from 'src/services/export.service';

@Component({
  selector: 'app-plotly-view-select-hex',
  templateUrl: '../plotly-view/plotly-view.component.html',
  styleUrls: ['../plotly-view/plotly-view.component.scss']
})
export class PlotlyViewSelectHexComponent extends PlotlyViewComponent {
  @Output() selectHex : EventEmitter<any> = new EventEmitter<any>();
  selectionOverlay: MapAreaEx;

  constructor(
    override exportService: ExportService,
  ) {
    super(exportService);

    this.selectionOverlay = null;
  }

  override bindEvents(): void {
    const that = this;
    this.graphRef.on("plotly_click", function(data) {
      that.selectHex.emit(data.points[0]['location']);
    });
  }

  override applyData(data: any, type: string): void {
    this.headerType = type;
    this.loadStatus = 1;
    this.GraphData = data;
    // Convert pixel values to viewport units
    this.plotWidth = this.GraphData.layout['width'] / (window.innerWidth / 100) / 100;
    this.plotHeight = this.GraphData.layout['height'] / (window.innerHeight / 100) / 100;
    // Assign converted values to layout
    // this.GraphData.layout.width = window.innerWidth * this.plotWidth;
    // this.GraphData.layout.height = window.innerHeight * this.plotHeight;    

    this.resetGraph();
  }
  resetGraph(): void {    
    let map_config: Partial<Plotly.Config> = cloneDeep(MAP_CONFIG);
    map_config.modeBarButtonsToRemove = ['lasso2d'];
    Plotly.newPlot(this.graphName, this.GraphData.data, MAP_LAYOUT, map_config).then(x =>
      {
        this.graphRef = x;
        this.bindEvents();
      }
    );
  }
  updateZoom(): void {
    
    const center = GraphFunctions.getGeoCenter(this.selectionOverlay.geometry);

    const layoutData = {
      geo: {
        center: {
          lat: center.latitude,
          lon: center.longitude
        },
        fitbounds: false,
        projection: {
          rotation: {
            lon: center.longitude
          },
          scale: 190.41968637944794
        }
      }
    };
    
    Plotly.update(this.graphName, {}, layoutData);
  }
}