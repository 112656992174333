import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Observable, catchError, map} from 'rxjs';
import { CommonFunctions } from 'src/classes/CommonFunctions';
import { environment } from 'src/environments/environment';
import { RequestBuilder } from 'src/classes/functions/RequestBuilder';
import { RequestDto } from 'src/classes/dataTransfer/dto/RequestDto';


@Injectable({
  providedIn: 'root'
})
export class BaseService {

  constructor(public http: HttpClient, protected snackBar: MatSnackBar) { }

  public handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('Client-side or network error occurred:', error.error);
      this.snackBar.open('API service is down', 'Close', {
        duration: 5000,
        panelClass: ['snackbar-error'] // Optional: custom CSS class for styling
      });
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      this.snackBar.open('Internal server error occurred while fetching data', 'Close', {
        duration: 3000,
        panelClass: ['snackbar-error'] // Optional: custom CSS class for styling
      });
      console.error(
        `Backend returned code ${error.status}, body was: `, error.error);
        
    }
    // Return an observable with a user-facing error message.
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }

  public doRequest(requestData: RequestDto, functionName: string): Observable<HttpResponse<any>>
  {
      const qString = new RequestBuilder(environment.apoloWebApiURL, functionName);

      return this.http.post<any>(qString.getPostUrl(), requestData, { headers:CommonFunctions.headerInfo.headers,observe:'response' })
          .pipe(
              map(response=>{
                  if(response.status==201)
                  console.log('Hit prediction service while calling: ' + functionName + response.status);
                  return response.body;
                  }),
              catchError(this.handleError));
  }

}
