<div class="sub-header-container">
    <div class="subHeader">
        Differences Between Models
    </div>
    <app-comparison-group #comparisonGrp (notifyCompare)="doCompare($event)"
    ></app-comparison-group>
</div>
<br>
<div>
    <div *ngIf="type === 'difference'" class="sub-header-container">
        <div class="subHeader">
            Delta Map(Alternate-Baseline)/Baseline <app-info-tooltip [tooltipContent]="'A negative, or cool, percent difference signifies the alternate model is lower than the baseline model'" />
        </div>
    </div> 
    <div *ngIf="type === 'variance'" class="sub-header-container">
        <div class="subHeader">
            Variance <app-info-tooltip [tooltipContent]="'Placeholder: Variance is different than difference'" />
        </div>
    </div>   
    <div class="plotView">
        <plotly-view #comparisonView graphName="diffPlot">
        </plotly-view>
    </div>
</div>