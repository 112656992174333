<div class="containerDivWrapper">
    <div class="titlePredictionLabel">
        <div class="Header" style="color: #00213B;">{{ capitalizeTitle("Predictions for Local Areas") }}</div>
    </div>
    <div class="tagContainerWrapper">
        <div class="d-flex flex-column align-items-center">
            <div class="plotyDivWrap">
                <app-plotly-view-select style="position: relative;" #plotlyShowSelected [isExpandable]="false" graphName="plotlyShowSelected"
                (lassoSelected)="lassoSelected($event)" (rectangleSelected)="rectangleSelected($event)" (pointSelected)="pointSelected($event)"
                >
                </app-plotly-view-select>
            </div>
            <div class="justify-content-center align-items-center">
                <span>Base Map: </span>
                <span style="margin-left: 1vw;">
                    <mat-form-field class="custom-mat-dropdown" subscriptSizing="dynamic">
                        <mat-select   (selectionChange)="modelTypeChange($event.value)" [(ngModel)]="modelSelectedMap">
                            <mat-option *ngFor="let model of getSelectedModels()" [value]="model">
                                <div class="divOptions">
                                <div class="ModelDisplay__Position--Position{{ model.position }}">
                                    {{ model.position }}
                                </div><span style="margin-left: 0.3vw;">|</span>
                                <div class="selectedModelRunID" style="margin-left: 0.5vw;">
                                    {{ model.friendlyName }}
                                </div>
                            </div>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </span>
            </div>
        </div>
        <div [formGroup]="customTagForm">
        <app-card-box>
            <div class="customButtonDiv">
                <div class="switchTags">
                    <button   [ngClass]="{
                        'tagToggleCss': !switchTagFlag,
                        'tagToggleDisabledCss': switchTagFlag}" (click)="switchTag(false)">Saved Locations</button>
                    <button   [ngClass]="{
                        'tagToggleCss': switchTagFlag,
                        'tagToggleDisabledCss': !switchTagFlag,
                        'switch-to-right': switchTagFlag,
                        'switch-to-left': !switchTagFlag
                    }"  (click)="switchTag(true)">Custom Locations</button>
                </div>
                <app-info-tooltip 
                    [tooltipContent] = " switchTagFlag ? 'Create a new location polygon and save it in our database':'Load location polygons from our database'"
                    style="margin-top: 1.4vh;"
                />
            </div>
            <!-- Div for when switchTagFlag is true -->
            <div *ngIf="switchTagFlag" class="tagDivWrap">
                <mat-card class="cardCss" appearance="outlined">
                    <div class="customButtonDiv">
                        <div class="radioSwitchButton" (click)="switchToggleEvent(false)">
                        <img height="40" width="40" 
                            [src]="switchCustomToggle ? './assets/img/icons/radio-button.png' : './assets/img/icons/radio-selected-button.png'"> 
                        Select area on map
                        </div>
                        <div class="radioSwitchButton" (click)="switchToggleEvent(true)">
                        <img height="40" width="40" 
                            [src]="switchCustomToggle ? './assets/img/icons/radio-selected-button.png' : './assets/img/icons/radio-button.png'"> 
                        Input Long & Lat
                        </div>
                    </div>
                    <div *ngIf="!switchCustomToggle">
                        <div class="customInstructions" *ngIf="customAreaStep==0">
                            <ol>
                                <li>First select an area by doing one of the following.
                                    <ul>
                                        <li>Use the box select tool</li>
                                        <li>Use the lasso tool</li>
                                    </ul>
                                </li>
                                <li>
                                    (optional) Press &quot;Predict&quot; below to generate predictions for the selected area.
                                </li>
                                <li>
                                    (optional) Create a name for this area that you can use to refer to it later.
                                </li>
                                <li>
                                    Press the &quot;Save Tag&quot; button to save the selected area.
                                </li>
                            </ol>
                        </div>
                        <div class="customInstructions" *ngIf="customAreaStep==1 || customAreaStep==2">
                            <div *ngIf="customAreaStep==1">
                                You have a rectangle selected.
                            </div>
                            <div class="customInstructions" *ngIf="customAreaStep==2">
                                You have a generic area selected.
                            </div>
                            <div>
                                <div class="tagDetailLabel">
                                    Bounding Points:&nbsp;&nbsp;
                                </div>
                                <div class="tagBoundingBox">
                                    <div class="tagList">
                                        <div *ngFor="let point of getBoundingPoints()">
                                            {{ point }}
                                        </div>
                                        <br/>
                                    </div>
                                </div>
                                
                            </div>
                            <div>
                                <span class="tagDetailLabel">
                                    Hex Values:&nbsp;&nbsp;
                                </span>
                                {{ tagSelectionOverlay.hexValues.length }}
                            </div>
                        </div>
                    </div>
                    <div *ngIf="switchCustomToggle">
                        <div class="customInstructions">
                            <div>
                                <ol>
                                    <li>
                                        Input the longitude and latitude of the center of the area you want to select.
                                    </li>
                                    <li>
                                        Input the radius in miles using the slider.
                                    </li>
                                    <li>
                                        Press the &quot;Render&quot; button to generate the area.
                                    </li>
                                    <li>
                                        (optional) Press &quot;Predict&quot; below to generate predictions for the selected area.
                                    </li>
                                    <li>
                                        (optional) Create a name for this area that you can use to refer to it later.
                                    </li>
                                    <li>
                                        Press the &quot;Save Tag&quot; button to save the selected area.
                                    </li>
                                </ol>
                            </div>
                        </div>
                        <table cellpadding="4" cellspacing="0" class="latLongTable">
                            <tr>
                                <td>
                                    Longitude
                                </td>
                                <td>
                                    <input formControlName="longitudeInput" class="tagDiscInput" placeholder="Longitude Input" type="number">
                                </td>
                                <td rowspan="2" valign="middle">
                                    <button class="btn-medium" (click)="renderLatitudeLongitude()">Render</button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Latitude
                                </td>
                                <td>
                                    <input formControlName="latitudeInput" class="tagDiscInput" placeholder="Latitude Input" type="number">
                                </td>
                            </tr>
                        </table>
                        <div class="plotDivWrapper">
                            <mat-slider min="0" max="20" step="1" showTickMarks style="width: 11vw;">
                                <input type="hidden" />
                                <input #radiusValue matSliderThumb value="7"  (change)="onSliderChange()"
                                formControlName="radiusInput">
                            </mat-slider>
                            <div style="padding-top: 10px;">
                                {{ radiusValue.value }} miles
                            </div>
                        </div>
                        <div class="plotDivWrapper">
                            <mat-divider class="dividerCss"></mat-divider>
                        </div>
                    </div>
                    <div class="plotDivWrapper">
                        <input formControlName="tagTitle" class="tagDiscInput" placeholder="Tag Title" type="text">
                    </div>
                    <div class="saveButtonDiv">
                        <button class="tagButtonCss" (click)="saveTag()">Save Tag</button>
                        <button class="tagButtonCss" style="margin-left: 1vw;" (click)="clearTag()">Clear Tag</button>
                    </div>
                </mat-card>
            </div>

            <!-- Div for when switchTagFlag is false -->
            <div *ngIf="!switchTagFlag" class="tagDivWrap">
                <div class="switchItemFilterCss">
                    <button [ngClass]=" itemListSelection == 'All' ? 'switchItemFilterTabCss' : 'switchItemTabCss' " (click) = "itemListSelectionEvent('All')">ALL</button>
                    <button [ngClass]=" itemListSelection == 'Neighborhood' ? 'switchItemFilterTabCss' : 'switchItemTabCss' " (click) = "itemListSelectionEvent('Neighborhood')">Neighborhood</button>
                    <button [ngClass]=" itemListSelection == 'DevArea' ? 'switchItemFilterTabCss' : 'switchItemTabCss' " (click) = "itemListSelectionEvent('DevArea')">Dev Area</button>
                    <button [ngClass]=" itemListSelection == 'Custom' ? 'switchItemFilterTabCss' : 'switchItemTabCss' " (click) = "itemListSelectionEvent('Custom')">Custom Tag</button>
                </div>
                <div class="tagListDivWrapper">
                    <input
                        class="searchFilterListCss" (input)="onSearchChange($event)" type="text" placeholder="Search Areas ..."
                    />
                    <div class="tagList">
                        <div *ngIf="filteredAreaList == undefined">
                            <h3 style="color: grey;margin-top: 1vh;">No data to preview</h3>
                            <div style="display: flex;justify-content: center;opacity: 0.3;">
                                <img src="./assets/img/NoData.png">
                            </div>
                        </div>
                        <div *ngIf="filteredAreaList != undefined">
                            <div *ngFor="let option of filteredAreaList" [ngClass]="option.id == tagSelection ? 'tagItemSelected' : 'tagItem'" (click)="tagChange(option)" >
                                {{ option.area }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </app-card-box>
        </div>
    </div>
    <br/><br/><br/><br/>
    <app-prediction-view #predictionView [mapData]="plotlyShowSelected.selectionOverlay" [tagSelection]="tagSelection"></app-prediction-view>
</div>