import { Location, Model, Product, ScoreParams } from "./DTO_Components";

export class RequestDto {
    // required attributes
    location: Location;
    product: Product;
    models: Model[];

    // smart defaults in prediction service
    culture: string[];
    scoring_params: ScoreParams;

    // required for scoring, no smart defaults available
    analog_model?: Model;

    
    constructor(location: Location, product: Product, models: Model[]) {
        this.location = location;
        this.product = product;
        this.models = models;

        this.culture = new Array<string>();
        this.culture.push("dev_areas", "basin_boundaries")
        this.scoring_params = new ScoreParams();
        this.scoring_params.confidence = "any";
    }
}