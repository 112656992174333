<div class="HEADER_CONTAINER">
    <div class="GROUP_NAME" (click)="collapse()" [title]="groupTitle">{{ groupName }}</div>
    <div *ngIf="collapsed" class="EXPAND_GROUP" (click)="collapse()"><mat-icon>chevron_right</mat-icon></div>
    <div *ngIf="!collapsed" class="EXPAND_GROUP" (click)="collapse()"><mat-icon>expand_more</mat-icon></div>
</div>
<mat-divider *ngIf="!collapsed" class="similar-margin"></mat-divider>
<div *ngIf="!collapsed" [formGroup]="configuration" class="pb-2">
    <div class="col-12 p-0">
        <div class="row d-flex justify-content-between align-items-center">
            <div class="col-12">
                <mat-label>Base Time</mat-label>
                <app-info-tooltip [tooltipContent]="'Timeslice selection'"></app-info-tooltip>
            </div>
            <div class="col-12 d-flex justify-content-center p-0">
                <app-segmented-toggle [options]="[EUR_LABEL, TIMESLICE_LABEL]"
                    [selectedOption]="selectEUR ? EUR_LABEL : TIMESLICE_LABEL" (optionSelected)="toggleSwitch($event)">
                </app-segmented-toggle>
            </div>
        </div>
        <div class="row pl-4 pr-2 d-flex justify-content-between align-items-center">
            <div class="col-auto pr-0 pl-0">
                <mat-label *ngIf="!selectEUR">Timeslice</mat-label>
                <mat-label *ngIf="selectEUR" style="color: #bbb;">Timeslice</mat-label>
            </div>
            <div class="col-auto pl-0 pr-0" id="timeslice-label-id">
                <mat-slider min="0" max="7" step="1" discrete showTickMarks=false disabled={{selectEUR}}
                    class="sliderCSS" [displayWith]="formatLabel">
                    <input matSliderThumb value="{{sliderValue}}" [(ngModel)]="sliderValue"
                        [ngModelOptions]="{standalone: true}" (valueChange)="selectTimeslice($event)">
                </mat-slider>
                <mat-label *ngIf="!selectEUR" class="labelCSS">
                    month {{configuration.get('timeslice')?.value}}
                </mat-label>
                <mat-label *ngIf="selectEUR" class="labelCSS-disabled">
                    month {{configuration.get('timeslice')?.value}}
                </mat-label>
            </div>
            <!-- <div class="labelCss">{{configuration.get('timeslice')?.value}}</div> -->
        </div>
    </div>
    <mat-divider class="similar-margin"></mat-divider>
    <div class="location-group">
        <mat-label>Analog Binning Source</mat-label>
        <mat-form-field class="location-custom-mat-dropdown"
            matTooltip="The group of wells to use for actuals. Sourced from model definitions."
            subscriptSizing="dynamic">
            <mat-select #analogSet placeholder="Analog Set" formControlName="analogSet"
                (selectionChange)="selectionUpdate()">
                <mat-option *ngFor="let model of getSelectedModels()" [value]="model.runId">
                    <div class="divOptions">
                        <div class="ModelDisplay__Position--Position{{ model.position }}">
                            {{ model.position }}
                        </div><span style="margin-left: 0.3vw;">|</span>
                        <div class="selectedModelRunID" style="margin-left: 0.5vw;">
                            {{ model.friendlyName }}
                        </div>
                    </div>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <mat-divider class="similar-margin"></mat-divider>
    <div class="col-12 p-0">
        <div class="row d-flex justify-content-between align-items-center">
            <div class="col-12">
                <mat-label>Basis of Design (BOD)</mat-label>
            </div>
            <div class="col-12 d-flex justify-content-center p-0" id="timeslice-label-id">
                <app-segmented-toggle [options]="[PLAYBOOK_LABEL, CUSTOM_LABEL]"
                    [selectedOption]="useBODPlaybook ? PLAYBOOK_LABEL : CUSTOM_LABEL" (optionSelected)="toggleBOD()">
                </app-segmented-toggle>
            </div>
        </div>
    </div>
</div>