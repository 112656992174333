import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { CalAngularService, ICvxClaimsPrincipal } from '@cvx/cal-angular';
import { environment } from 'src/environments/environment';
import { UserProfileService } from 'src/services/auth/user-profile.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  loggedIn: boolean;
  inAccessGroup: boolean;
  errorText: string;
  showLogin: boolean=true;
  isDisabled: boolean=false;
  showWorkFlowBuilder:boolean=false;
  
  constructor(private router: Router, 
    private authService: CalAngularService,
    private userService: UserProfileService,
    private _formBuilder: FormBuilder) {
      this.loggedIn = false;
      this.userService.checkAccount(this.receiveUserCallback, this);
      this.userService.userPermissionReceived$.subscribe((userData) => {
        this.loggedIn = true;
      });

  }

  receiveUserCallback(loggedIn: boolean, sender: LoginComponent) {
    sender.loggedIn = loggedIn;
  }

  currentUserProfile: ICvxClaimsPrincipal;
  login():void {
    this.showWaitCursor();

    this.authService.isUserSignedIn().subscribe((value: boolean) => {
          this.loggedIn = value;
          if (!this.loggedIn) {
            this.errorText = "User not yet signed in";  
            this.authService.userInitiatedSignIn();
          }
          this.authService.isInGroup(environment.root.accessGroupId).subscribe((accessValue: boolean) => 
          {
            this.inAccessGroup = accessValue;
          
            if (this.loggedIn && this.inAccessGroup) {
                this.showLogin=false;
                this.router.navigate(['/toolbox']);
            }
            else {
              this.errorText = "User does not have access privileges. Click "
                + "<a class='link1' href='mailto:" + environment.root.productOwnerEmail +"'>here</a>"
                + " to request access.";
            }
          }
        )
      }
    );
  }

   showWaitCursor(): void {
    this.isDisabled=true;
    var divId = document.getElementById('divLogin');
    if(divId != null) {
      divId.classList.add('samp');
    }
  }
}
