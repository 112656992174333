<div class="error" *ngIf="!isRunIdAvailable">Plots are not displayed since runid is not available for the selected bench
</div>

<div class="sub-header-container">
  <div class="subHeader">
    {{ headers['featureImportance'] }}
  </div>
  <mat-form-field class="custom-mat-dropdown" subscriptSizing="dynamic">
    <mat-select [(value)]="chartSelection" (selectionChange)="chartTypeChange($event.value)">
      <mat-option value="bar">Bar Plot</mat-option>
      <mat-option value="beeswarm">Beeswarm Shap Plot</mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div class="plotViewContainer">
  <div class="plotView">
    <plotly-view #featureImportance header="" graphType="{{ getScatterGraphyType() }}" [width]=800 [height]=500
      graphName="featureImportance" style="padding-right: 12px" [exportType]="exportTypes.FeatureImportance">
    </plotly-view>
  </div>
</div>
<div>
  <app-card-box style="display: flex;justify-content: center;">
  <p>Given the current set of feature values, the contribution of a feature value to the difference between
    the actual prediction and the mean prediction is the estimated Shapley value.
    <a href="https://christophm.github.io/interpretable-ml-book/shapley.html" target="_blank">Interpretable
      AI</a>
  </p>
</app-card-box>
</div>
<div class="sub-header-container">
  <div class="subHeader">
    headers['pdp']
  </div>
</div>

<div class="plotViewContainer">
  <div class="plotView">
    <plotly-view #pdp graphName="pdp" graphType="{{ getScatterGraphyType() }}" [width]=1000 [height]=600
      [exportType]="exportTypes.PDP"></plotly-view>
  </div>
</div>
<div>
  <app-card-box style="display: flex;justify-content: center;">
  <p>
    These plots illuminate how the target changes with the underlying feature. The partial dependence plot
    (short PDP or PD plot) shows the marginal effect one or two features have on the predicted outcome of a
    machine learning model (J. H. Friedman 200130). A partial dependence plot can show whether the
    relationship between the target and a feature is linear, monotonic or more complex.
  </p>
  </app-card-box>
</div>
<div class="sub-header-container">
  <div class="subHeader">
    {{ headers['shapMaps'] }}
  </div>
</div>
<div class="plotViewContainer">
  <div class="plotView">
    <plotly-view #shapMaps [width]=800 [height]=400 graphName="shapMaps" style="padding-right: 12px;"
      [exportType]="exportTypes.SHAP">
    </plotly-view>
  </div>
</div>
<div>
  <app-card-box style="display: flex;justify-content: center;">
  <p>Spatial impacts of features based on their Shapely values.</p>
  </app-card-box>
</div>
<div class="sub-header-container">
  <div class="subHeader">
    {{headers['qqMaps']}}
  </div>
</div>
<div class="plotViewContainer">
  <div class="plotView">
    <plotly-view #qqMaps graphType="{{ getScatterGraphyType() }}" [width]=800 [height]=400 graphName="qqMaps"
      style="padding-right: 12px;" [exportType]="exportTypes.QQMaps">
    </plotly-view>
  </div>
</div>